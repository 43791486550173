.PGB-con-a {
  align-items: center;
  display: flex;
  height: 47px;
  position: relative;
  width: 708px;
}

.PGB-con-a .PGB-button-a {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  margin-right: -0.25px !important;
  position: relative !important;
  width: unset !important;
}

.PGB-con-a .PGB-button-a-2 {
  width: 708px !important;
}

.PGB-con-a .PGB-button-a-3 {
  width: 655px !important;
}
