.figmapopup-testroot {
  width: 1920px;
  height: 1080px;

  border: 5px solid black;
  background: #241b1b;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.figmapopup-root {
  display: flex;
  width: 788px;
  height: 993px;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;

  position: absolute;
  left: calc(50% - 396px);
  top: 71px;
}

.figmapopup-general-root {
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;

  position: absolute;
}

.figmapopup-general-root.with-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.figmapopup-general-title {
  position: relative;
  width: 800px;
  height: 600px;

  left: calc(50% - 400px);
  top: calc(50% - 300px);
}

.figmapopup-deco-paper {
  width: 98.64%;
  height: 95.94%;
  transform: rotate(-0.662deg);
  flex-shrink: 0;
  position: absolute;

  background: #6F664E;
}

.figmapopup-popup-content {
  display: flex;
  width: 98.33%;
  height: 96.29%;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  position: absolute;

  border: 5px solid #EADAB0;
}

.figmapopup-title {
  display: flex;
  height: 49px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  align-self: stretch;
  position: relative;

  background: #3D3324;
}

.figmapopup-title-underline {
  width: 98.87%;
  height: 2px;
  flex-shrink: 0;
  top: 41px;

  position: absolute;

  background: #685334;
}

.figmapopup-title-text {
  width: 94.07%;
  top: 4px;

  color: #E8E0B9;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  position: absolute;
}

.figmapopup-body-frame {
  display: flex;
  flex-direction: column;
  height: calc(100% - 49px);
  flex: 1;
  align-self: stretch;
  position: relative;
  overflow: hidden;

  background: #D5C08B;
}

.figmapopup-body-glow-mask {
  position: absolute;
  width: 100%;
  height: 100%;

  background: #D5C08B;
}

.figmapopup-body-glow {
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  flex-shrink: 0;
  position: absolute;

  border-radius: 70px;
  background: #E6D5AC;
  filter: blur(25px);
}

.figmapopup-body-lines {
  width: 100%;
  height: 100%;
  padding: 20px;
  position: absolute;

  background: linear-gradient(
    to bottom,
    transparent 19px,
    rgba(213, 194, 146, 1) 20px
  )
  center 0 / calc(100% - 40px) 20px repeat-y;
}

.figmapopup-body-content {
  flex: 1;
  padding: 1em 2em;

  align-self: stretch;
  z-index: 1;

  overflow-y: auto;
}

.figmapopup-deco-clip {
  position: absolute;
  top: -11px;
  left: 18px;
}

.figmapopup-root.unknown {
  border-color: #808080;
}
.figmapopup-root.neutral {
  border-color: #ffffff;
}
.figmapopup-root.accident {
  border-color: #ff4500;
}
.figmapopup-root.penalty {
  border-color: #ff0000;
}
.figmapopup-root.bonus {
  border-color: #32cd32;
}
.figmapopup-root.reward {
  border-color: #1e90ff;
}
.figmapopup-root.dilemma {
  border-color: purple;
}

.figmapopup-bg {
  position: absolute;
  width: 768px;
  height: 942px;
  flex-shrink: 0;

  top: 17px;
  left: 14px;
  right: 10px;
  bottom: 8px;
}

.figmapopup-bg-rect {
  position: absolute;
}

.figmapopup-bg-rect.rect267 {
  width: 693px;
  height: 844px;
  flex-shrink: 0;
  border-radius: 4px 15px 15px 4px;
  border: 3px solid #464646;
  background: #7e7e7e;

  top: 6px;
  left: -11px;
}

.figmapopup-bg-rect.rect255 {
  width: 788px;
  height: 941px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 3px solid #7f6e62;
  background: #78685c;

  top: 6px;
  left: -11px;
}

.figmapopup-bg-rect.rect266 {
  width: 767px;
  height: 936px;
  flex-shrink: 0;
  opacity: 0.7;
  background: #666;
  mix-blend-mode: multiply;

  top: 6px;
  left: 1px;
}

.figmapopup-bg-rect.rect265 {
  width: 761px;
  height: 928.059px;
  flex-shrink: 0;
  background: #6e6b5e;

  top: 5.97px;
  left: 3px;
}

.figmapopup-bg-rect.rect264 {
  width: 761px;
  height: 929.055px;
  flex-shrink: 0;
  background: #9b9680;

  top: 2.99px;
  left: 2px;
}

.figmapopup-bg-rect.rect257 {
  width: 761px;
  height: 929.055px;
  flex-shrink: 0;
  background: #b0ab93;

  top: 1px;
  left: 1px;
}

.figmapopup-bg-rect.rect263 {
  width: 761px;
  height: 925px;
  flex-shrink: 0;
  background: #e2dcbf;

  top: 1px;
  left: 0px;
}

.figmapopup-bg-rect.rect259 {
  width: 751px;
  height: 915.114px;
  flex-shrink: 0;
  border: 4px solid #cdc6b0;
  background: #ebe6cd;

  top: 5.97px;
  left: 5px;
}

.figmapopup-bg-rect.rect293 {
  width: 761px;
  height: 471px;
  flex-shrink: 0;
  background: linear-gradient(
    180deg,
    rgba(247, 244, 230, 0) 2.27%,
    #f7f4e6 100%
  );
  mix-blend-mode: multiply;

  top: 455px;
  left: 0px;
}

.figmapopup-bg-rect.rect292 {
  width: 761px;
  height: 44px;
  flex-shrink: 0;
  background: linear-gradient(0deg, rgba(204, 202, 193, 0) 2.27%, #cccac1 100%);
  mix-blend-mode: multiply;

  top: 1px;
  left: 0px;
}

.figmapopup-bg-springs {
  display: inline-flex;
  width: 705px;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;

  position: absolute;
  top: -17px;
  left: 28px;
}

.figmapopup-content {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
  top: 70px;
}

.figmapopup-header {
  display: flex;
  width: 703px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.figmapopup-divider {
  height: 3px;
  width: 703px;
  background: #b3ad97;
}

.figmapopup-header-title {
  align-self: stretch;

  color: #4e4f41;
  text-align: center;
  -webkit-text-stroke-width: 1.2000000476837158pt;
  -webkit-text-stroke-color: #4e4f41;
  /* font-family: Caveat, "나눔손글씨"; */
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0.76px;

  height: 48px;
  white-space: nowrap;
}

.figmapopup-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;

  height: 727px;
}

.figmapopup-content-event {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  height: 393px;
  width: 702px;

  background: linear-gradient(
    180deg,
    #d1cbb4 1px,
    transparent 1px,
    transparent 22px
  );
  background-size: 100% 23px;
}

.figmapopup-content-event.simple {
  height: 727px;
}
.figmapopup-content-event.system {
  height: 727px;
}

.figmapopup-content-event-top {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  width: 100%;
  height: 100%;
}

.figmapopup-content-agents {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 3px;
}

.figmapopup-content-message.simple {
  flex: unset;
  align-self: unset;
  text-align: center;
}

.figmapopup-content-message.simple .hl {
  color: inherit;
}

.figmapopup-content-message-mission-objective-detail {
  display: block;
  text-align: left;
}

.figmapopup-content-message-body {
  text-align: start;
}

.figmapopup-content-message {
  display: flex;
  padding: 5px 10px;
  gap: 10px;
  width: 100%;

  color: #484c27;
  /* font-family: Caveat, "나눔손글씨"; */
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  white-space: pre-line;
  overflow-y: auto;
}

.simple .figmapopup-content-message,
.system .figmapopup-content-message {
  overflow-y: scroll;
}

.figmapopup-content-event::-webkit-scrollbar,
.figmapopup-content-message::-webkit-scrollbar {
  width: 12px;
}

.figmapopup-content-event::-webkit-scrollbar-thumb,
.figmapopup-content-message::-webkit-scrollbar-thumb {
  background: #56382e;
  width: 4px;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 12px;
}

.figmapopup-content-event::-webkit-scrollbar-track,
.figmapopup-content-message::-webkit-scrollbar-track {
  background: transparent;
}

.figmapopup-content-message.system {
  font-family: interit;
  font-size: inherit;
  font-weight: inherit;
}

.figmapopup-content-event-choices {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.figmapopup-content-event-choices button.fh1-button {
  display: flex;
  height: 40px;
  padding: 3px 10px;
  align-items: center;
  gap: 15px;
  align-self: stretch;

  border-radius: 4px;
  border: 2px solid #8a5c32;
  background: #724b26;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.5);

  color: #c79c60;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.figmapopup-content-event-choices button.fh1-button > strong {
  color: #f7d19d;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: unset;
}

.figmapopup-content-event-choices button.fh1-button:hover {
  border-radius: 4px;
  border: 2px solid #b38860;
  background: #9a724e;
}

.figmapopup-content-event-choices button.fh1-button:active {
  border-radius: 4px;
  border: 2px solid #8a5c32;
  background: #523820;
}

.figmapopup-general-buttongroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  position: relative;
}

.figmapopup-buttongroup {
  display: flex;
  padding: 7px 24px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  position: relative;
}

.figmapopup-button {
  width: 379px;
  height: 54px;
  flex-shrink: 0;

  position: relative;

  border-radius: 7px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.figmapopup-button:hover,
.figmapopup-button:active {
  cursor: pointer;
}

.figmapopup-button-emboss {
  width: 379px;
  height: 51px;
  flex-shrink: 0;

  border-radius: 7px;
  background: #581d15;

  position: absolute;
  top: 3px;
}

.figmapopup-button-background {
  width: 379px;
  height: 51px;
  flex-shrink: 0;

  border-radius: 7px;
  border: 1.4px solid #923c30;
  background: #6f241a;

  position: absolute;
  top: 0px;
}

.figmapopup-button-outline {
  width: 373px;
  height: 45px;
  flex-shrink: 0;

  border-radius: 4px;
  border: 2px solid #3a0606;

  position: absolute;
  top: 3px;
  left: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.figmapopup-button-label {
  color: #ffbd75;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  position: relative;

  display: flex;
  align-items: center;
  gap: 5px;
}

.figmapopup-button:hover .figmapopup-button-emboss {
  background: #872619;
}

.figmapopup-button:hover .figmapopup-button-background {
  border: 1.4px solid #923c30;
  background: #9b382b;
}

.figmapopup-button:hover .figmapopup-button-outline {
  border: 2px solid #85291c;
}

.figmapopup-button:hover .figmapopup-button-label {
  color: #ffd2a1;
}

.figmapopup-button:active .figmapopup-button-emboss {
  background: #581d15;
}

.figmapopup-button:active .figmapopup-button-background {
  border: 1.4px solid #782b21;
  background: #551b13;

  top: 2px;
}

.figmapopup-button:active .figmapopup-button-outline {
  border: 2px solid #3a0606;

  top: 5px;
}

.figmapopup-button:active .figmapopup-button-label {
  color: #ffd2a1;
}

.figmapopup-button.disabled {
  cursor: unset;
}

.figmapopup-button.disabled .figmapopup-button-emboss {
  background: #424242 !important;
}

.figmapopup-button.disabled .figmapopup-button-background {
  border: 1.4px solid #555 !important;
  background: #474747 !important;

  top: 0px !important;
}

.figmapopup-button.disabled .figmapopup-button-outline {
  border: 2px solid #333 !important;

  top: 3px !important;
}

.figmapopup-button.disabled .figmapopup-button-label {
  color: #777 !important;
}

.figmapopup-photo > img {
  position: absolute;

  height: 625px;
  left: -388px;
  top: 56px;
}

.lobby-pendings .figmapopup-photo > img {
  height: 400px;
  left: 0px;
  top: 0px;
}

.figmapopup-content-safe {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 0 0;
}

.figmapopup-photo ~ .figmapopup-content-safe {
  width: 1084px;
}

.lobby-pendings .figmapopup-photo ~ .figmapopup-content-safe {
  width: calc(100% - 253px);
}

.figmapopup-root.in-background {
  border-radius: unset;
  border: unset;
  background: unset;
}

.figmapopup-root.in-background .figmalist-body .figmapopup-dim {
  opacity: 0.95;
}

.figmapopup-root.in-background .figmapopup-content-safe {
  display: flex;
  height: 710px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  flex: 1 0 0;
}

.figmapopup-root.in-background .figmapopup-content-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.figmapopup-content-tags {
  display: flex;
  gap: 10px;
}

.figmapopup-event-tag {
  display: flex;
  padding: 5px 10px;
  align-items: center;
  gap: 10px;

  background: #3e2a0e;
  color: #f2e4b5;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmapopup-event-tag.negative {
  background: #650c0c;
  color: #ff2b1e;
}

.figmapopup-event-tag.positive {
  background: #0b4b0c;
  color: #71c507;
}

.figmapopup-root.in-background .figmapopup-content-event-top {
  flex-direction: column;
}

.figmapopup-root.in-background .figmapopup-content-agents {
  flex-direction: row;
  align-self: stretch;
  background: rgba(81, 73, 68, 0.5);
  padding: 10px;
}

.figmapopup-root.in-background .figmapopup-content-event {
  display: flex;
  height: 710px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  flex: 1 0 0;

  margin: unset;
}

.figmapopup-root.in-background .figmalist-header-title {
  color: #f6e2d0;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmapopup-root.in-background .figmapopup-content-message {
  display: flex;
  padding: 0px 10px 5px 10px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmapopup-root.in-background .figmapopup-content-event-choices {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  padding: 0px;
}

.lobby-pendings .figmapopup-root.in-background .figmapopup-photo > img {
  height: unset;
  width: 400px;
  left: 0px;
  top: 0px;
}

.lobby-pendings
  .figmapopup-root.in-background
  .figmapopup-photo
  ~ .figmapopup-content-safe {
  width: calc(100% - 400px);
}

/* choices */
.grind-event-button-base {
  display: flex;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-end;

  width: 702px;
  height: 68px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
}

.grind-event-button-base.selected {
  background: unset;
}

.grind-event-button-base.disabled {
  cursor: unset;
}

.grind-event-button-background {
  background: center url("/public/img/popup/UI_Popup_Button_0.png") no-repeat;
  position: absolute;
  width: 720px;
  height: 76px;
  left: -9px;
  top: -3px;
  background-repeat: none;
}

.grind-event-button {
  height: 100%;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.grind-event-button-base:hover .grind-event-button-background {
  background: center url("/public/img/popup/UI_Popup_Button_1.png") no-repeat;
  width: 720px;
  height: 76px;
  left: -9px;
  top: -3px;
}

.grind-event-button-base:active .grind-event-button-background {
  background: center url("/public/img/popup/UI_Popup_Button_2.png") no-repeat;
  width: 702px;
  height: 66px;
  left: 0px;
  top: -1px;
}

.grind-event-button-base.disabled .grind-event-button-background {
  background: center url("/public/img/popup/UI_Popup_Button_3.png") no-repeat;
  width: 720px;
  height: 76px;
  left: -9px;
  top: -3px;
}

.grind-event-button-label-header {
  display: flex;
  width: 180px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #474436;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.grind-event-button-base:hover .grind-event-button-label-header {
  color: #fff;
}

.grind-event-button-base:active .grind-event-button-label-header {
  color: #d0cdc0;
}

.grind-event-button-base.disabled .grind-event-button-label-header {
  color: #474436 !important;
}

.grind-event-button-label-body {
  color: #736758;
  overflow: auto;
  text-overflow: wrap;
  white-space: pre-line;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  width: 505.196px;
}

.grind-event-button-base:hover .grind-event-button-label-body,
.grind-event-button-base:active .grind-event-button-label-body {
  color: #c7c1a6;
}

.grind-event-button-base.disabled .grind-event-button-label-body {
  color: #736758 !important;
}
