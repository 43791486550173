html {
  --c-gr1-transpbox-fg-positive: #60a45a;
  --c-gr1-transpbox-fg-negative: #cc624a;
}

.toggle-background {
  position: relative;
  width: 73px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 27px;
  border: 2px solid #625443;
  background: #1c160d;
  overflow: visible;
  justify-content: right;
}

.toggle-button {
  position: absolute;
  top: 50%;
  margin-top: -13px;
  display: flex;
  width: 50px;
  height: 26px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #9e8662;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.toggle-button:hover {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #c7af8b;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.toggle-button:active {
  border-radius: 66px;
  border: 2px solid rgba(8, 8, 8, 0.1);
  background: #7a6442;
}

.toggle-label {
  color: #3d301c;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.icon-warning-low-background {
  position: absolute;
  z-index: 0;

  width: 21.651px;
  height: 18.75px;
  flex-shrink: 0;
}

.icon-warning-low-text {
  position: absolute;
  z-index: 1;

  display: flex;
  width: 5px;
  height: 14px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.icon-warning-low {
  width: 22px;
  height: 22px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-warning-high-background {
  position: absolute;
  z-index: 0;
}

.icon-warning-high-text {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.icon-warning-high {
  width: 22px;
  height: 22px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.figmasquad-testroot {
  width: 1920px;
  height: 1080px;

  border: 5px solid black;
  overflow: hidden;
}

#root:has(.figmasquad-testroot) {
  overflow: hidden;
}
body:has(#root):has(.figmasquad-testroot) {
  overflow: hidden;
}

.figmasquad-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;

  background: #241b1b;
}

.figmasquad-root {
  /* Frame 330 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  width: 1855px;

  position: absolute;
  top: 20px;
  left: calc((100% - 1855px) / 2);

  background: #2b241c;
  border: 3px solid #625c4e;
  border-radius: 12px;

  --tabitem-width: 90px;

  user-select: none;

  --color-red: #cc624a;
  --color-green: #60a45a;
}

.hl {
  color: #f2e4b5;
}

.figmasquad-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 24px;

  width: 1855px;
  height: 56px;

  background: #4e4233;

  border-radius: 12px 12px 0px 0px;
}

.figmasquad-header-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 31px;

  width: 100%;
  height: 40px;
}

.figmasquad-header-title {
  height: 40px;

  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height */
  text-align: center;

  color: #f2e4b5;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmasquad-header-subtitle {
  height: 24px;

  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;

  color: #c5ab78;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmasquad-header-sep {
  /* Vector 28 */

  width: 0px;
  height: 39px;

  border: 1px solid #1e160d;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmasquad-header-warninglowoption {
  display: flex;
  align-items: center;
  gap: 10px;
}

.figmasquad-header-warninglowoption-title {
  display: flex;
  align-items: center;
  gap: 4px;
}

.figmasquad-header-warninglowoption-title-label {
  color: #ad9a81;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmasquad-header-btn-close {
  /* 닫기 버튼 */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 53px;
  height: 35px;

  background: #9e8662;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 66px;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/

  margin-left: auto;
}

.figmasquad-header-btn-close:hover {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #c7af8b;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmasquad-header-btn-close:active {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #7a6442;
}

.figmasquad-body {
  display: inline-flex;

  flex-direction: column;
  align-items: center;

  gap: 5px;
  margin: 5px 0px;
}

.figmasquad-body-squad {
  width: 100%;
}

.figmasquad-body-squad-group {
  gap: 10px;
  display: flex;
  padding: 10px 12px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  position: relative;
  border-radius: 10px;
  border: 2px solid #7d6c53;
  background: #6f5c40;
}

.figmasquad-body-squad-slotframe {
  display: flex;
  padding: 6px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: #3d3329;
}

.figmasquad-body-squad-slot {
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 4px;
  border: 2px solid #5a4f3d;
  background: #352c1e;
}

.figmasquad-body-squad-slot-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.figmasquad-body-squad-slot-badge {
  width: 46px;
  height: 57px;
  position: relative;
  justify-content: center;
}

.figmasquad-body-squad-slot-badge-number {
  position: absolute;
  display: flex;
  height: 21px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  left: 50%;
  top: 17px;
  transform: translate(-50%);
  color: #372923;
  text-align: center;
  font-family: Freshman;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.figmasquad-body-squad-slot-buttons-sep {
  height: 1px;
  align-self: stretch;
  background: #6b5d49;
}

.figmasquad-body-squad-slot-button {
  display: flex;
  width: 45.113px;
  padding: 5px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background-color: transparent;
  border-color: transparent;
}

.figmasquad-body-squad-slot-button-label {
  color: #7f7467;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: pre;
}

.button-detail:hover .icon-detail {
  fill: #ccb187;
}

.button-detail:active .icon-detail {
  fill: #614d2e;
}

.button-detail:active .icon-detail-stroke {
  stroke: rgba(0, 0, 0, 0.1);
}

.icon-detail {
  fill: #9e8662;
}

.icon-detail-stroke {
  stroke: rgba(255, 255, 255, 0.1);
}

.button-exclude:hover .icon-exclude {
  fill: #c35b5b;
}

.button-exclude:active .icon-exclude {
  fill: #702e2e;
}

.button-exclude:active .icon-exclude-stroke {
  stroke: rgba(0, 0, 0, 0.1);
}

.icon-exclude {
  fill: #904141;
}

.icon-exclude-stroke {
  stroke: rgba(255, 255, 255, 0.1);
}

.button-exchange:hover .icon-exchange {
  fill: #ccb187;
}

.button-exchange:active .icon-exchange {
  fill: #614d2e;
}

.button-exchange:active .icon-exchange-stroke {
  stroke: rgba(0, 0, 0, 0.1);
}

.icon-exchange {
  fill: #9e8662;
}

.icon-exchange-stroke {
  stroke: white;
}

.button-prev:hover .icon-prev {
  fill: #ccb187;
}

.button-prev:active .icon-prev {
  fill: #614d2e;
}

.button-prev:active .icon-prev-stroke {
  stroke: rgba(0, 0, 0, 0.1);
}

.icon-prev {
  fill: #9e8662;
}

.icon-prev-stroke {
  stroke: white;
}

.button-next:hover .icon-next {
  fill: #ccb187;
}

.button-next:active .icon-next {
  fill: #614d2e;
}

.button-next:active .icon-next-stroke {
  stroke: rgba(0, 0, 0, 0.1);
}

.icon-next {
  fill: #9e8662;
}

.icon-next-stroke {
  stroke: white;
}

.figmasquad-body-squad-slot-inform {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.figmasquad-body-squad-slot-inform-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.figmasquad-body-squad-slot-inform-profile-header {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 27px;
}

.figmasquad-body-squad-slot-inform-profile-role {
  display: flex;
  padding: 7px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 27px;
}

.figmasquad-body-squad-slot-inform-profile-role-pointman {
  background: #a451b9;
}

.figmasquad-body-squad-slot-inform-profile-role-breacher {
  background: #cacdd8;
}

.figmasquad-body-squad-slot-inform-profile-role-medic {
  background: #bcb258;
}

.figmasquad-body-squad-slot-inform-profile-role-vanguard {
  background: #d6922b;
}

.figmasquad-body-squad-slot-inform-profile-role-sharpshooter {
  background: #3fa3b9;
}

.figmasquad-body-squad-slot-inform-profile-role-scout {
  background: #58b258;
}

.figmasquad-body-squad-slot-inform-profile-role-label {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.figmasquad-body-squad-slot-inform-profile-name {
  display: flex;
  align-items: center;
  gap: 7px;
  height: 27px;
}

.figmasquad-body-squad-slot-inform-profile-name-label {
  color: #d3c6b2;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmasquad-body-squad-slot-inform-profile-callsign {
  display: flex;
  padding: 4px 5px;
  align-items: center;
  gap: 10px;
  border: 1px solid #d3c6b2;
  height: 27px;
}

.figmasquad-body-squad-slot-inform-profile-callsign-label {
  color: #d3c6b2;
  text-align: center;
  font-family: "Roboto Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmasquad-body-squad-slot-inform-profile-basic {
  display: flex;
  align-items: flex-start;
  gap: 13px;
}

.figmasquad-body-squad-slot-inform-profile-portrait {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 109px;
  height: 135px;
  padding: 3px;
  gap: 10px;
  /*background: #FFF;*/
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.figmasquad-body-squad-slot-inform-profile-states {
  display: flex;
  width: 234px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

.figmasquad-body-squad-slot-inform-profile-state {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.figmasquad-body-squad-slot-inform-profile-state-text {
  display: flex;
  height: 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.figmasquad-body-squad-slot-inform-profile-state-title {
  display: flex;
  width: 107px;
  align-items: center;
  gap: 2px;
}

.figmasquad-body-squad-slot-inform-profile-state-label {
  color: #c4a87d;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-wrap: nowrap;
}

.figmasquad-body-squad-slot-inform-profile-state-value {
  display: flex;
  float: right;
  justify-content: center;
  align-items: center;
  gap: 3px;
  align-self: stretch;
}

.figmasquad-body-squad-slot-inform-profile-state-value-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #f2e4b5;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}

.figmasquad-body-squad-slot-inform-profile-state-valuemax-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  color: #856f54;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}

.figmasquad-body-squad-slot-inform-profile-state-sep {
  height: 1px;
  align-self: stretch;
  background: #6b5d49;
}

.figmasquad-body-squad-slot-inform-profile-state-status-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #f2e4b5;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}

.figmasquad-body-squad-slot-inform-profile-state-status-label.positive {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--c-gr1-transpbox-fg-positive);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}

.figmasquad-body-squad-slot-inform-profile-state-status-label.negative {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: var(--c-gr1-transpbox-fg-negative);
  text-align: center;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}

.figmasquad-body-squad-slot-inform-profile-modifier {
  display: flex;
  width: 356px;
  height: 77px;
  padding: 1px;
  flex-direction: column;
  align-items: center;
  border: 1px solid #6b5d49;
}

.figmasquad-body-squad-slot-inform-profile-modifier-header {
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  background: #453927;
}

.figmasquad-body-squad-slot-inform-profile-modifier-header-label {
  flex: 1 0 0;
  color: #c4a87d;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.figmasquad-body-squad-slot-inform-profile-modifier-body {
  padding: 4px 14px;
  align-self: stretch;
}

.figmasquad-body-squad-slot-inform-profile-modifier-contents {
  color: #d3c6b2;
  display: inline-block;
  align-items: flex-start;
}

.figmasquad-body-squad-slot-inform-profile-modifier-contents-label {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  display: inline-block;
  padding-left: 0.2em;
}

.figmasquad-body-squad-slot-inform-stat {
  width: 356px;
  height: 210px;
}

.figmasquad-body-squad-slot-inform-gears {
  display: flex;
  width: 355px;

  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.figmasquad-body-squad-slot-inform-gear {
  display: flex;
  height: 32px;
  padding: 3px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #9e8662;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmasquad-body-squad-slot-inform-gear-empty {
  height: 32px;
  width: 100%;
}

.figmasquad-body-squad-slot-inform-gear:hover {
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #c2a983;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmasquad-body-squad-slot-inform-gear:active {
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  background: #6b5637;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmasquad-body-squad-slot-inform-gear-title {
  display: flex;
  align-items: center;
}

.figmasquad-body-squad-slot-inform-gear-title-label {
  color: #3d301c;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmasquad-body-squad-empty-slot {
  display: flex;
  width: 440px;
  height: 601px;
  padding: 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 3px solid #59482f;
  background: #473a27;
}

.figmasquad-body-squad-empty-slot:hover {
  border-radius: 10px;
  border: 3px solid #806844;
  background: #635135;
}

.figmasquad-body-squad-empty-slot:active {
  border-radius: 10px;
  border: 3px solid #28241e;
  background: #332a1d;
}

.figmasquad-body-squad-empty-slot-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.figmasquad-body-squad-empty-slot-title-label {
  color: #b8a47f;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmasquad-body-footer {
  display: flex;
  width: 1818px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.figmasquad-body-powereval {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.figmasquad-body-powereval-header {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.figmasquad-body-powereval-header-title {
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-top: 1px solid #685a44;
  border-bottom: 1px solid #685a44;
  background: #4e4233;
  width: 904px;
}

.figmasquad-body-powereval-header-title-label {
  color: #e9d8ab;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmasquad-body-powereval-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.figmasquad-body-powereval-body-effdis {
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 0.5px;
  background: #523628;
}

.figmasquad-body-powereval-body-effdis-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmasquad-body-powereval-body-effdis-header-title {
  display: flex;
  height: 36px;
  padding: 0px 15px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #3d2f1a;
}

.figmasquad-body-powereval-body-effdis-header-title-label {
  color: #c4a87d;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmasquad-body-powereval-body-effdis-header-title-options {
  display: flex;
  align-items: center;
  gap: 25px;
}

.figmasquad-body-powereval-body-effdis-header-title-option {
  display: flex;
  align-items: center;
  gap: 5px;
}

.figmasquad-body-powereval-body-effdis-header-title-option-firearmty {
  display: flex;
  padding: 6px 4px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: #393228;
}

.figmasquad-body-powereval-body-effdis-header-title-option-firearmty-label {
  color: #f7af23;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  /* 12px */
  letter-spacing: 0.6px;
  text-transform: capitalize;
}

.figmasquad-body-powereval-body-effdis-body {
  display: flex;
  padding: 0px 10px 4px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  background: #39261c;
}

.figmasquad-body-powereval-body-effdis-body-scales {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.figmasquad-body-powereval-body-effdis-body-scale {
  display: flex;
  width: 34px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.figmasquad-body-powereval-body-effdis-body-scale-label {
  color: #906755;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmasquad-body-powereval-body-effdis-body-chart {
  display: flex;
  height: 15px;
  padding: 0px 15px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmasquad-body-powereval-body-effdis-body-chart-value {
  position: relative;
  display: flex;
  padding: 0px 112px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-right: 2px solid #5d4c33;
  border-bottom: 2px solid #5d4c33;
  border-left: 2px solid #5d4c33;
}

.figmasquad-body-advice {
  display: flex;
  padding: 7px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 5px;
  background: #110e0a;
  height: 215px;
  overflow: auto;
}

.figmasquad-body-advice-line {
  display: flex;
  align-items: center;
  gap: 2px;
}

.figmasquad-body-advice-line-label-high {
  color: #ff4e4e;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.figmasquad-body-advice-line-label-low {
  color: #ffdb20;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.figmasquad2-root {
  width: 1760px;
  height: 900px;
  flex-shrink: 0;
  background: #291917;
  border: 2px solid #3c2421;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
}

.figmasquad-testroot .figmasquad2-root {
  top: calc(50% - 445.5px);
  left: calc(50% - 880px);
}

.outgame-lobby .figmasquad2-root {
  top: 96px;
  left: 100px;
}

.figmasquad2-header {
  width: 1748px;
  height: 46px;
  flex-shrink: 0;

  background: #3d251b;
  position: relative;
}

.figmasquad2-header-title {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  position: absolute;
  left: 12px;
  top: 4px;
}

.figmasquad2-header-title-bullet {
  width: 3px;
  height: 26px;
  background: #6b543f;
}

.figmasquad2-header-title-label {
  color: #e4c89e;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.figmasquad2-squad {
  display: flex;
  width: 1748px;
  height: 718px;
  padding: 7px;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  background: #130c09;
}

.figmasquad2-squad-agent-slot {
  width: 426px;
  height: 723px;
  flex-shrink: 0;

  position: relative;
}

.figmasquad2-squad-agent-slot-outline {
  animation: shake 300ms ease-out 1ms infinite alternate;
  animation-iteration-count: infinite;
  border-radius: 14px;
  border: 3px solid #ecb16b;
  width: 426px;
  height: 723px;
  position: absolute;
  top: -5px;
  pointer-events: none;
}

@keyframes shake {
  0% {
    transform: rotate(-0.2deg);
  }
  100% {
    transform: rotate(0.2deg);
  }
}

.figmasquad2-squad-agent-slot-exclude-button-wrapper {
  width: 426px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 0px 0px 8px 8px;
  background: #ecb16b;
  position: absolute;
  top: 676px;
  left: 1px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.figmasquad2-squad-agent-slot-exclude-button-wrapper > div {
  display: flex;
  width: 100%;
}

.figmasquad2-squad-agent-slot-exclude-button-wrapper.exchange {
  top: 631px;
}

.figmasquad2-squad-agent-slot-exclude-button {
  flex: 1 1 0;
  width: 100%;
  height: 37px;
  flex-shrink: 0;
}

.figmasquad2-squad-agent-slot-exclude-button .lobby2-page-button-emboss {
  flex: 1 1 0;
  width: 100%;
  height: 35px;
  flex-shrink: 0;
  top: 2px;
}

.figmasquad2-squad-agent-slot-exclude-button .lobby2-page-button-background {
  flex: 1 1 0;
  width: 100%;
  height: 35px;
  flex-shrink: 0;
  bottom: 2px;
}

.figmasquad2-squad-agent-slot-exclude-button .lobby2-page-button-outline {
  flex: 1 1 0;
  width: 100%;
  height: 35px;
  border: unset;
  top: unset;
  left: unset;
}

.figmasquad2-squad-agent-slot-exclude-button .lobby2-page-button-label {
  color: #bf5c4e;
  font-size: 22px;
}

.figmasquad2-squad-agent {
  width: 426px;
  height: 723px;
  flex-shrink: 0;

  position: relative;
}

.figmasquad2-squad-agent-slot-gap {
  width: 426px;
  height: 723px;
  flex-shrink: 0;

  position: relative;
}

.figmasquad2-squad-agent-slot-gap.none {
  display: none;
}

.figmasquad2-squad-agent-bg-card {
  width: 417px;
  height: 680px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 2px solid #734f3b;
  background: #5a3a29;
  position: absolute;
  top: 4px;
  left: 6px;
}

.figmasquad2-squad-agent-bg-slot {
  position: absolute;
  top: 1px;
  left: 0px;
}

.figmasquad2-squad-agent-title {
  display: inline-flex;
  align-items: flex-start;
  left: 103px;
  top: 9px;
  position: relative;
}

.figmasquad2-squad-agent-title-name {
  display: flex;
  width: 278px;
  padding: 2px 10px;
  align-items: center;
  gap: 2px;

  color: #c3a079;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmasquad2-squad-agent-order {
  display: flex;
  width: 41px;
  padding: 1px 0px 2px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 24px;
  top: 13px;
}

.figmasquad2-squad-agent-order-label {
  color: #ae8c65;
  text-align: center;
  font-family: Freshman;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.5px;
}

.figmasquad2-squad-agent-order-underline {
  width: 41px;
  height: 3px;
  background: #ae8c65;
}

.figmasquad2-squad-agent-info {
  display: flex;
  width: 412px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  position: absolute;
  left: 7px;
  top: 55px;
}

.figmasquad2-squad-agent-info-agent {
  display: flex;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 8px;
  background: #291917;
}

.figmasquad2-squad-agent-info-agent-agentcard2 {
  display: flex;
  height: 178px;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
}

.figmasquad2-squad-agent-info-agent-agentcard2-primary {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}

.figmalist-body2-card-primary-row-3-figmasquad2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.figmasquad2-squad-agent.dragging .figmasquad2-squad-agent-bg-slot {
  border-radius: 14px;
  border: 3px solid #ecb16b;
}

.figmasquad2-squad-agent.dragging-now {
  opacity: 0;
  width: 0px;
}

.portrait-wrapper2.figmasquad2-squad-agent-portrait {
  width: 114px;
  height: 137px;
}

.figmasquad2-squad-agent-portrait .portrait-wrapper2-portrait {
  width: 97.6px;
  height: 118.6px;
  top: 8px;
  left: 7.92px;
  right: 8.48px;
  bottom: 10.4px;
}

.figmasquad2-squad-agent-portrait .portrait-wrapper2-portrait-shadow {
  width: 97.836px;
  height: 39.886px;
}

.figmasquad2-squad-agent-info-agent-stats .figmalist-body-detail2-stats-graph {
  width: unset;
}

.figmasquad2-squad-agent-info-agent-stats-table {
  display: flex;
  width: 400px;
  padding-bottom: 1px;
  align-items: flex-start;
}

.figmasquad2-squad-agent-info-agent-stats-table-thead {
  display: flex;
  height: 34px;
  padding: 0px 1px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  flex: 1 1 0px;
}

.figmasquad2-squad-agent-info-agent-stats-table-thead-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  background: linear-gradient(0deg, #452f23 0%, rgba(69, 47, 35, 0.5) 95.59%);
}

.figmasquad2-squad-agent-info-agent-stats-table-thead:nth-child(even)
  .figmasquad2-squad-agent-info-agent-stats-table-thead-inner {
  background: linear-gradient(0deg, #543a2b 0%, rgba(84, 58, 43, 0.5) 95.59%);
}

.figmasquad2-squad-agent-info-agent-stats-table-thead-label {
  color: #c39762;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.figmasquad2-squad-agent-info-gears {
  display: flex;
  padding: 0px 5px 5px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
  border-radius: 4px;
  background: #4c3325;
}

.figmasquad2-squad-agent-info-gears-title {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.figmasquad2-squad-agent-info-gears-title-label {
  display: flex;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  color: #93765b;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmasquad2-squad-agent-info-gears-slots {
  display: flex;
  padding: 8px 5px 6px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: #2c1e16;
}

.figmasquad2-squad-agent-info-gear {
  display: flex;
  height: 33px;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 2px solid #553625;
  background: #5a3a29;
  box-shadow: 0px -2px 0px 0px #805842;
}

.figmasquad2-squad-agent-info-gear:hover {
  border: 2px solid #6d4835;
  background: #714c39;
  box-shadow: 0px -2px 0px 0px #986b52;
}

.figmasquad2-squad-agent-info-gear:active {
  border: 2px solid #4c382e;
  background: #4c3529;
  box-shadow: 0px 0px 0px 0px #7c543e;
}

.draggable .figmasquad2-squad-agent-info-gear {
  border: unset !important;
  background: #382e28 !important;
  box-shadow: unset !important;
  color: #7a6559 !important;
  pointer-events: none;
}

.figmasquad2-squad-agent-info-gear-empty {
  display: flex;
  width: 392px;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 5px;
  background: #382e28;
}

.figmasquad2-squad-agent-info-gear-title {
  display: flex;
  align-items: center;
  gap: 7px;
  flex: 1 0 0;
  justify-content: space-between;
}

.figmasquad2-squad-agent-info-gear-title-divider {
  height: 24px;
  width: 2px;
  background: #77513d;
}

.figmasquad2-powereval {
  display: flex;
  width: 1748px;
  height: 120px;
  padding: 5px;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background: #3f2722;
  margin-top: 4px;
}

.figmasquad2-powereval-item {
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 0.5px;
  background: #523628;
  flex: 1 1 0;
}

.figmasquad2-powereval-item-header {
  display: flex;
  height: 39.5px;
  padding: 0px 10px 3px 10px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.figmasquad2-powereval-item-header-dis {
  display: flex;
  height: 39.5px;
  padding: 0px 10px 3px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.figmasquad2-powereval-item-header-title {
  color: #c59673;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.figmasquad2-powereval-item-header-dis-options {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.figmasquad2-powereval-item-body-rate {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  background: #39261c;
  width: 100%;
  justify-content: space-around;
}

.figmasquad2-powereval-item-body-rate-subitem {
  display: flex;
  padding: 8px 10px;
  align-items: center;
  gap: 11px;
}

.figmasquad2-powereval-item-body-rate-subitem-title {
  display: flex;
  align-items: center;
  gap: 8px;
}

.figmasquad2-powereval-item-body-rate-subitem-title-bullet {
  width: 3px;
  height: 27px;
  background: #795340;
}

.figmasquad2-powereval-item-body-rate-subitem-title-label {
  color: #d0ad83;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmasquad2-powereval-item-body-rate-subitem-value {
  display: flex;
  align-items: flex-start;
}

.figmasquad2-powereval-item-header-dis-option-firearmty {
  display: flex;
  margin: 0px 3px;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 5px;
  background: #dbc5a3;
  clip-path: polygon(
    3px 0px,
    calc(100% - 3px) 0px,
    100% 3px,
    100% calc(100% - 3px),
    calc(100% - 3px) 100%,
    3px 100%,
    0px calc(100% - 3px),
    0px 3px
  );
}

.figmasquad2-powereval-item-header-dis-option-firearmty-label {
  color: #5a3a29;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmasquad2-squad-agent-empty {
  width: 426px;
  height: 693px;
  flex-shrink: 0;
  position: relative;
}

.figmasquad2-squad-agent-empty-bg {
  width: 412px;
  height: 636px;
  flex-shrink: 0;
  position: absolute;
  top: 50px;
  left: 7px;
  background: linear-gradient(
      to right,
      rgba(130, 106, 70, 0.2) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(130, 106, 70, 0.2) 1px, transparent 1px),
    #291917;
  background-size: 11px 11px;
  border-radius: 11px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.figmasquad2-squad-agent-empty-info {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.figmasquad2-squad-agent-empty-info-button {
  display: flex;
  height: 37px;
  padding: 0px 10px;
  align-items: center;
  border-radius: 5px;
  border: 2px solid #553625;
  background: #5a3a29;
  box-shadow: 0px -2px 0px 0px #805842;
}

.figmasquad2-squad-agent-empty-info-button:hover {
  border: 2px solid #8b6543;
  background: #916a47;
  box-shadow: 0px -2px 0px 0px #bf9168;
}

.figmasquad2-squad-agent-empty-info-button:hover
  .figmasquad2-squad-agent-empty-info-button-icon
  > path {
  fill: #eacaa5;
}

.figmasquad2-squad-agent-empty-info-button:hover
  .figmasquad2-squad-agent-empty-info-button-label {
  color: #eacaa5;
}

.figmasquad2-squad-agent-empty-info-button:active {
  border: 2px solid #482e20;
  background: #523526;
  box-shadow: 0px -1px 0px 0px #6a4d3d;
}

.figmasquad2-squad-agent-empty-info-button:active
  .figmasquad2-squad-agent-empty-info-button-icon
  > path {
  fill: #876646;
}

.figmasquad2-squad-agent-empty-info-button:active
  .figmasquad2-squad-agent-empty-info-button-label {
  color: #876646;
}

.figmasquad2-squad-agent-empty-info-button-label {
  display: flex;
  padding: 4px 5px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  color: #b18b5f;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
