.agent-tier {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 21px;
  height: 20px;
  flex-shrink: 0;
}

.agent-tier-background {
  position: absolute;
  z-index: 0;
}

.agent-tier-frame {
  position: absolute;
  z-index: 1;
}

.agent-tier1-label {
  z-index: 2;
  position: absolute;
  display: flex;
  width: 9.882px;
  height: 8.647px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #feeba8;
  text-align: center;
  font-family: Freshman;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.agent-tier2-label {
  z-index: 2;
  position: absolute;
  display: flex;
  width: 9.882px;
  height: 8.647px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #fff0b9;
  text-align: center;
  font-family: Freshman;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.agent-tier3-label {
  z-index: 2;
  position: absolute;
  display: flex;
  width: 9.882px;
  height: 8.647px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #d7e6f4;
  text-align: center;
  font-family: Freshman;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.agent-tier4-label {
  z-index: 2;
  position: absolute;
  display: flex;
  width: 9.882px;
  height: 8.647px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #fbcc96;
  text-align: center;
  font-family: Freshman;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.agent-tier5-label {
  z-index: 2;
  position: absolute;
  display: flex;
  width: 9.882px;
  height: 8.647px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #cdb39b;
  text-align: center;
  font-family: Freshman;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.figma-badge-role {
  /* Frame 177 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;

  height: 20px;

  background: #cacdd8;

  flex: none;
  flex-grow: 0;
}

.figma-badge-role-pointman {
  background: #a451b9;
}

.figma-badge-role-breacher {
  background: #cacdd8;
}

.figma-badge-role-medic {
  background: #bcb258;
}

.figma-badge-role-vanguard {
  background: #d6922b;
}

.figma-badge-role-sharpshooter {
  background: #3fa3b9;
}

.figma-badge-role-scout {
  background: #58b258;
}

.figma-badge-role-label {
  /* 브리처 */

  height: 10px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figma-badge-callsign {
  /* Frame 245 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px 5px;
  gap: 10px;

  height: 19px;

  border: 1px solid #d3c6b2;

  overflow: hidden;

  font-family: "Roboto Mono";
  font-style: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;

  color: #d3c6b2;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-testroot {
  width: 1920px;
  height: 1080px;

  border: 5px solid black;
  position: relative;
}

.figmalist-root {
  /* Frame 330 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 4px;

  width: 1760px;
  height: 891px;

  position: absolute;
  top: calc((100% - 891px) / 2);
  left: calc((100% - 1760px) / 2);

  border: 2px solid #3c2421;
  background: #1d0f0d;

  user-select: none;

  box-sizing: border-box;
  overflow: hidden;
}

.bad {
  color: #cc624a;
}

.good {
  color: #60a45a;
}

.hl {
  color: #f2e4b5;
}

.figmalist-header {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 15px;
  gap: 20px;
  margin-top: 4px;
  flex-shrink: 0;

  width: 1748px;
  height: 46px;

  background: #3d251b;

  /* Inside auto layout */
  /*
flex: none;
align-self: stretch;
flex-grow: 0;
*/
}

.figmalist-header-inner {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 20px;

  width: 100%;
  height: 40px;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmalist-header-title {
  /* 고용 */

  height: 40px;

  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
  /* identical to box height */
  text-align: center;

  color: #f2e4b5;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmalist-header-subtitle {
  /* 목록 갱신까지 3 일 */

  height: 27px;

  color: #c06c3d;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmalist-header-sep {
  /* Vector 28 */

  width: 0px;
  height: 39px;

  border: 1px solid #1e160d;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmalist-header-btn {
  /* 헤드헌터와 미팅 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 3px 20px;
  gap: 10px;

  width: 204px;
  height: 36px;

  background: #9e8662;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmalist-header-btn:hover {
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #d1b182;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-header-btn:active {
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  background: #6c593c;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-header-btn:disabled {
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #4b4942;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-header-btn:disabled .figmalist-header-btn-label {
  color: #737069;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-header-btn-label {
  /* 헤드헌터와 미팅 */
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;

  color: #3d301c;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmalist-header-btn-close {
  /* 닫기 버튼 */
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 53px;
  height: 35px;

  background: #9e8662;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 66px;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/

  margin-left: auto;
}

.figmalist-header-btn-close:hover {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #c7af8b;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-header-btn-close:active {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #7a6442;
}

.figmalist-body {
  /* Frame 329 */
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;

  width: 1855px;
  height: 892px;

  /* Inside auto layout */
  /*
flex: none;
align-self: stretch;
flex-grow: 1;
*/
}

.figmalist-body-list {
  /* 목록 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 1220px;
  height: 862px;

  /* Inside auto layout */
  /*
flex: none;
align-self: stretch;
flex-grow: 0;
*/
}

.figmalist-body-list-tabs {
  /* 고용 목록 탭 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 5px;
  gap: 10px;

  width: 1210px;
  height: 30px;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmalist-body-list-tab {
  /* Frame 325 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  height: 30px;

  background: #40372b;
  border-radius: 5px;

  /* Inside auto layout */
  /*
flex: none;
align-self: stretch;
flex-grow: 0;
*/

  justify-content: center;
}

.figmalist-body-list-tab-name,
.figmalist-body-list-tab-etc.power {
  flex: 2 0 0;
}

.figmalist-body-list-tab-etc {
  flex: 1 0 0;
}

.figmalist-body-list-tab-label {
  /* 이름 */
  height: 21px;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #8c7e69;
}

.figmalist-body-list-body {
  /* Frame 328 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 5px 5px;
  gap: 10px;

  width: 1220px;
  height: 822px;

  background: #000000;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 1;
*/

  overflow-x: hidden;
  overflow-y: scroll;
}

.figmalist-body-list-row {
  /* Frame 423 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  width: 1210px;

  background: #352c22;
  border-radius: 6px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  border: 3px solid transparent;
}

.figmalist-body-list-row-selected {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  width: 1210px;

  background: #352c22;

  border-radius: 6px;
  border: 3px solid #d7c895;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.figmalist-body-list-row-info {
  /* Frame 233 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  padding: 5px;
  gap: 10px;

  width: 100%;
  height: 62px;

  position: relative;
  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/
}

.figmalist-body-list-row-plan {
  /* 일정 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;

  width: 100%;
  height: 34px;

  background: #292118;
  border-radius: 0px 0px 6px 6px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

.figmalist-body-list-row-plan-note {
  /* Frame 431 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  padding: 0px;
  gap: 5px;

  height: 16px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
  leading-trim: both;
  text-edge: cap;
  display: flex;
  align-items: center;
  text-align: center;

  color: #cab07d;
}

.figmalist-body-list-row-plan-actions {
  /* 분류 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 25px;

  margin-left: auto;
  height: 24px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.figmalist-body-list-row-plan-radio {
  /* Frame 257 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  height: 24px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.figmalist-body-list-row-plan-radio-label {
  /* 일반 훈련 */
  height: 11px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  /* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
  leading-trim: both;
  text-edge: cap;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  color: #f2e4b5;
}

.figmalist-body-list-row-plan-radio-label-disabled {
  color: #4f4938;
}

.figmalist-body-list-row-etc {
  /* Frame 234 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 2px;

  height: 52px;

  flex: 1 0 0;
}

.figmalist-body-list-row-etc-sep::before {
  content: "/";
  margin-left: 0.15em;
  margin-right: 0.15em;
}

.figmalist-body-list-row-etc-label {
  /* 23.2/72.4 */

  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 21px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  text-wrap: nowrap;

  color: #f2e4b5;
}

.figmalist-body-list-row-etc-label.small {
  font-size: 12px;
}

.figmalist-body-list-row-etc-label.positive {
  color: #60a45a;
}

.figmalist-body-list-row-etc-label.negative {
  color: #cc624a;
}

.figmalist-body-list-row-etc-label.diff.positive {
  color: #388e3c;
}

.figmalist-body-list-row-etc-label.diff.negative {
  color: #b03a2e;
}

.figmalist-body-list-row-etc-label.multiline {
  flex-direction: column;
}

.figmalist-body-list-row-etc-label-power {
  display: flex;
  width: 50px;
  height: 22px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 1px;
  background: #1f160b;
  overflow: hidden;
  color: #ffc149;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.figmalist-body-list-row-etc-diff {
  position: absolute;
  top: 2.4em;
}

.figmalist-body-list-row-etc-diff.double {
  top: 3.2em;
}

.figmalist-body-list-row-extra {
  display: flex;
  height: 28px;
  padding: 5px 10px;
  align-items: center;
  align-self: stretch;
  border-radius: 0px 0px 6px 6px;
  background: #3e3326;
}

.figmalist-body-list-row-extra-inner {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 15px;
  flex: 1 0 0;
}

.figmalist-body-list-row-extra-title {
  color: #c4a87d;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.figmalist-body-list-row-extra-note {
  color: #c4a87d;
  text-align: left;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  display: flex;
}

.figmalist-body-detail {
  /* 상세 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 565px;
  height: 864px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-inner {
  /* Frame 313 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 100%;
  height: 808px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-title {
  /* Frame 331 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 0px 0px 4px;

  width: 100%;
  height: 28px;

  border-bottom: 1px solid #6b5d49;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.figmalist-body-detail-title-label {
  /* 용병 상세 */
  height: 24px;

  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */

  color: #f2e4b5;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-profile {
  /* 기본 프로필 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 17px;

  width: 100%;
  height: 136px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-profile-left {
  /* 사진 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  width: 109px;
  height: 135px;

  /*background: #FFFFFF;*/
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-profile-img {
  /* PUBG 1 */

  width: 100%;

  /* background: url(PUBG.png); */

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-profile-right {
  /* 프로필 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  height: 136px;

  /* Inside auto layout */
  flex: 1 0 0;
}

.figmalist-body-detail-profile-row {
  /* Frame 271 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 100%;
  height: 25px;
}

.figmalist-body-detail-profile-row-inner {
  /* Frame 276 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 40px;

  width: 100%;
  height: 20px;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.figmalist-body-detail-profile-row-item {
  /* 이름 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  height: 20px;

  /* Inside auto layout */
  flex: 1 0 0;
}

.figmalist-body-detail-profile-label-key {
  /* 이름 */
  height: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  display: flex;
  align-items: center;

  color: #c4a87d;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-profile-label-value-slot {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
}

.figmalist-body-detail-profile-label-value {
  /* 대한민국 */
  height: 11px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  /* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
  display: flex;
  align-items: center;

  color: #d3c6b2;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-profile-detail-button,
.overlay-flex button.figmalist-body-detail-profile-detail-button {
  display: flex;
  padding: 3px 10px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #9e8662;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-body-detail-profile-detail-button:hover {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #d1b182;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-body-detail-profile-detail-button:active {
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #6c593c;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-body-detail-profile-detail-button-label {
  color: #3d301c;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-profile-sep {
  /* Frame 268 */

  width: 100%;
  height: 1px;

  background: #6b5d49;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.figmalist-body-detail-box {
  /* 교육 이수 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px;

  width: 100%;

  border: 1px solid #6b5d49;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-box-header {
  /* Frame 280 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 6px 10px;
  gap: 10px;

  width: 100%;
  height: 22px;

  background: #453927;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.figmalist-body-detail-box-header-label {
  /* 교육 이수 */

  width: 543px;
  height: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* leading-trim and text-edge are draft CSS properties.

Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
*/
  leading-trim: both;
  text-edge: cap;
  display: flex;
  align-items: center;

  color: #c4a87d;

  /* Inside auto layout */
  flex: none;
  flex-grow: 1;
}

.figmalist-body-detail-box-body {
  /* Frame 279 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 14px;
  gap: 10px;

  width: 563px;
  height: 29px;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.figmalist-body-detail-box-body-label {
  /* 기초 스쿠버 과정, 고고도 활공침투 과정 */

  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #d3c6b2;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-box-firearmaptitudes {
  display: flex;
  padding: 4px 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.figmalist-body-detail-box-firearmaptitude {
  display: flex;
  align-items: center;
}

.figmalist-body-detail-box-firearmaptitude-ty {
  display: flex;
  padding: 6px 4px;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 4px;
  background: #dbbc6b;
}

.figmalist-body-detail-box-firearmaptitude-ty-label {
  color: #000;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  /* 12px */
  letter-spacing: 0.6px;
  text-transform: capitalize;
}

.figmalist-body-detail-box-firearmaptitude-dash {
  color: #6b5d49;
  text-align: center;
  font-family: "Roboto Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  /* 16px */
  letter-spacing: 0.8px;
  text-transform: capitalize;
}

.figmalist-body-detail-box-firearmaptitude-label {
  color: #d3c6b2;
  font-family: "Roboto Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: 0.8px;
  text-transform: capitalize;
}

.figmalist-body-detail-box2 {
  /* 탭 그룹 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  width: 100%;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-box-header-tabgroup {
  /* 탭 버튼 그룹 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 100%;
  height: 26px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-box-header-tab-on {
  position: relative;
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px 6px 0px 0px;
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  background: #6f5c40;
}

.figmalist-body-detail-box-header-tab-off {
  position: relative;
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px 6px 0px 0px;
  border-top: 2px solid rgba(255, 255, 255, 0.1);
  border-right: 2px solid rgba(255, 255, 255, 0.1);
  border-left: 2px solid rgba(255, 255, 255, 0.1);
  background: #483b27;
}

.figmalist-body-detail-box-header-tab-label-on {
  color: #dbc29d;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-box-header-tab-label-off {
  color: #977b5a;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-box-body2 {
  /* Frame 305 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 10px;

  height: 400px;
  width: 100%;

  border-width: 0px 2px 2px 2px;
  border-style: solid;
  border-color: #685744;
  border-radius: 0px 0px 5px 5px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-box-body2-inner {
  /* Frame 351 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px 5px 0px 15px;
  gap: 4px;

  margin: 0 auto;
  width: 100%;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 1;
}

.figmalist-body-detail-perks {
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px 15px 0px 15px;
  gap: 7px;
  isolation: isolate;

  width: 100%;

  flex: none;
  flex-grow: 1;

  overflow-y: auto;
}

.figmalist-body-detail-perkgroup {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: -4px;
  align-self: stretch;
}

.figmalist-body-detail-perk {
  position: relative;
  display: flex;
  height: 32px;
  padding: 3px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #9e8662;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
  z-index: 1;
}

.figmalist-body-detail-perk-labelgroup {
  display: flex;
  width: 285px;
  align-items: center;
  gap: 10px;
}

.figmalist-body-detail-perk-label {
  color: #3d301c;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-perk-count {
  height: 24px;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */
  text-align: center;

  color: #3d301c;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;

  margin-left: auto;
}

.figmalist-body-detail-perk-open {
  display: flex;
  padding: 14px 10px 8px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 0px 6px 6px 0px;
  background: #352c22;
}

.figmalist-body-detail-perk-activated {
  display: flex;
  align-items: center;
  align-self: stretch;
  position: relative;
  gap: 5px;
}

.figmalist-body-detail-perk-activated-icon {
  position: absolute;
  bottom: 50%;
}

.figmalist-body-detail-perk-activated-body {
  display: flex;
  padding: 6px 10px;
  margin-left: 20px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  background: #30361b;
  height: 53px;
}

.figmalist-body-detail-perk-activated-body-text {
  display: flex;
  flex: 1 0 0;
  overflow: auto;
  height: 49px;
}

.figmalist-body-detail-perk-activated-body-labelgroup {
  margin-top: 6px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.figmalist-body-detail-perk-activated-body-tags {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.figmalist-body-detail-perk-activated-body-tag {
  color: #6d5130;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-perk-activated-body-label {
  color: #f2e4b5;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-perk-activated-body-descr {
  color: #60a45a;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-perk-deactivated {
  display: flex;
  align-items: center;
  align-self: stretch;
  position: relative;
}

.figmalist-body-detail-perk-deactivated-icon {
  position: absolute;
  bottom: 50%;
}

.figmalist-body-detail-perk-deactivated-body {
  position: relative;
  display: flex;
  height: 53px;
  padding: 6px 10px;
  margin-left: 20px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  background: #45392a;
}

.figmalist-body-detail-perk-deactivated-body-labelgroup {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmalist-body-detail-perk-deactivated-body-label {
  color: #d2c6a7;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-perk-deactivated-body-button {
  display: flex;
  padding: 3px 20px;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #dfa612;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-body-detail-perk-deactivated-body-button-label {
  color: #422f0b;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-perk-deactivated-body-button:hover {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #ffd15b;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-body-detail-perk-deactivated-body-button:active {
  border-radius: 4px;
  border: 1px solid rgba(28, 28, 28, 0.2);
  background: #a27500;
}

.figmalist-body-detail-perk-blocked {
  display: flex;
  align-items: center;
  align-self: stretch;
  position: relative;
}

.figmalist-body-detail-perk-blocked-icon {
  position: absolute;
  bottom: 50%;
}

.figmalist-body-detail-perk-blocked-body {
  display: flex;
  height: 53px;
  padding: 6px 10px;
  margin-left: 20px;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  background: #423f3b;
}

.figmalist-body-detail-perk-blocked-body-labelgroup {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmalist-body-detail-perk-blocked-body-label {
  color: #87817a;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-modifiers {
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  background: #1d1312;
}

.figmalist-body-detail-modifier-item {
  display: flex;
  padding: 2px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 9px;
  background: #4e3526;
}

.figmalist-body-detail-modifier-item-inner {
  display: flex;
  padding: 1px;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 7px;
  background: #422a1d;
  height: 50px;
}

.figmalist-body-detail-modifier-item-icon {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  width: 50px;
  height: 50px;
  justify-content: center;
}

.figmalist-body-detail-modifier-item-label {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;

  color: #389e5a;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-box-salary {
  display: flex;
  padding: 3px 0px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;

  border-top: 2px solid #553d2c;
  border-bottom: 2px solid #553d2c;
  background: #21170e;

  color: #ffe2aa;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmalist-body-detail-box-salary-value {
  font-size: 24px;
}

.figmalist-body-detail-perk-buttons {
  /* Frame 332 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 15px;

  width: 100%;
  height: 44px;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.figmalist-body-detail-perk-button {
  /* 주요 버튼 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 85px;
  gap: 10px;

  width: 275px;
  height: 44px;

  background: #dfa612;
  border: 3px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 1;
}

.figmalist-body-detail-perk-button:hover {
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #ffd15b;
}

.figmalist-body-detail-perk-button:active {
  border-radius: 6px;
  border: 3px solid rgba(55, 55, 55, 0.2);
  background: #a27500;
}

.figmalist-body-detail-perk-button:disabled {
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  background: #4b4942;
}

.figmalist-body-detail-perk-button:disabled
  .figmalist-body-detail-perk-button-label {
  color: #737069;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail-perk-button-label {
  /* 면담 */

  height: 29px;

  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 29px;
  /* identical to box height */
  text-align: center;

  color: #422f0b;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.figmalist-body-detail-alert {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 275px;
  height: 44px;

  color: #ffe2aa;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  white-space: pre;
}

.figmalist-body-detail-stat-table {
  /* 스탯 상세 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px;

  width: 542px;
  height: 182px;

  border: 1px solid #645744;

  /* Inside auto layout */
  flex: none;
  flex-grow: 0;
}

.figmalist-body-detail-stat-table-col {
  /* 피지컬 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  height: 180px;

  /* Inside auto layout */
  flex: none;
  flex-grow: 1;
}

.figmalist-body-detail-stat-table-th {
  /* Frame 281 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  gap: 2px;

  width: 108px;
  height: 30px;

  background: #3e3220;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;

  border: 1px solid #645744;
}

.figmalist-body-detail-stat-table-th-label {
  /* 피지컬 */
  height: 18px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-decoration-line: underline;

  color: #c4a87d;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.figmalist-body-detail-stat-table-col-container {
  /* Frame 286 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 108px;
  height: 150px;

  background: #2d2518;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}

.figmalist-body-detail-stat-table-td {
  /* Frame 283 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;

  width: 108px;
  height: 50px;
  border: 1px solid #645744;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.figmalist-body-detail-stat-table-label {
  /* 체력 */
  height: 16px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  /* identical to box height */

  color: #a8927e;
}

.figmalist-body-detail-stat-table-value {
  /* 117 */
  height: 18px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: right;

  color: #d3c6b2;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.figmalist-body-list-header-state {
  /* Frame 377 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px 7px;
  gap: 30px;

  width: 100%;
  height: 31px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  z-index: 0;
}

.figmalist-body-list-header-item {
  /* Frame 43 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 10px;

  height: 24px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* identical to box height */
  color: #908173;
}

.figmalist-body-list-header-sep {
  /* Vector 28 */

  width: 0px;
  height: 24px;

  border: 1px solid #4e4233;
}

.figmalist-body-detail2 {
  display: flex;
  width: 554px;
  height: 829px;
  padding: 6px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;

  border: 2px solid #5a3a29;
  background: #382420;
}

.figmalist-body-detail2-title {
  display: flex;
  width: 542px;
  height: 42px;
  padding: 3px 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  background: #5a3a29;
}

.figmalist-body-detail2-title-inner {
  display: flex;
  height: 36px;
  padding: 0px 10px;
  justify-content: flex;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  align-self: stretch;
  border-top: 1px solid #734f3b;
  border-bottom: 1px solid #734f3b;
}

.figmalist-body-detail2-title-label {
  color: #c3a079;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.figmalist-body-detail2-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
}

.figmalist-body-detail2-profile-upper {
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.figmalist-body-detail2-profile-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
}

.figmalist-body-detail2-profile-lower {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
}

.figmalist-body-detail2-empty {
  height: 1px;
  flex-shrink: 0;
  align-self: stretch;
}

.figmalist-body-detail2-spec {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmalist-body-detail2-box2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.figmalist-body-detail2-box2-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.figmalist-body-detail2-box2-header-tabgroup {
  display: flex;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  position: relative;
}

.figmalist-body-detail2-box2-header-divider {
  height: 2px;
  align-self: stretch;

  background: #5a3a29;
}

.figmalist-body-detail2-box2-header-tab-on,
.figmalist-body-detail2-box2-header-tab-off {
  width: 173px;
  height: 32px;
  flex: 1 0 173px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.figmalist-body-detail2-box2-header-tab-outer {
  width: 100%;
  height: 32px;
  flex-shrink: 0;
  border-radius: 5px 5px 0px 0px;
  position: absolute;
}

.figmalist-body-detail2-box2-header-tab-inner {
  width: 100%;
  height: 30px;
  flex-shrink: 0;
  border-radius: 5px 5px 0px 0px;
  position: absolute;
  bottom: 0px;
}

.figmalist-body-detail2-box2-header-tab-on
  .figmalist-body-detail2-box2-header-tab-outer {
  background: #805842;
}

.figmalist-body-detail2-box2-header-tab-on
  .figmalist-body-detail2-box2-header-tab-inner {
  border-top: 2px solid #553625;
  border-right: 2px solid #553625;
  border-left: 2px solid #553625;
  background: #5a3a29;
}

.figmalist-body-detail2-box2-header-tab-off
  .figmalist-body-detail2-box2-header-tab-outer {
  background: #3d2723;
}

.figmalist-body-detail2-box2-header-tab-off
  .figmalist-body-detail2-box2-header-tab-inner {
  border-top: 2px solid #2f1d1a;
  border-right: 2px solid #2f1d1a;
  border-left: 2px solid #2f1d1a;
  background: #331f1c;
}

.figmalist-body-detail2-box2-header-tab-off:hover
  .figmalist-body-detail2-box2-header-tab-outer {
  background: #60403a;
}

.figmalist-body-detail2-box2-header-tab-off:hover
  .figmalist-body-detail2-box2-header-tab-inner {
  border-top: 2px solid #492e2a;
  border-right: 2px solid #492e2a;
  border-left: 2px solid #492e2a;
  background: #4e322d;
}

.figmalist-body-detail2-box2-header-tab-off:active
  .figmalist-body-detail2-box2-header-tab-outer {
  height: 31px;
  background: #2c1e1c;
}

.figmalist-body-detail2-box2-header-tab-off:active
  .figmalist-body-detail2-box2-header-tab-inner {
  height: 29px;
  border-top: 2px solid #221411;
  border-right: 2px solid #221411;
  border-left: 2px solid #221411;
  background: #261917;
}

.figmalist-body-detail2-box2-header-tab-label-on,
.figmalist-body-detail2-box2-header-tab-label-off {
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  position: relative;
}

.figmalist-body-detail2-box2-header-tab-label-on {
  color: #c3a079;
}

.figmalist-body-detail2-box2-header-tab-label-off {
  color: #7f6053;
}

.figmalist-body-detail2-box2-header-tab-off:hover
  .figmalist-body-detail2-box2-header-tab-label-off {
  color: #9a7e72;
}

.figmalist-body-detail2-box2-header-tab-off:active
  .figmalist-body-detail2-box2-header-tab-label-off {
  color: #9a7e72;
}

.figmalist-body-detail2-box-body2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.figmalist-body-detail2-stats-detail {
  display: flex;
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmalist-body-detail2-stats-graph-legend {
  display: flex;
  padding: 0px 15px;
  align-items: center;
  gap: 30px;
  align-self: stretch;
}

.figmalist-body-detail2-stats-graph-legend-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.figmalist-body-detail2-stats-graph-legend-item > span {
  text-align: center;
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail2-stats-graph-legend-item.current > span {
  color: #d59b57;
}

.figmalist-body-detail2-stats-graph-legend-item.potential > span {
  color: #af683f;
}

.figmalist-body-detail2-stats-graph-legend-item.expected > span {
  color: #8a795f;
}

.figmalist-body-detail2-stats-graph {
  display: flex;
  width: 540px;
  height: 200px;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(
    #55392a 0%,
    #55392a 1px,
    rgba(0, 0, 0, 0) 1px,
    rgba(0, 0, 0, 0) 100%
  );
  background-repeat: repeat-y;
  background-size: 100% 5%;
  border-bottom: 2px solid
    linear-gradient(
      rgba(139, 106, 65, 0) 0%,
      rgba(139, 106, 65, 1) 10%,
      rgba(139, 106, 65, 1) 90%,
      rgba(139, 106, 65, 0) 100%
    );

  position: relative;
}

.figmalist-body-detail2-stats-graph-item {
  flex: 1 0 0;
  position: relative;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.figmalist-body-detail2-stats-graph-item-expected {
  width: 48px;
  height: 3px;
  flex-shrink: 0;

  background: #8a795f;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.figmalist-body-detail2-stats-graph-item-potential {
  width: 48px;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* background: #5D2F14; */
  background-image: linear-gradient(
    135deg,
    #af683f 0%,
    #af683f 2px,
    #5d2f14 2px,
    #5d2f14 50%,
    #af683f 50%,
    #af683f calc(50% + 2px),
    #5d2f14 calc(50% + 2px),
    #5d2f14 100%
  );
  background-size: 8px 8px;
}

.figmalist-body-detail2-stats-graph-item-current {
  width: 48px;
  flex-shrink: 0;

  background: #d59b57;
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translateX(-50%);
}

.figmalist-body-detail2-stats-graph-item-value {
  color: #4e2d28;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  position: relative;
  background: #d59b57;
  paint-order: stroke fill;
  padding: 0px 4px;
}

.figmalist-body-detail2-stats-graph-item-potential-value {
  color: #af683f;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  position: absolute;
  background: #5d2f14;
  paint-order: stroke fill;
  transform: translateY(50%);
  padding: 0px 4px;
}

.figmalist-body-detail2-stats-graph-divider {
  height: 2px;
  width: 100%;
  background: linear-gradient(
    to right,
    rgba(139, 106, 65, 0) 0%,
    rgba(139, 106, 65, 1) 10%,
    rgba(139, 106, 65, 1) 90%,
    rgba(139, 106, 65, 0) 100%
  );
}

.figmalist-body-detail2-stats-table {
  display: flex;
  width: 542px;
  height: 211px;
  padding-bottom: 1px;
  align-items: flex-start;
}

.figmalist-body-detail2-stat-table-col {
  display: flex;
  padding: 0px 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex: 1 1 0;
  overflow: hidden;
}

.figmalist-body-detail2-stat-table-th {
  display: flex;
  height: 34px;
  padding: 0px 1px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 0 0 34px;
  align-self: stretch;
}

.figmalist-body-detail2-stat-table-th-label {
  color: #c39762;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.figmalist-body-detail2-stat-table-col-container {
  display: flex;
  padding: 0px 1px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex: 0 0 174px;
  align-self: stretch;
}

.figmalist-body-detail2-stat-table-td {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 56px;
  align-self: stretch;

  background: #3d2825;

  overflow: hidden;
}

.figmalist-body-detail2-stat-table-label {
  color: #876c59;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail2-stat-table-value {
  color: #bfaa8a;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail2-stat-table-col:nth-child(odd)
  .figmalist-body-detail2-stat-table-th {
  background: linear-gradient(0deg, #452f23 0%, rgba(69, 47, 35, 0.5) 95.59%);
}

.figmalist-body-detail2-stat-table-col:nth-child(odd)
  .figmalist-body-detail2-stat-table-td {
  background: #3d2825;
}

.figmalist-body-detail2-stat-table-col:nth-child(even)
  .figmalist-body-detail2-stat-table-th {
  background: linear-gradient(0deg, #543a2b 0%, rgba(84, 58, 43, 0.5) 95.59%);
}

.figmalist-body-detail2-stat-table-col:nth-child(even)
  .figmalist-body-detail2-stat-table-td {
  background: #442d2a;
}

.figmalist-body-detail2-box2-salary {
  display: flex;
  width: 540px;
  height: 43px;
  padding: 3px 20px;
  justify-content: center;
  align-items: center;
  gap: 19px;

  border: 2px solid #4f3430;
  background: #442d2a;
  position: relative;
}

.figmalist-body-detail2-box2-salary-title {
  color: #af8364;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail2-box2-salary-value {
  color: #ffd28e;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmalist-body-detail2-box2-salary-deco {
  height: 3px;
  width: 115.5px;
  position: absolute;
  top: 21.5px;
  background: #66483f;
}

.figmalist-body-detail2-box2-salary-deco.left {
  left: 20px;
}

.figmalist-body-detail2-box2-salary-deco.right {
  right: 20px;
}

/* .figmalist-body-detail2-box2-salary:hover .left {
  animation-name: decoration-left;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

@keyframes decoration-left {
  0% {
    left: 20px;
  }
  100% {
    left: 60px;
  }
}

.figmalist-body-detail2-box2-salary:hover .right {
  animation-name: decoration-right;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
}

@keyframes decoration-right {
  0% {
    right: 20px;
  }
  100% {
    right: 60px;
  }
} */

.figmalist-body-detail-profile-row2 {
  display: flex;
  height: 28px;
  padding: 0px 5px;
  align-items: center;
  align-self: stretch;
}

.figmalist-body-detail2-profile-sep {
  height: 1px;
  align-self: stretch;
  background: #543934;
}

.figmalist-body-detail2-profile-row-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.figmalist-body-detail2-profile-label-key {
  color: #876c59;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail2-profile-label-value-slot {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;

  position: relative;
}

.figmalist-body-detail2-profile-label-value {
  color: #bfaa8a;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-detail2-profile-levels {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
}

.figmalist-body-detail2-profile-level {
  color: #3ea58b;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figma-progressbar2 {
  display: flex;
  padding: 1px 0px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  width: 100%;
  position: relative;
}

.figma-progressbar2-bg {
  width: 100%;
  height: 22px;
  flex-shrink: 0;
  border-radius: 11px;
  background: #110806;
  position: absolute;
  overflow: hidden;
  padding: 1px;
}

.figma-progressbar2-fill {
  width: 100%;
  height: 20px;
  border-radius: 10px;
  background: #204e43;
}

.figma-progressbar2-label {
  color: #79a590;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
}

.figmalist-body-detail2-profile-detail-button {
  display: inline-flex;
  padding-bottom: 2px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.5);

  position: absolute;
  right: 0px;
}

button.btn-tiny {
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.5);

  /* hack */
  padding: 0;
  background-color: transparent;
  border: 0px;
}

.btn-tiny-inner {
  display: flex;
  padding: 1px 10px 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 3px;
  border: 1.5px solid #e5ac57;
  background: #d59536;
  box-shadow: 0px 2px 0px 0px #8a580f;
}

.btn-tiny-label {
  color: #431111;
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

/* disabled */
.btn-tiny:disabled .btn-tiny-inner {
  background: #4e433d;
  border: 1.5px solid #5e504a;
  box-shadow: 0px 2px 0px #3e332d;
  border-radius: 3px;
}

.btn-tiny:disabled .btn-tiny-label {
  color: #7a6a63;
}

.btn-tiny:disabled:hover .btn-tiny-inner {
  background: #4e433d;
  border: 1.5px solid #5e504a;
  box-shadow: 0px 2px 0px #3e332d;
  border-radius: 3px;
}

.btn-tiny:disabled:hover .btn-tiny-label {
  color: #7a6a63;
}

.btn-tiny:hover .btn-tiny-inner {
  background: #f3b861;
  box-shadow: 0px 2px 0px 0px #bc802a;
}

.btn-tiny:hover .btn-tiny-label {
  color: #7c4a0f;
}

.btn-tiny:active {
  display: inline-flex;
  padding: 1px 0px;
  box-shadow: unset;
}

.btn-tiny:active .btn-tiny-inner {
  border: 1.5px solid #b87b20;
  background: #b1751d;
  box-shadow: 0px 1px 0px 0px #8a580f;
}

.btn-tiny:active .btn-tiny-label {
  color: #431111;
}

.figmalist-body-detail2-profile-aptitudes {
  display: flex;
  align-items: flex-start;
  gap: 7px;
}

.figma-badge2-aptitude {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  padding: 0px 9px;
  clip-path: polygon(
    0% 3px,
    0% calc(100% - 3px),
    3px 100%,
    calc(100% - 3px) 100%,
    100% calc(100% - 3px),
    100% 3px,
    calc(100% - 3px) 0%,
    3px 0%
  );
}

.figma-badge2-aptitude-label {
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figma-badge2-aptitude-1,
.figma-badge2-aptitude-2 {
  background: #086294;
}

.figma-badge2-aptitude-1 svg path,
.figma-badge2-aptitude-2 svg path {
  fill: #70c3ff;
}

.figma-badge2-aptitude-1 .figma-badge2-aptitude-label,
.figma-badge2-aptitude-2 .figma-badge2-aptitude-label {
  color: #70c3ff;
}

.figma-badge2-aptitude-3 {
  background: #2c5e37;
}

.figma-badge2-aptitude-3 svg path {
  fill: #77c583;
}

.figma-badge2-aptitude-3 .figma-badge2-aptitude-label {
  color: #77c583;
}

.figma-badge2-aptitude-4,
.figma-badge2-aptitude-5 {
  background: #483728;
}

.figma-badge2-aptitude-4 svg path,
.figma-badge2-aptitude-5 svg path {
  fill: #a88e76;
}

.figma-badge2-aptitude-4 .figma-badge2-aptitude-label,
.figma-badge2-aptitude-5 .figma-badge2-aptitude-label {
  color: #a88e76;
}

.figma-badge2-aptitude-thumbsups {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.figma-badge2-nationality {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.figma-badge2-nationality-flag {
  width: 27px;
  height: 20px;
  border: 1px solid #fff;
}

.figma-badge2-nationality-label {
  color: #bfaa8a;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2 {
  display: flex;
  width: 1748px;
  align-items: flex-start;
  gap: 4px;
}

.figmalist-body2-list {
  display: flex;
  height: 829px;
  padding-top: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;

  background: #291917;
}

.figmalist-body2-list-tabs {
  display: flex;
  height: 24px;
  padding: 0px 14px 0px 4px;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;
  align-self: stretch;
}

.figmalist-body2-list-tab {
  display: flex;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 5px;
  border: 1.5px solid #3d2a28;
  background: #31211f;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-body2-list-tab.name {
  width: 184px;
}

.figmalist-body2-list-tab.role {
  width: 150px;
}

.figmalist-body2-list-tab.grade,
.figmalist-body2-list-tab.level {
  width: 70px;
}

.figmalist-body2-list-tab.power {
  width: 200px;
}

.figmalist-body2-list-tab.modifiers {
  width: 210px;
}

.figmalist-body2-list-tab.relation {
  flex: 1 0 0;
}

.figmalist-body2-list-tab.payment {
  width: 120px;
}

.figmalist-body2-list-tab-label {
  color: #876c59;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  text-wrap: nowrap;
}

.figmalist-body2-list-body-wrapper {
  display: flex;
  padding: 4px 2px 0px 4px;
  align-items: flex-start;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  background: #19100f;
  overflow-y: scroll;
}

.figmalist-body2-list-body {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
  flex: 1 0 0;
  width: 100%;
}

.figmalist-body2-list-body-wrapper::-webkit-scrollbar {
  width: 12px;
}

.figmalist-body2-list-body-wrapper::-webkit-scrollbar-thumb {
  background: #56382e;
  width: 4px;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 12px;
}

.figmalist-body2-list-body-wrapper::-webkit-scrollbar-track {
  background: transparent;
}

.figmalist-body2-list-row {
  display: flex;
  flex-direction: column;
  padding: 6px 2px;
  align-self: stretch;
  box-sizing: border-box;
  height: 44px;

  border: 2px solid #3c2421;
  background: #291917;
}

.figmalist-body2-list-row.selected {
  padding: 4px 0px;
  border: 4px solid #805842;
  background: #3b2420;
}

.figmalist-body2-list-row-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  margin-top: auto;
  margin-bottom: auto;
}

.figmalist-body2-list-row-item.name {
  display: flex;
  width: 184px;
  height: 30px;
  padding: 4px 15px;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
}

.figmalist-body2-list-row-item.role {
  display: flex;
  width: 150px;
  height: 30px;
  padding: 0px 10px;
  align-items: center;
  gap: 5px;
  justify-content: flex-start;
}

.figmalist-body2-list-row-item.tier {
  display: flex;
  width: 70px;
  height: 30px;
  padding: 1px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.figmalist-body2-list-row-item.level {
  display: flex;
  width: 70px;
  height: 30px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.figmalist-body2-list-row-item.power {
  display: flex;
  width: 200px;
  height: 30px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.figmalist-body2-list-row-item.relation {
  display: flex;
  width: 144px;
  height: 30px;
  padding: 4px 15px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
}

.figmalist-body2-list-row-item.modifiers {
  width: 210px;
  display: flex;
  flex-direction: row;
  height: 30px;
  padding: 4px 15px;
  justify-content: left;
  align-items: flex-start;
  gap: 10px;
}

.figmalist-body2-list-row-item.payment {
  display: flex;
  width: 120px;
  height: 30px;
  padding: 1px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.figmalist-body2-list-row-item-label {
  color: #cab7a2;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-list-row-item-label.inline {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.figmalist-body2-list-row-item.name .figmalist-body2-list-row-item-label {
  color: #cab7a2;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-list-row-item.level .figmalist-body2-list-row-item-label {
  color: #3ea58b;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-list-row-item.power .figmalist-body2-list-row-item-label {
  color: #34b6c8;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  gap: 4px;
}

.figmalist-body2-list-row-item.condition .figmalist-body2-list-row-item-label,
.figmalist-body2-list-row-item.mood .figmalist-body2-list-row-item-label,
.figmalist-body2-list-row-item.relation .figmalist-body2-list-row-item-label {
  color: #bfaa8a;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-list-row-item.condition
  .figmalist-body2-list-row-item-label.positive,
.figmalist-body2-list-row-item.mood
  .figmalist-body2-list-row-item-label.positive,
.figmalist-body2-list-row-item.relation
  .figmalist-body2-list-row-item-label.positive,
.figmalist-body2-card-row-item-smily-value.positive {
  color: #389e5a;
}

.figmalist-body2-list-row-item.condition
  .figmalist-body2-list-row-item-label.negative,
.figmalist-body2-list-row-item.mood
  .figmalist-body2-list-row-item-label.negative,
.figmalist-body2-list-row-item.relation
  .figmalist-body2-list-row-item-label.negative,
.figmalist-body2-card-row-item-smily-value.negative {
  color: #d12121;
}

.figmalist-root2 {
  width: 1760px;
  height: 891px;
  flex-shrink: 0;

  position: absolute;
  margin: auto;

  background: #291917;
  border: 2px solid #3c2421;

  user-select: none;

  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.figmalist-header2 {
  display: flex;
  width: 1748px;
  height: 46px;
  padding: 0px 15px;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;

  background: #1c1212;
}

.figmalist-header2-inner {
  width: 100%;
  height: 100%;
  display: flex;
}

.figmalist-header2-title {
  display: flex;
  align-items: center;
  gap: 12px;
}

.figmalist-header2-title-bullet {
  width: 3px;
  height: 26px;
  background: #6b543f;
}

.figmalist-header2-title-label {
  display: flex;
  padding-bottom: 3px;
  align-items: center;
  gap: 10px;

  color: #e4c89e;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-header2-title-button {
  display: inline-flex;
  height: 34px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
}

.figmalist-header2-title-button-inner {
  display: flex;
  padding: 1px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 2px solid #ae9564;
  background: #9a8355;
  box-shadow: 0px 2px 0px 0px #75633f;
}

.figmalist-header2-title-button-label {
  display: flex;
  padding: 0px 2px;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  color: #382500;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-header2-title-button:hover .figmalist-header2-title-button-inner {
  border: 2px solid #ccb17d;
  background: #bda679;
}

.figmalist-header2-title-button:hover
  .figmalist-header2-title-button-icon
  path {
  fill: #644e24;
}

.figmalist-header2-title-button:hover .figmalist-header2-title-button-label {
  color: #644e24;
}

.figmalist-header2-title-button:active {
  padding-bottom: 1px;
}

.figmalist-header2-title-button:active .figmalist-header2-title-button-inner {
  border: 2px solid #88744c;
  background: #796846;
}

.figmalist-header2-title-button:active
  .figmalist-header2-title-button-icon
  path {
  fill: #382500;
}

.figmalist-header2-title-button:active .figmalist-header2-title-button-label {
  color: #382500;
}

.outgame-lobby .figmalist-root2,
.outgame-lobby .figmalist-agents-root2,
.figmalist-testroot .figmalist-agents-root2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.figmalist-agents-root2 {
  width: 1760px;
  height: 891px;
  flex-shrink: 0;

  position: absolute;
  margin: auto;

  background: #291917;
  border: 2px solid #3c2421;

  user-select: none;

  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: center;
}

.figmalist-agents-body2 {
  display: flex;
  width: 1748px;
  align-items: center;
  gap: 4px;
}

.figmalist-agents-body2-list {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
}

.figmalist-body2-cardboard {
  display: flex;
  height: 829px;
  padding: 4px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 5px;
  flex: 1 0 0;
  flex-wrap: wrap;
  overflow-y: auto;

  background: #1d0f0d;
  border: 4px solid #39201b;
}

.figmalist-body2-cardboard::-webkit-scrollbar {
  width: 0px;
}

.figmalist-body2-cardboard::-webkit-scrollbar-thumb {
  background: #56382e;
}

.figmalist-body2-cardboard::-webkit-scrollbar-track {
  background: transparent;
}

.figmalist-body2-cardboard-card {
  display: flex;
  width: 550px;
  padding-bottom: 7px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;
  box-sizing: border-box;

  border: 2px solid #55382b;
  background: #291917;
}

.figmalist-body2-card-header {
  display: flex;
  padding: 2px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  box-sizing: border-box;

  background: #3c2421;
}

.figmalist-body2-cardboard-card.selected {
  padding-bottom: 5px;
  border: 4px solid #ecb16b;
}

.figmalist-body2-cardboard-card.selected .figmalist-body2-card-header {
  padding: 0px 0px 2px 0px;
  background: #4c2c28;
}

.figmalist-body2-card-header-role {
  display: flex;
  padding: 6px 5px 4px 5px;
  justify-content: center;
  align-items: center;
}

.figmalist-body2-card-header-name {
  color: #cab7a2;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-card-body {
  display: flex;
  padding: 0px 5px;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.figmalist-body2-cardboard-card.selected .figmalist-body2-card-body {
  padding: 0px 3px;
}

.figmalist-body2-card-primary {
  display: flex;
  padding: 0px 3px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.figmalist-body2-card-primary-left {
  display: flex;
  padding: 7px;
  align-items: center;
  gap: 10px;
}

.figmalist-body2-card-primary-right {
  display: flex;
  padding-top: 2px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.figmalist-body2-card-primary-row-1 {
  display: flex;
  height: 36px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmalist-body2-card-row-item-grade {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmalist-body2-card-row-item-label {
  color: #876c59;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-card-row-divider {
  width: 1px;
  height: 100%;
  background: #4c382a;
}

.figmalist-body2-card-row-item-level {
  display: flex;
  padding: 0px 5px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
}

.figmalist-body2-card-primary-divider {
  height: 1px;
  width: 100%;
  background: #4c382a;
}

.figmalist-body2-card-primary-row-2 {
  display: flex;
  height: 36px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.figmalist-body2-card-primary-row-item {
  display: flex;
  height: 36px;
  padding: 4px 10px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.figmalist-body2-card-row-item-value {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  align-self: stretch;
}

.figmalist-body2-list-row-item-value-label {
  width: 36px;
  color: #34b6c8;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-card-primary-row-3 {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
  height: 59px;
}

.figmalist-body2-card-row-item-smily {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmalist-body2-card-row-item-smily-label {
  color: #876c59;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-card-row-item-smily-value {
  color: #876c59;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-card-secondary {
  display: flex;
  padding: 3px 3px 3px 10px;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  background: #452a25;
}

.figmalist-body2-card-right-secondary {
  display: flex;
  height: 62px;
  padding: 3px 0px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;
}

.figmalist-body2-card-secondary-row {
  display: flex;
  height: 26px;
  padding: 0px 10px;
  align-items: center;
  gap: 5px;
  flex-shrink: 0;
  align-self: stretch;

  background: #452a25;
}

.figmalist-body2-card-secondary-row-item-label {
  color: #a98972;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-card-secondary-row-item-value {
  color: #dbc5a3;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-card-notes {
  display: flex;
  height: 38px;
  padding: 5px;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  overflow-y: scroll;
  flex-wrap: wrap;

  background: #110806;
}

.figmalist-body2-card-notes::-webkit-scrollbar {
  width: 12px;
}

.figmalist-body2-card-notes::-webkit-scrollbar-thumb {
  background: #56382e;
  width: 4px;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 12px;
}

.figmalist-body2-card-notes::-webkit-scrollbar-track {
  background: transparent;
}

.figmalist-body2-card-notes-title {
  color: #a98972;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body2-tabs {
  display: flex;
  width: 69px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 4px;
}

.figmalist-body2-tab {
  width: 69px;
  height: 85px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.figmalist-body2-tab.selected {
  background: unset;
}

.figmalist-body2-tab > svg {
  position: absolute;
  top: 0px;
  left: 0px;
}

.figmalist-body2-tab:hover .figmalist-body2-tab-svg-1 {
  fill: #60403a;
}

.figmalist-body2-tab:hover .figmalist-body2-tab-svg-3 {
  fill: #4e322d;
}

.figmalist-body2-tab:hover .figmalist-body2-tab-svg-4 {
  fill: #492e2a;
}

.figmalist-body2-tab:hover .figmalist-role-icon > path {
  fill: #9a7e72;
}

.figmalist-body2-tab:active .figmalist-body2-tab-svg-1 {
  d: path("M0 7C0 3.13401 3.13401 0 7 0H69V85H7C3.13401 85 0 81.866 0 78V7Z");
  fill: #3d2723;
}

.figmalist-body2-tab:active .figmalist-body2-tab-svg-2 {
  d: path("M4 7C4 3.13401 7.13401 0 11 0H69V85H11C7.13401 85 4 81.866 4 78V7Z");
}

.figmalist-body2-tab:active .figmalist-body2-tab-svg-3 {
  d: path("M4 7C4 3.13401 7.13401 0 11 0H69V85H11C7.13401 85 4 81.866 4 78V7Z");
  fill: #261917;
}

.figmalist-body2-tab:active .figmalist-body2-tab-svg-4 {
  d: path(
    "M2 7C2 2.02944 6.02944 -2 11 -2H69V2H11C8.23858 2 6 4.23858 6 7H2ZM69 87H11C6.02944 87 2 82.9706 2 78H6C6 80.7614 8.23858 83 11 83H69V87ZM11 87C6.02944 87 2 82.9706 2 78V7C2 2.02944 6.02944 -2 11 -2V2C8.23858 2 6 4.23858 6 7V78C6 80.7614 8.23858 83 11 83V87ZM69 0V85V0Z"
  );
  fill: #221411;
}

.figmalist-body2-tab:active .figmalist-role-icon > path {
  fill: #9a7e72;
}

.figmalist-body2-tab.selected .figmalist-body2-tab-svg-1 {
  d: path(
    "M0 7C0 3.13401 3.13401 0 7 0H69V85H7C3.13401 85 0 81.866 0 78V7Z"
  ) !important;
  fill: #805842 !important;
}

.figmalist-body2-tab.selected .figmalist-body2-tab-svg-2 {
  d: path(
    "M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z"
  ) !important;
  fill: #5a3a29 !important;
}

.figmalist-body2-tab.selected .figmalist-body2-tab-svg-3 {
  d: path(
    "M2 7C2 3.13401 5.13401 0 9 0H69V85H9C5.13401 85 2 81.866 2 78V7Z"
  ) !important;
  fill: #5a3a29 !important;
}

.figmalist-body2-tab.selected .figmalist-body2-tab-svg-4 {
  d: path(
    "M0 7C0 2.02944 4.02944 -2 9 -2H69V2H9C6.23858 2 4 4.23858 4 7H0ZM69 87H9C4.02944 87 0 82.9706 0 78H4C4 80.7614 6.23858 83 9 83H69V87ZM9 87C4.02944 87 0 82.9706 0 78V7C0 2.02944 4.02944 -2 9 -2V2C6.23858 2 4 4.23858 4 7V78C4 80.7614 6.23858 83 9 83V87ZM69 0V85V0Z"
  ) !important;
  fill: #553625;
}

.figmalist-body2-tab.selected .figmalist-role-icon > path {
  fill: #c3a079 !important;
}

.figmalist-body2-tab-inner {
  position: relative;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.figmalist-body2-tab-icon {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.figmalist-body2-tab-icon > svg {
  width: 25px;
  height: 25px;
}

.figmalist-body2-tab-label {
  color: #7f6053;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
