.perk-level {
  height: 145px;
  width: 30px;
}

.perk-level .overlap-group-15 {
  height: 41px;
  position: relative;
}

.perk-level .outer-bg {
  background-color: #957931;
  height: 41px;
  left: 0;
  position: absolute;
  top: 0;
  width: 30px;
}

.perk-level .inner-line {
  border: 1px solid;
  border-color: #fde286;
  height: 37px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 26px;
}

.perk-level .PT-perklevel {
  color: #ffe99d;
  font-family: "Freshman", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 30px;
  left: 7px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 6px;
  width: 16px;
}
