.figmalist-body-list-row-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;

  height: 52px;

  flex: 2.5 0 0;
}

.figmalist-body-list-row-name-img {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  margin-right: 8px;

  height: 44px;

  /*background: #FFFFFF;*/
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.figmalist-body-list-row-name-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  height: 40px;

  position: relative;
}

.figmalist-body-list-row-name-upper {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-bottom: 10px;

  height: 19px;

  flex: none;
  flex-grow: 0;
}

.figmalist-body-list-row-name-upper-sep {
  width: 1px;
  height: 12px;

  background: #5e4f4f;

  flex: none;
  flex-grow: 0;
}

.figmalist-body-list-row-name-lower {
  height: 11px;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;

  display: flex;
  align-items: center;

  color: #d3c6b2;

  white-space: nowrap;
}

.figmalist-body-list-row-name-lower-name {
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 6px;
}

/* RenderAgentCardEvent */
.figmapopup-content-agent {
  width: 126px;
  height: 167px;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.figmapopup-content-agent-background {
  width: 120px;
  height: 160px;
  flex-shrink: 0;
  position: absolute;
  left: 3px;
  right: 3px;
  top: 0px;
  bottom: 7px;
  background: #ccc7af;
}

.figmapopup-content-agent-shadow {
  width: 110px;
  height: 133px;
  flex-shrink: 0;
  position: absolute;
  left: 8px;
  right: 8px;
  top: 5px;
  bottom: 29px;
  opacity: 0.5;
  background: #3a3937;
  filter: blur(2px);
}

.figmapopup-content-agent-names {
  width: 114px;
  height: 25px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.figmapopup-content-agent-names-bg {
  width: 126px;
  height: 25px;
  position: absolute;
  max-width: unset;
}

.figmapopup-content-agent-names-name {
  color: #ece9d8;
  text-align: center;
  /* font-family: Caveat, "나눔손글씨"; */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
}

/* role badge */
.figma-badge2-role {
  display: flex;
  align-items: center;
}

.figma-badge2-role-label {
  color: #9f8967;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  align-items: center;
  text-align: center;

  margin-left: 5px;
}

.figma-badge2-role.pointman .figma-badge2-role-label {
  color: #308dc1;
}

.figma-badge2-role.medic .figma-badge2-role-label {
  color: #67ba33;
}

.figma-badge2-role.vanguard .figma-badge2-role-label {
  color: #dd9e3e;
}

.figma-badge2-role.breacher .figma-badge2-role-label {
  color: #a7928d;
}

.figma-badge2-role.sharpshooter .figma-badge2-role-label,
.figma-badge2-role.scout .figma-badge2-role-label {
  color: #a25cc3;
}

/* detail */
.lobby-squad-drawer-right-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
}

.lobby-squad-drawer-item-label {
  color: var(--c-gr1-transpbox-fg-desc);
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.56px;
  white-space: nowrap;
}

.lobby-squad-drawer-item-value {
  color: var(--c-gr1-agent-fg);
  text-align: right;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.6px;
  white-space: nowrap;
}

.lobby-squad-drawer-item-value.positive {
  color: var(--c-gr1-transpbox-fg-positive);
}

.lobby-squad-drawer-item-value.negative {
  color: var(--c-gr1-transpbox-fg-negative);
}

/* evalCSSClass */
.value.positive {
  color: #92e22f;
}

.value.negative {
  color: #ef2b00;
}

/* role badge label */
.figma-labelbadge-role {
  display: flex;
  padding: 2px 7px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 5px;
}

.figma-labelbadge-role.pointman {
  border: 1px solid #2a426e;
  background: #25355c;
}

.figma-labelbadge-role.medic {
  border: 1px solid #304b25;
  background: #24401e;
}

.figma-labelbadge-role.vanguard {
  border: 1px solid #7d511b;
  background: #684112;
}

.figma-labelbadge-role.breacher {
  border: 1px solid #514839;
  background: #433d32;
}

.figma-labelbadge-role.sharpshooter,
.figma-labelbadge-role.scout {
  border: 1px solid #773279;
  background: #67276c;
}

.figma-labelbadge-role-label {
  color: #9f8967;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  align-items: center;
  text-align: center;

  margin-left: 5px;
}

.figma-labelbadge-role.pointman .figma-labelbadge-role-label {
  color: #49bbdc;
}

.figma-labelbadge-role.medic .figma-labelbadge-role-label {
  color: #5b9a34;
}

.figma-labelbadge-role.vanguard .figma-labelbadge-role-label {
  color: #d39c4a;
}

.figma-labelbadge-role.breacher .figma-labelbadge-role-label {
  color: #a8a091;
}

.figma-labelbadge-role.sharpshooter .figma-labelbadge-role-label,
.figma-labelbadge-role.scout .figma-labelbadge-role-label {
  color: #e667fb;
}
