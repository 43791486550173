.cashbook-root tr,
.cashbook-root td,
.cashbook-root th
{
  border: 1px solid lightgray;
}

.cashbook-root th:last-of-type {
  border-right: unset;
}

.cashbook-pos {
  color: var(--c-fh1-delta-positive);
  border-color: inherit;
}

.cashbook-zero {
  border-color: inherit;
}

.cashbook-neg {
  color: var(--c-fh1-delta-negative);
  border-color: inherit;
}


