.character-card-compact-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.character-card-compact {
  border: 1px solid #ccc;
  margin: 5px;
}

.character-card-compact-body {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 160px;
}

.inline-vertical-separator {
  border-left: 1px solid #ccc;
  height: 100%;
  margin: 0 5px;
}

.inline-vertical-separator-black {
  border-left: 2px solid black;
  height: 100%;
  margin: 5px;
}


.character-card-compact-note-small {
  color: #999;
  vertical-align: bottom;
}


.character-card-compact-profile-small {
  font-size: 0.8em;
  color: #999;
}
