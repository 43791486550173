/* styles.css */
.briefing-root {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: relative;
}

.briefing-letterbox-upper {
  height: 15%;
  width: 100%;
  position: relative;
  left: 0;
  top: 0;
  background-color: black;
  z-index: 1;
}

.briefing-letterbox-lower {
  height: 15%;
  width: 100%;
  position: relative;
  left: 0;
  bottom: 0;
  background-color: black;
  z-index: 1;
}

.briefing-image-container {
  position: relative;
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  transform: scale(1.2);
  opacity: 0;
}

.briefing-image-background,
.briefing-image-overlay-B,
.briefing-image-overlay-A,
.briefing-image-contents,
.briefing-image-light {
  display: block;
  position: absolute;
  max-width: none;
  pointer-events: none;
  user-select: none;
}

.briefing-image-background,
.briefing-image-overlay-B,
.briefing-image-overlay-A {
  left: 50%;
  top: -20%;
  transform: translate(-50%, -0%);
}

.briefing-image-contents {
  left: 50%;
  top: -20%;
  transform: translate(-50%, 40%);
}

.briefing-image-light {
  left: 50%;
  top: -20%;
  transform: translate(-1744%, 1446%);
}

.briefing-image-overlay-B,
.briefing-image-overlay-A {
  mix-blend-mode: overlay;
}

@keyframes twinkleLamp {
  0% {
    opacity: 1;
  }

  2% {
    opacity: 0;
  }

  4% {
    opacity: 1;
  }

  5% {
    opacity: 0;
  }

  6% {
    opacity: 1;
  }

  55% {
    opacity: 1;
  }

  65% {
    opacity: 0.7;
  }

  75% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.briefing-image-overlay-B.twinkle,
.briefing-image-overlay-A.twinkle {
  animation: twinkleLamp 4.5s linear infinite;
}

@keyframes twinkleLight {
  0% {
    opacity: 1;
  }

  1% {
    opacity: 0;
  }

  2% {
    opacity: 1;
  }

  3% {
    opacity: 0;
  }

  4% {
    opacity: 1;
  }

  5% {
    opacity: 0;
  }

  6% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

.briefing-image-light.twinkle {
  animation: twinkleLamp 6s linear infinite;
}

.briefing-background-shade {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 0.1;
  transition: opacity 0.5s ease-in-out;
}

.briefing-contents-lower {
  width: 100%;
  height: 30%;
  position: absolute;
  left: 0;
  bottom: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.briefing-contents-lower-shade {
  width: 100%;
  height: 70%;
  position: absolute;
  left: 0;
  bottom: 50%;
  transform: translateY(50%);
  background-color: rgba(0, 0, 0, 0);
  backdrop-filter: blur(0px);
  transition: background-color 0.5s ease-in-out, backdrop-filter 0.5s ease-in-out;
}

.briefing-contents-text {
  color: white;
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  text-align: center;
  pointer-events: none;
}

.briefing-contents-image {
  position: absolute;
  left: 50%;
  top: 50%;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}