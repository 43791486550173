.agent-detail-group {
  background-color: var(--c-title00);

  flex-direction: column;
  border: 1px solid;
  margin: 0.2em 0.4em;
  padding: 0.2em 0.4em;
}

.agent-detail-stat {
  color: var(--c-grid00);
  flex-direction: column;
  margin: 1em;
}

.agent-detail-stat>div {
  @apply agent-detail-group;
}

.agent-detail-perk {
  color: var(--c-grid00);
  flex-direction: column;
  margin: 1em;
  height: 90%;
  overflow: auto;
}

.agent-detail-perk div {
  flex-direction: column;
}

.agent-detail-perk-header,
.agent-detail-perk-lv {
  @apply agent-detail-group;
}

.stat-perk-off {
  color: gray;
}

.stat-perk-avail {
  color: lightgray;
}

.stat-perk-on {
  color: #c8c;
}

.agent-stat-gauges td:first-child {
  flex: 0 0 80px;
}

.agent-stat-gauges {
  display: table;
}

.agent-stat-gauges thead {
  display: table-header-group;
}

.agent-stat-gauges tbody {
  display: table-row-group;
}

.agent-stat-gauges tr {
  display: table-row;
}

.agent-stat-gauges td {
  display: table-cell;
}

.agent-stat-gauges .agent-stat-value {
  display: flex;
  align-items: center;
}

.agent-stat-gauges .agent-stat-value .agent-stat-progress {
  flex: 0 0 80px;
  margin-right: 10px;
  display: flex;
}

.agent-stat-gauges-help {
  position: relative;
}

.agent-stat-gauges-help-inner {
  position: absolute;
  background-color: var(--c-popup00);
  width: 400px;
  height: 200px;
  top: 20px;
  left: 10px;
  border: 5px solid black;
  z-index: 1;
  padding: 5px;
}

.agent-stat-gauges-help-closebutton {
  position: absolute;
  right: 5px;
}

.agent-stat-gauges-help-title {
  font-size: 1.2em;
  padding-bottom: 5px;
  text-align: center;
}

.agent-stat-gauges-help-body {
  display: flex;
}

.agent-stat-gauges-help-body-image {
  width: 120px;
  height: 100px;
  padding-right: 5px;
}

.agent-stat-gauges-help-body-text {
  font-size: 1em;
}

.agent-profile-name .agent-unavaiable {
  color: var(--c-fh1-condition-nok);
}

.agent-thumbnail {
  position: relative;
}

.flex-agents-portrait-label {
  position: absolute;
  top: 0;
  font-weight: 600;
  background: black;
  text-transform: uppercase;
  /*
  text-shadow: -1px -1px 0 #888, 1px -1px 0 #888, -1px 1px 0 #888, 1px 1px 0 #888;
  */
  padding: 1px 2px;
}

.flex-agents-portrait-label-assault { color: red; }
.flex-agents-portrait-label-tank { color: green; }
.flex-agents-portrait-label-support { color: blue; }

.flex-agents-portrait-label-pointman { color: #007BFF; } /* Pointman - 밝은 파란색 */
.flex-agents-portrait-label-breacher { color: #FF5733; } /* Breacher - 강렬한 빨간색 */
.flex-agents-portrait-label-medic { color: #4CAF50; } /* Medic - 평온한 녹색 */
.flex-agents-portrait-label-vanguard { color: #FFD700; } /* Vanguard - 밝은 황금색 */
.flex-agents-portrait-label-sharpshooter { color: #008080; } /* Sharpshooter - 진한 청록색 */
.flex-agents-portrait-label-scout { color: #FFFF00; } /* Scout - 밝은 노란색 */

.equip {
  max-width: 150px;
}

.equip > .item-label > span:last-child {
  max-width: 100px;
}
