/* tier badge */
.figma-badge2-tier {
  display: flex;
  width: 31px;
  height: 28px;
  padding: 2px 0px 1px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.figma-badge2-tier-label {
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-right: 6px;
}

.figma-badge2-tier-label-1 {
  color: #fff1a7;
}

.figma-badge2-tier-label-2 {
  color: #ecb86a;
}

.figma-badge2-tier-label-3 {
  color: #a4a7b0;
}

.figma-badge2-tier-label-4 {
  color: #a86637;
}

.figma-badge2-tier-label-5 {
  color: #666363;
}

.figma-badge2-tier-formal {
  width: 37px;
  height: 37px;
  flex-shrink: 0;
}

.figma-badge2-tier-formal-border {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.figma-badge2-tier-formal-border-1 {
  background: linear-gradient(180deg, #fff1a7 0%, #a89658 100%);
}

.figma-badge2-tier-formal-border-2 {
  background: linear-gradient(180deg, #ecb86a 0%, #a6742a 100%);
}

.figma-badge2-tier-formal-border-3 {
  background: linear-gradient(180deg, #b1bad6 0%, #585f74 100%);
}

.figma-badge2-tier-formal-border-4 {
  background: linear-gradient(180deg, #d86e32 0%, #8d211a 100%);
}

.figma-badge2-tier-formal-border-5 {
  background: linear-gradient(180deg, #a1a2a2 0%, #5f5f5f 100%);
}

.figma-badge2-tier-formal-background {
  width: 84%;
  height: 84%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.figma-badge2-tier-formal-background-1 {
  background: #6c2016;
}

.figma-badge2-tier-formal-background-2 {
  background: #422904;
}

.figma-badge2-tier-formal-background-3 {
  background: #282d3a;
}

.figma-badge2-tier-formal-background-4 {
  background: #291103;
}

.figma-badge2-tier-formal-background-5 {
  background: #27282c;
}

.figmalist-body2-list-row-item-label-power-number {
  display: inline-block;
}

.figmalist-body2-list-row-item-label-power-number-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 5px;

  width: 64px;
  height: 23px;

  background: #34b6c8;
  border-radius: 27px;
}

.figmalist-body2-list-row-item-label-power-icon {
  width: 20px;
  height: 20px;
}

.figmalist-body2-list-row-item-label-power-inner {
  display: flex;
  width: 40px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  margin-bottom: 1px;
}

.figmalist-body2-list-row-item-label-power-inner span {
  width: 33px;
  height: 23px;

  font-family: "Noto Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: normal;
  text-align: center;

  color: #083b41;
}

.figmalist-body2-power-star {
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  margin-right: -4px;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.figmalist-body2-power-star:last-child {
  margin-right: unset;
}

.figmalist-body2-power-star-fill-all {
  position: absolute;
  left: 1px;
  top: 2px;
  width: 21px;
  height: 21px;
}

.figmalist-body2-power-star-fill-base {
  position: absolute;
  left: 1px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.figmalist-body2-power-star-fill-bg-base {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.figmalist-body2-power-star-fill-alt {
  position: absolute;
  left: 1px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.figmalist-body2-power-star-fill-bg-alt {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

.figmalist-body2-power-star-fill-glow {
  position: absolute;
  left: 0px;
  top: 0px;
}

.figmalist-body2-power {
  display: flex;
  flex-direction: row;
  gap: 2px;
}
.figmalist-body2-power-stars {
  display: flex;
  align-items: flex-start;
}


