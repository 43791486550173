.stamp {
  font-size: 3rem;
  font-weight: 700;
  border: 0.25rem solid;
  display: inline-block;
  padding: 0.25rem 1rem;
  border-radius: 1rem;
  font-family: 'Courier';
  -webkit-mask-image: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/8399/grunge.png');
  -webkit-mask-size: 944px 604px;
  animation-name: stampani2;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}


@keyframes stampani2 {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  95% {
    transform: scale(3);
    apacity: 0;
  }

  100% {
    transform: scale(1) rotate(12deg);
    opacity: 1;
    visibility: visible;
  }
}

.grid-row {
  height: 6em;
  overflow-y: hidden;
  visibility: hidden;
  animation-name: grow;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  display: grid;
  grid-template-columns: 300px 150px;
}

.grid-report {
  display: grid;
  grid-template-columns: 450px 400px;
}

@keyframes grow {
  from {
    transform: translateY(3em);
    visibility: inherit;
  }

  to {
    transform: translateY(0);
    visibility: inherit;
  }
}