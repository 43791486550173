.agentreport-root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: inline-flex;
  height: 800px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  border: 3px solid #583c25;
  background: #583c25;
}

.agentreport-header {
  position: relative;
  display: flex;
  height: 54px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #3d291a;
}

.agentreport-header-btn-close {
  box-sizing: border-box;
  position: absolute;
  right: 12px;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 53px;
  height: 35px;

  background: #9e8662;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 66px;
}

.agentreport-header-btn-close:hover {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #c7af8b;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.agentreport-header-btn-close:active {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #7a6442;
}

.agentreport-header-titel {
  color: #dcba86;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-body {
  display: flex;
  width: 1274px;
  padding-top: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  align-self: stretch;

  flex: 1 0 0;

  background: #0c0602;
}

.agentreport-body-tabs {
  display: flex;
  height: 32px;
  padding: 0px 20px;
  align-items: flex-start;
  gap: 25px;
  align-self: stretch;
}

.agentreport-body-tab {
  display: flex;
  align-items: center;
  gap: 5px;
}

.agentreport-body-tab-title {
  display: flex;
  align-items: center;
}

.agentreport-body-tab-title-label {
  color: #dcba86;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-body-list {
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  background: #0e0808;
}

.agentreport-body-list-row {
  display: flex;
  padding: 7px 0px;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  border-radius: 6px;
  border: 3px solid #553923;
  background: #432c1a;
}

.agentreport-body-list-row-inner {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;
}

.agentreport-body-list-row-inner-agent {
  display: flex;
  width: 200px;
  align-items: flex-start;
  gap: 8px;
  overflow: hidden;
}

.agentreport-body-list-row-inner-agent-portraitframe {
  display: flex;
  width: 42px;
  height: 52px;
  position: relative;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.agentreport-body-list-row-inner-agent-portraitframe img {
  object-fit: cover;
}

.agentreport-body-list-row-inner-agent-inform {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
}

.agentreport-body-list-row-inner-agent-inform-upper {
  display: flex;
  padding-right: 1px;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.agentreport-body-list-row-inner-agent-inform-upper-role {
  display: flex;
  padding: 1px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.agentreport-body-list-row-inner-agent-inform-upper-role.pointman {
  background: #a451b9;
}

.agentreport-body-list-row-inner-agent-inform-upper-role.breacher {
  background: #cacdd8;
}

.agentreport-body-list-row-inner-agent-inform-upper-role.medic {
  background: #bcb258;
}

.agentreport-body-list-row-inner-agent-inform-upper-role.vanguard {
  background: #d6922b;
}

.agentreport-body-list-row-inner-agent-inform-upper-role.sharpshooter {
  background: #3fa3b9;
}

.agentreport-body-list-row-inner-agent-inform-upper-role.scout {
  background: #58b258;
}

.agentreport-body-list-row-inner-agent-inform-upper-role-label {
  color: #000;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.agentreport-body-list-row-inner-agent-inform-upper-callsign {
  display: flex;
  padding: 1px 5px 2px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1.5px solid #f2e4b5;
}

.agentreport-body-list-row-inner-agent-inform-upper-callsign-label {
  display: flex;
  width: 57px;
  flex-direction: column;
  justify-content: center;
  text-overflow: ellipsis;
  color: #f2e4b5;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  flex: 1 0 0;
}

.agentreport-body-list-row-inner-agent-inform-lower {
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.agentreport-body-list-row-inner-agent-inform-lower-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;
  overflow: hidden;
  color: #f2e4b5;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-body-list-row-inner-result {
  display: flex;
  width: 120px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.agentreport-body-list-row-inner-result-title {
  color: #b1805c;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-body-list-row-inner-result-value {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.agentreport-body-list-row-inner-result-value-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #8d6443;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
}

.agentreport-body-list-row-inner-result-value-label.great,
.agentreport-body-list-row-inner-result-value-label.good {
  color: #71c507;
}

.agentreport-body-list-row-inner-result-value-label.bad {
  color: #e92424;
}

.agentreport-body-list-row-inner-train {
  display: flex;
  width: 140px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.agentreport-body-list-row-inner-train-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.agentreport-body-list-row-inner-train-title-label {
  color: #b1805c;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-body-list-row-inner-train-value {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.agentreport-body-list-row-inner-train-value-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #ffe2aa;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
}

.agentreport-body-list-row-inner-train-value-diff {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.agentreport-body-list-row-inner-train-value-diff-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #619f14;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
}

.agentreport-body-list-row-inner-train-value-diff-label.higher {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #619f14;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
}

.agentreport-body-list-row-inner-train-value-diff-label.lower {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #d23a3a;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
}

.agentreport-body-list-row-inner-specials {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  flex: 1 0 0;
  align-self: stretch;
}

.agentreport-body-list-row-inner-specials-modifier {
  display: flex;
  padding: 3px 5px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: #261b1b;
}

.agentreport-body-list-row-inner-specials-modifier-title {
  color: #b1805c;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-body-list-row-inner-specials-modifier-value {
  color: #ffe2aa;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-body-list-row-inner-specials-modifier-value-none {
  color: #8d6443;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-body-list-row-inner-specials-perk {
  display: flex;
  padding: 3px 5px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  background: #261b1b;
}

.agentreport-body-list-row-inner-specials-perk-title {
  color: #b1805c;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-body-list-row-inner-specials-perk-bold {
  font-size: 15px;
}

.agentreport-body-list-row-inner-specials-perk-value {
  color: #ffe2aa;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-body-list-row-inner-specials-perk-value-none {
  color: #8d6443;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentreport-footer {
  display: flex;
  height: 70px;
  padding: 7px 24px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  align-self: stretch;
  background: #3d291a;
}

.agentreport-footer-check-btn {
  display: flex;
  width: 300px;
  padding: 8px 0px 7px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #dfa612;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.agentreport-footer-check-btn:hover {
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #ffd15b;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.agentreport-footer-check-btn:active {
  border-radius: 6px;
  border: 3px solid rgba(55, 55, 55, 0.2);
  background: #a27500;
}

.agentreport-footer-check-btn-label {
  color: #422f0b;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
