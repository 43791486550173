.contract-in-progress {
  height: 341px;
  position: relative;
  width: 323px;
}

.contract-in-progress .squad-info {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;
  flex-wrap: wrap;

  left: 0;
  position: absolute;
  top: 0;
  width: 323px;
}

.contract-in-progress .contract-info {
  height: 143px;
  left: 0;
  position: absolute;
  top: -145px;
  width: 322px;
}

.contract-in-progress .div {
  height: 143px;
  position: relative;
  width: 321px;
}

.contract-in-progress .img {
  height: 121px;
  left: 0;
  position: absolute;
  top: 22px;
  width: 321px;
}

.contract-in-progress .reward {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;
  left: 7px;
  position: absolute;
  top: 102px;
  width: 306px;
}

.contract-in-progress .reputation {
  align-items: center;
  background-color: #41c3a4;
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;

  height: 34px;
  justify-content: center;
  padding: 5px 14px;
  position: relative;
}

.contract-in-progress .icon {
  height: 24px;
  position: relative;
  width: 19px;
}

.contract-in-progress .pt-renown {
  color: #e8fff9;
  font-family: "Pretendard", Helvetica;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -0.8px;
  line-height: 20px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-in-progress .money {
  align-items: center;
  background-color: #de960c;
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;

  height: 34px;
  justify-content: center;
  margin-left: -1px;
  padding: 6px 14px;
  position: relative;
}

.contract-in-progress .icon-2 {
  height: 18px;
  position: relative;
  width: 30px;
}

.contract-in-progress .pt-money {
  color: #fff4bd;
  font-family: "Pretendard", Helvetica;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -0.8px;
  line-height: 20px;
  position: relative;
  text-align: /* PMC */ center; justify-content: center;
  white-space: nowrap;
  width: fit-content;
}

.contract-in-progress .map-enemy {
  align-items: center;
  display: flex;

  justify-content: center;
  left: 15px;
  position: absolute;
  top: 56px;
  width: 289px;
}

.contract-in-progress .map {
  align-items: center;
  display: flex;
  flex: 1 1 0;
  flex-grow: 1;

  position: relative;
}

.contract-in-progress .frame {
  height: 21px;
  margin-bottom: -2.5px;
  margin-left: -2px;
  margin-top: -2.5px;
  position: relative;
  width: 14px;
}

.contract-in-progress .pt-map-name {
  color: #583b18;
  font-family: "Pretendard", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-in-progress .vector {
  height: 37px;
  position: relative;
  width: 1px;
}

.contract-in-progress .frame-2 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;

  position: relative;
}

.contract-in-progress .overlap-group-wrapper {
  height: 32px;
  position: relative;
  width: 20px;
}

.contract-in-progress .pt-threat-level-wrapper {
  background-image: url(../../../static/anima/img/union-9.svg);
  background-size: 100% 100%;
  height: 35px;
  position: relative;
  width: 21px;
}

.contract-in-progress .pt-threat-level {
  color: #ffd0a4;
  font-family: "Pretendard", Helvetica;
  font-size: 15px;
  font-weight: 800;
  left: 6px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 16px;
  white-space: nowrap;
}

.contract-in-progress .frame-3 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;

  position: relative;
}

.contract-in-progress .x {
  color: #a70000;
  font-family: "Pretendard", Helvetica;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: -0.28px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-in-progress .pt-enemy-count {
  color: #a70000;
  font-family: "Pretendard", Helvetica;
  font-size: 20px;
  font-weight: 800;
  letter-spacing: -0.4px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-in-progress .customer {
  align-items: center;
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
  border-color: #6f4a12;
  display: inline-flex;

  justify-content: center;
  left: 12px;
  padding: 0px 0px 1px;
  position: absolute;
  top: 27px;
}

.contract-in-progress .pt-customer {
  color: #6f4a12;
  font-family: "Pretendard", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.5px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-in-progress .in-progress {
  background-image: url(../../../static/anima/img/bg-15.svg);
  background-size: 100% 100%;
  height: 47px;
  left: 0;
  position: absolute;
  top: 0;
  width: 321px;
}

.contract-in-progress .in-progress-due {
  align-items: center;
  display: inline-flex;

  left: 237px;
  position: absolute;
  top: 9px;
}

.contract-in-progress .pt-remain-number {
  color: #e9ffac;
  font-family: "Pretendard", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.contract-in-progress .pt-remain-unit {
  color: #e9ffac;
  font-family: "Pretendard", Helvetica;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-in-progress .in-progress-2 {
  color: #e9ffac;
  font-family: "Pretendard", Helvetica;
  font-size: 15px;
  font-weight: 600;
  left: 11px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 1px;
  white-space: nowrap;
}

.contract-in-progress .CIPO-cell-instance {
  position: relative !important;
}

.contract-in-progress .swap-dummy-3 {
  height: 64px !important;
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
  width: 59px !important;
}

.contract-in-progress .swap-dummy-4 {
  height: 60px !important;
  width: 55px !important;
}

.contract-in-progress .swap-dummy-5 {
  height: 55px !important;
  white-space: unset !important;
  width: 45px !important;
}
.contract-in-progress .customer>* { margin-left: 10px; } .contract-in-progress .customer>*:first-child { margin-left: 0; }
.contract-in-progress .frame-2>* { margin-left: 5px; } .contract-in-progress .frame-2>*:first-child { margin-left: 0; }
.contract-in-progress .frame-3>* { margin-left: 1px; } .contract-in-progress .frame-3>*:first-child { margin-left: 0; }
.contract-in-progress .in-progress-due>* { margin-left: 4px; } .contract-in-progress .in-progress-due>*:first-child { margin-left: 0; }
.contract-in-progress .map>* { margin-left: 7px; } .contract-in-progress .map>*:first-child { margin-left: 0; }
.contract-in-progress .map-enemy>* { margin-left: 19px; } .contract-in-progress .map-enemy>*:first-child { margin-left: 0; }
.contract-in-progress .money>* { margin-left: 6px; } .contract-in-progress .money>*:first-child { margin-left: 0; }
.contract-in-progress .reputation>* { margin-left: 3px; } .contract-in-progress .reputation>*:first-child { margin-left: 0; }
.contract-in-progress .squad-info>* { margin-right: 2px; margin-bottom: 2px; } .contract-in-progress .squad-info>*:last-child { margin-right: 0; margin-bottom: 0; }
