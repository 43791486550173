.swap-dummer {
  background-color: #0000ff;
  height: 30px;
  width: 30px;
}

.swap-dummer .overlap-group {
  border: 1px solid;
  border-color: #ffff00;
  height: 26px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 26px;
}

.swap-dummer .dummy {
  color: #ffe99d;
  font-family: "Freshman", Helvetica;
  font-size: 18px;
  font-weight: 400;
  height: 21px;
  left: 4px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
  text-align: /* PMC */ center; justify-content: center;
  top: 2px;
  white-space: nowrap;
  width: 16px;
}
