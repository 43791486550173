.perk-tree-column {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 204px;
}

.perk-tree-column .perk-slot-instance {
  position: relative !important;
}
