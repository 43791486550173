html {
  --c-gr1-bg: #241b1b;

  --c-gr1-transpbox-fg: #c4a87d;
  --c-gr1-transpbox-fg-important: #f2e4b5;
  --c-gr1-transpbox-fg-desc: #908173;
  --c-gr1-transpbox-fg-desc-2: #827767;
  --c-gr1-transpbox-fg-positive: #60a45a;
  --c-gr1-transpbox-fg-negative: #cc624a;

  --c-gr1-transpbox-header-bg: #46372f;
  --c-gr1-transpbox-subheader-bg: #322924;
  --c-gr1-transpbox-subarea-bg: rgba(0, 0, 0, 0.2);

  --c-gr1-agent-fg: #d3c6b2;

  --c-gr1-lobby-clock-bg: #1b0b0b;
  --c-gr1-lobby-clock-fg: #e9e9e9;
  --c-gr1-lobby-clock-border: #422f2f;

  --c-gr1-lobby-calendar-ruler-border: #413b35;
  --c-gr1-lobby-calendar-fg-weekday: #6f6456;
  --c-gr1-lobby-calendar-fg-weekend: #8d2c26;

  --c-gr1-lobby-item-border: #5e4f4f;

  --c-gr1-button-bg: #d0bd8b;
  --c-gr1-button-bg-important: #dfa612;
  --c-gr1-button-fg: #422f0b;

  --c-gr1-img-bg: #fff;

  --c-gr1-icon-bg: #20393b;
}

.icon-date-check {
  position: absolute;
  z-index: 2;
}

.outgame-lobby {
  background: url("/public/img/lobby/PMM_Main.png");
  background-size: cover;
  background-position: center;
  color: var(--c-gr1-transpbox-fg);
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  font-family: "Noto Sans";
  user-select: none;
}

.outgame-lobby h1 {
  font-size: 1.5em;
  font-weight: 700;
  margin: 0;
  padding: 0;

  text-transform: uppercase;
}

.outgame-lobby th {
  background-color: inherit;
}

.lobby-header {
  position: fixed;
  top: 10px;
  left: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
}

.lobby-clock {
  width: 274px;
  height: 42px;
  border: 3px solid #422f2f;
  background: #1b0b0b;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lobby-clock-label {
  position: absolute;
  z-index: 0;
  color: #e9e9e9;
  font-family: "Roboto Mono";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}

.lobby-clock-line {
  position: absolute;
  top: 18.5px;
  z-index: 1;
  width: 263px;
  height: 0px;
  border: 1.5px solid black;
  opacity: 0.6;
}

.lobby-calendar2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 9px;
}

.lobby-calendar {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: auto;
}

.lobby-calendar2-inner {
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
}

.lobby-calendar2-row {
  display: flex;
  align-items: flex-start;
  gap: 1px;
}

.lobby-calendar2-dateslot {
  display: flex;
  width: 38px;
  height: 38px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.lobby-calendar2-dateslot-label {
  position: absolute;
  z-index: 0;
  color: #999;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.lobby-calendarlegend {
  display: flex;
  padding: 0px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.lobby-calendarlegend-line {
  display: flex;
  align-items: center;
  gap: 5px;
}

.lobby-calendarlegend-line-rect {
  width: 12px;
  height: 12px;
}

.lobby-calendarlegend-line-label {
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.lobby-calendar-ruler {
  display: flex;
  width: 870px;
  height: 30px;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;

  border-right: 2px solid var(--c-gr1-lobby-calendar-ruler-border);
  border-bottom: 2px solid var(--c-gr1-lobby-calendar-ruler-border);
  border-left: 2px solid var(--c-gr1-lobby-calendar-ruler-border);
}

.lobby-calendar-date {
  width: 60px;

  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  display: flex;
  align-items: center;
  justify-content: center;
}

.lobby-calendar-date.weekend {
  color: var(--c-gr1-lobby-calendar-fg-weekend);
}

.lobby-calendar-date.weekday {
  color: var(--c-gr1-lobby-calendar-fg-weekday);
}

.lobby-missionObjectives {
  display: flex;
  padding: 0px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.lobby-status {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 14px;
}

.lobby-status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 277px;
  gap: 10px;
}

.lobby-status-item-cash {
  position: fixed;
  top: 26px;
  right: 28px;
  width: 290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.lobby-status-item-cash-header {
  display: flex;
  height: 34px;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #744c00;
}

.lobby-status-item-cash-header-label {
  flex: 1 0 0;
  color: #ffb800;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby-status-item-cash-header-value {
  color: #ffcf52;
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.lobby-status-item-cash-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.lobby-status-item-cash-label {
  flex: 1 0 0;
  color: #dab988;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby-status-item-cash-label-desc {
  flex: 1 0 0;
  color: #a98744;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.lobby-status-item-cash-value-desc.positive {
  color: #71c507;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby-status-item-cash-value-desc.negative {
  color: #e92424;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby-status-item-cash-value {
  color: #ffe2aa;
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.lobby-status-item-cash-value.positive {
  color: #71c507;
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.lobby-status-item-cash-value.negative {
  color: #e92424;
  text-align: right;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.lobby-status-item-condition {
  position: fixed;
  bottom: 93px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 277px;
  gap: 10px;
}

.lobby-status-item-header {
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-self: stretch;

  width: 277px;
}

.lobby-status-item-detail {
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  width: 277px;
}

.lobby-status-item-agent {
  display: flex;
  width: 277px;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
}

.lobby-status-item-agent-img {
  display: flex;
  width: 43px;
  height: 43px;
  padding: 2px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.lobby-status-item-agent-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  flex: 1 0 0;
}

.lobby-status-item-agent-name-lower {
  display: flex;
  height: 11px;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  overflow: hidden;
  color: #d3c6b2;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby-status-item-agent-button {
  width: 68px;
  height: 26px;
  display: flex;
  padding: 3px 0px;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);

  background-color: var(--c-gr1-button-bg-important);
}

.lobby-status-item-agent-button-label {
  color: var(--c-gr1-button-fg);
  height: 100%;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.lobby-status-item-header {
  background-color: var(--c-gr1-transpbox-header-bg);
}

.lobby-status-item-label {
  color: var(--c-gr1-transpbox-fg);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
}

.lobby-status-item-label.desc {
  color: var(--c-gr1-transpbox-fg-desc);
  font-weight: 500;
}

.lobby-status-item-value {
  display: inline-flex;
  justify-content: flex-end;
  color: var(--c-gr1-transpbox-fg-important);
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby-status-item-value.positive {
  color: var(--c-gr1-transpbox-fg-positive);
}

.lobby-status-item-value.negative {
  color: var(--c-gr1-transpbox-fg-negative);
}

.lobby-status-item-body {
  display: flex;
  flex-direction: column;
  background-color: var(--c-gr1-transpbox-subarea-bg);
  gap: 5px;
}

.lobby-status-item-cash-body>hr {
  size: 1px;
  align-self: stretch;
  border: 1px solid #5c422f;
}

.lobby-status-item-cash-detail {
  padding: 0px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.lobby-status-item-subitem {
  background-color: var(--c-gr1-transpbox-subarea-bg);
}

.lobby-status-item-subheader {
  display: flex;
  flex-direction: row;
  padding: 3px 10px;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
  background-color: var(--c-gr1-transpbox-subheader-bg);
}

.lobby-status-item-subbody {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: center;
  gap: 7px;
}

.lobby-status-menu {
  margin-right: 16px;
}

.lobby-left-drawer {
  position: fixed;
  top: 81px;
  left: 16px;

  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.lobby-left-drawer-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding-left: 10px;
  border-left: 4px solid var(--c-gr1-lobby-item-border);
}

.lobby-left-drawer-item-type {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.lobby-left-drawer-item-label {
  color: var(--c-gr1-transpbox-fg);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby-left-drawer-item-value {
  color: var(--c-gr1-transpbox-fg);
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  padding: 0px 5px 0px 15px;
  gap: 4px;
  align-items: center;
  white-space: pre;
}

.lobby-left-drawer-item-value.highlight {
  color: var(--c-gr1-transpbox-fg-important);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.04px;
  padding: 0px;
}

.lobby-squad-drawer {
  position: fixed;
  top: 80px;
  right: 28px;
  display: flex;
  width: 220px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.lobby-squad-drawer-header {
  display: flex;
  padding: 4px 10px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background-color: var(--c-gr1-transpbox-header-bg);
}

.lobby-squad-drawer-header-label {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby-squad-drawer-header-value {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.72px;
  text-decoration-line: underline;
}

.lobby-squad-drawer-body {
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;
  align-self: stretch;
}

.lobby-squad-drawer-item {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.lobby-squad-drawer-item-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.lobby-squad-drawer-item-img {
  display: flex;
  width: 82px;
  height: 82px;
  align-items: flex-start;
  gap: 10px;

  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.lobby-squad-drawer-item-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;

  height: 24px;
  width: 72px;
}

.lobby-squad-drawer-item-icon-weapon {
  display: flex;
  align-items: center;
  gap: 12px;
}

.lobby-squad-drawer-item-icon-weapon>.text-icon {
  padding: 6px 4px 6px 20px;

  color: #f7af23;

  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  /* 12px */
  letter-spacing: 0.6px;
  text-transform: capitalize;
  margin-left: -22px;
}

.lobby-squad-drawer-item-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-radius: 4px;
  background: var(--c-gr1-icon-bg);
}

.lobby-squad-drawer-item-icon-number {
  display: flex;
  text-align: center;
  font-family: "Lexend Zetta";
  font-size: 12px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: capitalize;
}

.lobby-squad-drawer-item-icon-number>svg {
  z-index: 1;
}

.lobby-squad-drawer-item-icon-number>span {
  color: #b9b6b1;
  z-index: 2;
  margin-left: -15px;
  margin-top: 4px;
}

.lobby-squad-drawer-item-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1px;
  flex: 1 0 0;
  overflow: hidden;
}

.lobby-squad-drawer-item-row {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
}

.lobby-squad-drawer-item-callsign {
  overflow: hidden;
  color: var(--c-gr1-agent-fg);
  text-overflow: ellipsis;
  white-space: nowrap;

  text-align: center;
  font-family: "Roboto Mono";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  height: 20px;

  padding: 0px 5px;

  align-items: center;
  gap: 10px;
  border: 1px solid #d3c6b2;
}

.lobby-left-footer {
  position: fixed;
  bottom: 17px;
  left: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}

.lobby-left-footer-button {
  display: flex;
  width: 320px;
  padding: 4px 8px;
  align-items: center;

  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: var(--c-gr1-button-bg);
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);

  color: var(--c-gr1-button-fg);
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.88px;
}

.lobby-left-footer-button:hover {
  border-radius: 6px;
  background: #f8ebc8;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.lobby-left-footer-button:active {
  border-radius: 6px;
  border: 3px solid rgba(55, 55, 55, 0.2);
  background: #857751;
}

@keyframes colorShift {

  0%,
  100% {
    background-color: #857751;
  }

  70% {
    background-color: magenta;
  }
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }

  70% {
    transform: scale(1.02);
    box-shadow: 0 0 10px 10px rgba(0, 123, 255, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

.lobby-left-footer-button.highlighted {
  border-radius: 6px;
  border: 3px solid rgba(55, 55, 55, 0.2);
  animation: colorShift 2s ease-in-out infinite, pulse-animation 2s infinite;
}

.lobby-right-footer {
  position: fixed;
  bottom: 17px;
  right: 20px;

  display: flex;
  flex-direction: column;
}

.lobby-right-footer-upper {
  margin-left: auto;
  margin-right: 0px;
}

.lobby-right-footer-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.lobby-right-footer-button {
  display: flex;
  width: 360px;
  padding: 13px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);

  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby-right-footer-button.enabled {
  background-color: var(--c-gr1-button-bg);
  color: var(--c-gr1-button-fg);
}

.lobby-right-footer-button.enabled.primary {
  background-color: var(--c-gr1-button-bg-important);
  color: var(--c-gr1-button-fg);
}

.lobby-right-footer-button.enabled.primary.highlighted {
  animation: colorShift 2s ease-in-out infinite, pulse-animation 2s infinite;
}

.lobby-right-footer-button.enabled.primary:hover {
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #ffd15b;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.lobby-right-footer-button.enabled.primary:active {
  border-radius: 6px;
  border: 3px solid rgba(55, 55, 55, 0.2);
  background: #a27500;
}

.lobby-right-footer-button.disabled,
.lobby-right-footer-button.disabled.primary {
  background-color: var(--c-gr1-button-bg-important);
  color: var(--c-gr1-button-fg);
  opacity: 0.3;
  text-align: center;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.12px;
}

.lobby-right-footer-button.primary::before {
  content: "▶";
}

.lobby-right-footer-button.event::before {
  content: "⚠";
}

.lobby-right-footer-button.event.event {
  background-color: silver;
}

.lobby-right-footer-button.event.event:hover {
  background-color: white;
}

.lobby-right-footer-button.primary.final {
  background-color: red;
}

.lobby-right-footer-button>p.desc {
  font-size: 18px;
  font-weight: 500;
}

.lobby-right-footer button:has(+ .figma-tooltip)::after {
  font-size: 22px;
  padding: 0px 8px;
}

.lobby-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;

  flex-shrink: 1;
  align-self: center;
}

.lobby-center>hr {
  width: 100%;
  border: 1px solid #888;
  margin: 4px;
}

.lobby2-light {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: none;
}

.lobby2-light-overlay,
.lobby2-light-multiply {
  opacity: 0.5;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: none;
  height: 120%;
  pointer-events: none;
  user-select: none;
  will-change: transform;
  backface-visibility: hidden;
}


@keyframes rotate-smooth {
  0%, 100% {
    top: -7%;
    left: 7%;

    transform: translate(0%, -9%) scale(1.1) rotate(0deg);
    animation-timing-function: ease-in-out;
  }


  50% {
    top: -7%;
    left: 7%;

    transform: translate(0%, -9%) scale(1.1) rotate(-0.3deg);
    animation-timing-function: ease-in-out;
  }

}

.lobby2-light-overlay,
.lobby2-light-multiply {
  transform-origin: top right;
  animation: rotate-smooth 1s infinite;
}

.lobby2-light-multiply {
  mix-blend-mode: multiply;
}

.lobby2-light-overlay {
  mix-blend-mode: overlay;
}


.lobby2-header {
  position: absolute;
  top: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.lobby2-header-primary {
  height: 40px;
  align-self: stretch;
  background: #210b0b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.lobby2-header-primary-left {
  display: flex;
  width: 300px;
  align-items: center;
  gap: 15px;
}

.lobby2-header-primary-daytime,
.lobby2-header-primary-ddMMyyyy,
.lobby2-header-primary-item-values {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.lobby2-header-primary-left span,
.lobby2-header-primary-schedule span,
.lobby2-header-primary-timelimit span {
  color: #ffd28e;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.lobby-header-primary-button-menu:hover,
.lobby-header-primary-button-menu:active {
  cursor: pointer;
}

.lobby-header-primary-button-menu:hover path {
  fill: #ece8df;
}

.lobby-header-primary-button-menu:active path {
  fill: #d0c9bb;
}

.lobby2-header-primary-center {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.lobby2-header-primary-mission-title {
  color: #ffd28e;
  text-align: right;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}

.lobby2-header-primary-schedule,
.lobby2-header-primary-timelimit {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.lobby2-header-primary-schedule span.lobby2-header-primary-item-value,
.lobby2-header-primary-timelimit span.lobby2-header-primary-item-value {
  color: #fefbde;
  font-size: 22px;
}

.lobby2-header-primary-center-divider {
  width: 0px;
  height: 21px;
  border: 1px solid #ffd28e;
}

.lobby2-header-primary-right {
  display: flex;
  width: 300px;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.lobby2-header-primary-resource {
  display: flex;
  padding-left: 4px;
  justify-content: flex-end;
  align-items: center;
}

.lobby2-header-primary-resource span {
  color: #ffd28e;
  text-align: right;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby2-header-primary-button-box {
  display: flex;
  padding-bottom: 2px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.5);
}

.lobby2-header-primary-button-detail {
  display: flex;
  padding: 1px 10px 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 3px;
  border: 1.5px solid #e5ac57;
  background: #d59536;
  box-shadow: 0px 2px 0px 0px #8a580f;
}

.lobby2-header-primary-button-detail span {
  color: #431111;
  font-family: "Noto Sans";
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.lobby2-header-primary-button-detail:hover {
  background: #f3b861;
  box-shadow: 0px 2px 0px 0px #bc802a;
}

.lobby2-header-primary-button-detail:hover span {
  color: #7c4a0f;
}

.lobby2-header-primary-button-box:active {
  padding: 1px 0px;
}

.lobby2-header-primary-button-detail:active {
  border: 1.5px solid #b87b20;
  background: #b1751d;
  box-shadow: 0px 1px 0px 0px #8a580f;
}

.lobby2-header-primary-button-detail:active span {
  color: #431111;
}

.lobby2-header-primary-button-detail:disabled {
  border-radius: 3px;
  border: 1.5px solid #5e504a;
  background: #4e433d;
  box-shadow: 0px 2px 0px 0px #3e332d;
}

.lobby2-header-primary-button-detail:disabled span {
  color: #7a6a63;
}

.lobby2-header-divider {
  width: 100%;
  height: 3px;
  background: #3f2222;
}

.lobby2-header-secondary {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;

  background: rgba(15, 3, 1, 0.7);
}

.lobby2-header-secondary-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.lobby2-header-secondary-item-desc {
  color: #9e8471;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.lobby2-header-secondary-item-value {
  display: flex;
  align-items: center;
  gap: 7px;
}

.lobby2-header-secondary-item-value-desc {
  color: #bfaa8a;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby2-header-secondary-item-value-badge {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;

  border-radius: 34px;
  background: #bfaa8a;
}

.lobby2-header-secondary-item-value-value {
  color: #000;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby2-header-secondary-item-value.negative .lobby2-header-secondary-item-value-desc {
  color: #d12121;
}

.lobby2-header-secondary-item-value.negative .lobby2-header-secondary-item-value-badge {
  background: #d12121;
}

.lobby2-header-secondary-item-value.positive .lobby2-header-secondary-item-value-desc {
  color: #389e5a;
}

.lobby2-header-secondary-item-value.positive .lobby2-header-secondary-item-value-badge {
  background: #389e5a;
}

.lobby2-header-secondary-divider {
  width: 0px;
  height: 21px;

  border: 1px solid #9e8471;
}

.lobby2-side-menu {
  position: absolute;
  left: 9px;
  top: 130px;

  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.lobby2-clipboard {
  position: absolute;
  width: 1413px;
  height: 985px;
  left: 271px;
  top: 95px;

  background: url("/public/img/lobby/UI_Board.png");
}

.lobby2-clipboard-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 26px;
  width: 1237px;
  margin-top: 108px;
  margin-left: 71px;

  position: relative;
}

.lobby2-clipboard-section {
  width: 100%;
  position: relative;
}

.lobby2-clipboard-section.summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.lobby2-clipboard-section-header {
  display: flex;
  width: 100%;
  height: 34px;
  padding: 0px 10px 0px 20px;
  align-items: center;
  gap: 10px;

  background: rgba(195, 188, 153, 0.7);
}

.lobby2-clipboard-section-header>span {
  color: #4a4632;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.lobby2-clipboard-section-header-box {
  display: flex;
  padding: 0px 17px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  background: #72604b;
  clip-path: polygon(7px 0px, 100% 0px, calc(100% - 7px) 100%, 0px 100%);
}

.lobby2-clipboard-section-header-box>span {
  color: #d7c197;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.lobby2-clipboard-section-header-box.result {
  background: black;
}

.lobby2-clipboard-section-header-box.pass>span {
  color: green;
}

.lobby2-clipboard-section-header-box.fail>span {
  color: red;
}

.lobby2-clipboard-section-body {
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.lobby2-clipboard-section-item-name {
  display: flex;
  align-items: center;
  gap: 16px;
}

.lobby2-clipboard-section-item-desc {
  display: inline-flex;
  align-items: center;
  gap: 7px;

  vertical-align: top;
  position: relative;
}

.lobby2-clipboard-section-item-desc-bullet {
  width: 12px;
  height: 12px;

  background: #817e61;
}

.lobby2-clipboard-section-item-desc-label {
  color: #817e61;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.36px;
  text-transform: uppercase;
}

.lobby2-clipboard-section-item-value-name {
  color: #463c34;
  -webkit-text-stroke-width: 1.2000000476837158pt;
  -webkit-text-stroke-color: #463c34;
  font-family: Caveat, "나눔손글씨";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.lobby2-clipboard-section-item-subsection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.lobby2-clipboard-section-item-objective {
  display: flex;
  align-items: center;
  gap: 13px;
}

.lobby2-clipboard-section-item-value-objective {
  color: #463c34;
  text-align: center;
  font-family: Caveat, "나눔손글씨";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby2-clipboard-section-details {
  display: flex;
  height: 115px;
  padding-left: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.lobby2-clipboard-section-detail-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.lobby2-clipboard-section-detail-item-desc-bullet {
  width: 10px;
  height: 3px;

  background: #817e61;
}

.lobby2-clipboard-section-detail-item-desc-label {
  color: #817e61;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.34px;
  text-transform: uppercase;
}

.lobby2-clipboard-section-item-value {
  color: #463c34;
  text-align: center;
  font-family: Caveat, "나눔손글씨";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby2-clipboard-stamp-approved {
  position: absolute;
  top: 119px;
  right: 37.88px;
}

.lobby2-clipboard-section-border {
  border-top: 2px solid #a8ab8d;
  border-bottom: 2px solid #a8ab8d;

  background: rgba(242, 233, 210, 0);

  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 10px;

  width: 100%;
}

.lobby2-clipboard-section-root {
  display: flex;
}

.lobby2-clipboard-section-divider {
  border: 2px solid #5d5148;
  margin: 0px 5px 0px 5px;
}

.lobby2-clipboard-section-quest-item {
  padding: 4px;
  align-items: center;
  gap: 5px;
  border-radius: 62px;
}

.lobby2-clipboard-section-quest-item-title {
  display: flex;
}

.lobby2-clipboard-section-quest-item-title>span {
  color: #4a4632;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.lobby2-clipboard-section-quest-item-body {
  display: flex;
  padding-right: 20px;
  align-items: center;
  gap: 5px;
}

.lobby2-clipboard-section-quest-item-label {
  color: #463c34;
  font-family: Caveat, "나눔손글씨";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby2-clipboard-section-quest-item-body-header {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lobby2-clipboard-section-quest-item-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #817e61;
}

.lobby2-clipboard-section-quest-item-value {
  color: #463c34;
  font-family: Caveat, "나눔손글씨";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby2-clipboard-section-desc {
  color: #5d5148;
  /* font-family: Caveat, "나눔손글씨"; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  white-space: pre;
  text-wrap: wrap;
  overflow-y: auto;
}

.lobby2-clipboard-section-desc::-webkit-scrollbar {
  width: 12px;
}

.lobby2-clipboard-section-desc::-webkit-scrollbar-thumb {
  width: 4px;
  background-clip: content-box;
  border: 4px solid transparent;
  border-radius: 12px;
}

.lobby2-clipboard-section-desc::-webkit-scrollbar-track {
  background: transparent;
}

.lobby2-clipboard-section.goals {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.lobby2-clipboard-section.goal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.lobby2-clipboard-section-goal-list {
  display: flex;
  padding-left: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
}

.lobby2-clipboard-section-goal-item {
  display: flex;
  padding: 4px;
  align-items: center;
  gap: 5px;
  border-radius: 62px;
}

.lobby2-clipboard-section-goal-item-header {
  width: 38px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lobby2-clipboard-section-goal-item-bullet {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #817e61;
}

.lobby2-clipboard-section-goal-item-body {
  display: flex;
  padding-right: 20px;
  align-items: center;
  gap: 5px;
}

.lobby2-clipboard-section-goal-item-label {
  color: #463c34;
  font-family: Caveat, "나눔손글씨";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby2-clipboard-section-goal-item-value {
  color: #463c34;
  font-family: Caveat, "나눔손글씨";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.lobby2-clipboard-section-goal-item-value-cur {
  color: #af2b23;
}

.lobby2-clipboard-section-goal-item.pass {
  background: #4c7a59;
}

.pass .lobby2-clipboard-section-goal-item-label {
  color: #eaffde;
}

.pass .lobby2-clipboard-section-goal-item-value,
.pass .lobby2-clipboard-section-goal-item-value-cur {
  color: #a7e482;
}

.lobby2-footer {
  position: absolute;
  bottom: 14px;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.lobby2-page-button {
  width: 541px;
  height: 59px;
  flex-shrink: 0;

  position: relative;

  border-radius: 7px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.lobby2-page-button:hover,
.lobby2-page-button:active {
  cursor: pointer;
}

.lobby2-page-button-emboss {
  width: 541px;
  height: 56px;
  flex-shrink: 0;

  border-radius: 7px;
  background: #581d15;

  position: absolute;
  top: 3px;
}

.lobby2-page-button-background {
  width: 541px;
  height: 56px;
  flex-shrink: 0;

  border-radius: 7px;
  border: 1.4px solid #923c30;
  background: #6f241a;

  position: absolute;
  top: 0px;
}

.lobby2-page-button-outline {
  width: 535px;
  height: 50px;
  flex-shrink: 0;

  border-radius: 4px;
  border: 2px solid #3a0606;

  position: absolute;
  top: 3px;
  left: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.lobby2-page-button-label {
  color: #ffbd75;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  position: relative;

  display: flex;
  align-items: center;
  gap: 5px;
}

.lobby2-page-button:hover .lobby2-page-button-emboss {
  background: #872619;
}

.lobby2-page-button:hover .lobby2-page-button-background {
  border: 1.4px solid #923c30;
  background: #9b382b;
}

.lobby2-page-button:hover .lobby2-page-button-outline {
  border: 2px solid #85291c;
}

.lobby2-page-button:hover .lobby2-page-button-label {
  color: #ffd2a1;
}

.lobby2-page-button:active .lobby2-page-button-emboss {
  background: #581d15;
}

.lobby2-page-button:active .lobby2-page-button-background {
  border: 1.4px solid #782b21;
  background: #551b13;

  top: 2px;
}

.lobby2-page-button:active .lobby2-page-button-outline {
  border: 2px solid #3a0606;

  top: 5px;
}

.lobby2-page-button:active .lobby2-page-button-label {
  color: #ffd2a1;
}

.lobby2-page-button.disabled .lobby2-page-button-emboss {
  background: #424242 !important;
}

.lobby2-page-button.disabled .lobby2-page-button-background {
  border: 1.4px solid #555 !important;
  background: #474747 !important;

  top: 0px !important;
}

.lobby2-page-button.disabled .lobby2-page-button-outline {
  border: 2px solid #333 !important;

  top: 3px !important;
}

.lobby2-page-button.disabled .lobby2-page-button-label {
  color: #777 !important;
}

.lobby2-page-button.secondary .lobby2-page-button-emboss {
  background: #432b1d;
}

.lobby2-page-button.secondary .lobby2-page-button-background {
  border: 1.4px solid #724d38;
  background: #513626;
}

.lobby2-page-button.secondary .lobby2-page-button-outline {
  border: 2px solid #2d180c;
}

.lobby2-page-button.secondary .lobby2-page-button-label {
  color: #ae906e;
}

.lobby2-page-button.secondary:hover .lobby2-page-button-emboss {
  background: #744d38;
}

.lobby2-page-button.secondary:hover .lobby2-page-button-background {
  border: 1.4px solid #9d735c;
  background: #85614d;
}

.lobby2-page-button.secondary:hover .lobby2-page-button-outline {
  border: 2px solid #6f4c39;
}

.lobby2-page-button.secondary:hover .lobby2-page-button-label {
  color: #dec3a4;
}

.lobby2-page-button.secondary:active .lobby2-page-button-emboss {
  background: #432b1d;
}

.lobby2-page-button.secondary:active .lobby2-page-button-background {
  border: 1.4px solid #634736;
  background: #3c281c;

  top: 2px;
}

.lobby2-page-button.secondary:active .lobby2-page-button-outline {
  border: 2px solid #2d180c;

  top: 5px;
}

.lobby2-page-button.secondary:active .lobby2-page-button-label {
  color: #ae906e;
}

.lobby2-page-button-wide {
  width: 852px;
  /* 원하는 너비로 설정 */
}

.lobby2-page-button-wide .lobby2-page-button-emboss,
.lobby2-page-button-wide .lobby2-page-button-background {
  width: 852px;
  /* 원하는 너비로 설정 */
}

.lobby2-page-button-wide .lobby2-page-button-outline {
  width: 846px;
}

.lobby2-side-menu-item {
  position: relative;
  width: 70px;
  height: 70px;
}

.lobby2-side-menu-item:hover,
.lobby2-side-menu-item:active {
  cursor: pointer;
}

.lobby2-side-menu-item.timer:hover,
.lobby2-side-menu-item.timer:active {
  cursor: default;
}

.lobby2-side-menu-item-icon {
  height: 86px;
  width: 86px;
  left: -8px;
  top: -8px;

  position: relative;
}

.lobby2-side-menu-item-icon.current {
  height: 92px !important;
  width: 92px !important;
  top: -11px !important;
  left: -11px !important;
}

.lobby2-side-menu-item-icon.timer {
  width: 78px;
  height: 88px;
  left: -4px;
}

.lobby2-side-menu-item-icon.timer0 {
  background-image: url("/public/img/lobby/UI_Timer_0.png");
  background-size: 78px 88px;
}

.lobby2-side-menu-item-icon.timer1 {
  background-image: url("/public/img/lobby/UI_Timer_1.png");
}

.lobby2-side-menu-item-icon.timer2 {
  background-image: url("/public/img/lobby/UI_Timer_2.png");
}

.lobby2-side-menu-item-icon.timer3 {
  background-image: url("/public/img/lobby/UI_Timer_3.png");
}

.lobby2-side-menu-item-icon.timer4 {
  background-image: url("/public/img/lobby/UI_Timer_4.png");
}

.lobby2-side-menu-item-icon.timer5 {
  background-image: url("/public/img/lobby/UI_Timer_5.png");
}

.lobby2-side-menu-item-icon.timer6 {
  background-image: url("/public/img/lobby/UI_Timer_6.png");
}

.lobby2-side-menu-item-icon.timer7 {
  background-image: url("/public/img/lobby/UI_Timer_7.png");
}

.lobby2-side-menu-item-icon.squad {
  background-image: url("/public/img/lobby/UI_Squad_0.svg");
}

.lobby2-side-menu-item-icon.mission {
  background-image: url("/public/img/lobby/UI_Mission_0.svg");
}

.lobby2-side-menu-item-icon.recruits {
  background-image: url("/public/img/lobby/UI_Recruits_0.svg");
}

.lobby2-side-menu-item-icon.agents {
  background-image: url("/public/img/lobby/UI_Agents_0.svg");
}

.lobby2-side-menu-item-icon.shop {
  background-image: url("/public/img/lobby/UI_Shop_0.svg");
}

.lobby2-side-menu-item-icon.inventory {
  background-image: url("/public/img/lobby/UI_Inventory_0.svg");
}

.lobby2-side-menu-item-icon.call_superior {
  background-image: url("/public/img/lobby/UI_Call_Superior_0.svg");
}

.lobby2-side-menu-item-icon.mission_objective {
  background-image: url("/public/img/lobby/UI_Mission_Objective_0.svg");
}

.lobby2-side-menu-item-icon.mission_final {
  background-image: url("/public/img/lobby/UI_Mission_Final_0.svg");
}

.lobby2-side-menu-item-label {
  display: none;
  position: absolute;
  top: 12px;
  left: 53px;

  width: 228px;
  height: 43px;
  flex-shrink: 0;
}

.lobby2-side-menu-item.timer .lobby2-side-menu-item-label {
  top: 14px;
  height: 61px;
}

.lobby2-side-menu:hover .lobby2-side-menu-item-label {
  display: flex;
}

.lobby2-side-menu-item-label-bg {
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0px;
  left: 0px;

  background: linear-gradient(90deg,
      #170d09 59.21%,
      rgba(45, 41, 41, 0.09) 100%);
  filter: blur(5px);
}

.lobby2-side-menu-item-label-content {
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 25px;

  color: #ddbd8d;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: pre;
}

.lobby2-side-menu-divider {
  margin-top: 8px;
}

.lobby2-side-menu-item:hover .lobby2-side-menu-item-icon.squad {
  background-image: url("/public/img/lobby/UI_Squad_1.svg");
}

.lobby2-side-menu-item:active .lobby2-side-menu-item-icon.squad {
  background-image: url("/public/img/lobby/UI_Squad_2.svg");
}

.lobby2-side-menu-item-icon.squad.current {
  background-image: url("/public/img/lobby/UI_Squad_3.svg") !important;
}

.lobby2-side-menu-item.disabled .lobby2-side-menu-item-icon.squad {
  background-image: url("/public/img/lobby/UI_Squad_4.svg") !important;
}

.lobby2-side-menu-item:hover .lobby2-side-menu-item-icon.mission {
  background-image: url("/public/img/lobby/UI_Mission_1.svg");
}

.lobby2-side-menu-item:active .lobby2-side-menu-item-icon.mission {
  background-image: url("/public/img/lobby/UI_Mission_2.svg");
}

.lobby2-side-menu-item-icon.mission.current {
  background-image: url("/public/img/lobby/UI_Mission_3.svg") !important;
}

.lobby2-side-menu-item.disabled .lobby2-side-menu-item-icon.mission {
  background-image: url("/public/img/lobby/UI_Mission_4.svg") !important;
}

.lobby2-side-menu-item:hover .lobby2-side-menu-item-icon.recruits {
  background-image: url("/public/img/lobby/UI_Recruits_1.svg");
}

.lobby2-side-menu-item:active .lobby2-side-menu-item-icon.recruits {
  background-image: url("/public/img/lobby/UI_Recruits_2.svg");
}

.lobby2-side-menu-item-icon.recruits.current {
  background-image: url("/public/img/lobby/UI_Recruits_3.svg") !important;
}

.lobby2-side-menu-item.disabled .lobby2-side-menu-item-icon.recruits {
  background-image: url("/public/img/lobby/UI_Recruits_4.svg") !important;
}

.lobby2-side-menu-item:hover .lobby2-side-menu-item-icon.agents {
  background-image: url("/public/img/lobby/UI_Agents_1.svg");
}

.lobby2-side-menu-item:active .lobby2-side-menu-item-icon.agents {
  background-image: url("/public/img/lobby/UI_Agents_2.svg");
}

.lobby2-side-menu-item-icon.agents.current {
  background-image: url("/public/img/lobby/UI_Agents_3.svg") !important;
}

.lobby2-side-menu-item.disabled .lobby2-side-menu-item-icon.agents {
  background-image: url("/public/img/lobby/UI_Agents_4.svg") !important;
}

.lobby2-side-menu-item:hover .lobby2-side-menu-item-icon.shop {
  background-image: url("/public/img/lobby/UI_Shop_1.svg");
}

.lobby2-side-menu-item:active .lobby2-side-menu-item-icon.shop {
  background-image: url("/public/img/lobby/UI_Shop_2.svg");
}

.lobby2-side-menu-item-icon.shop.current {
  background-image: url("/public/img/lobby/UI_Shop_3.svg") !important;
}

.lobby2-side-menu-item.disabled .lobby2-side-menu-item-icon.shop {
  background-image: url("/public/img/lobby/UI_Shop_4.svg") !important;
}

.lobby2-side-menu-item:hover .lobby2-side-menu-item-icon.inventory {
  background-image: url("/public/img/lobby/UI_Inventory_1.svg");
}

.lobby2-side-menu-item:active .lobby2-side-menu-item-icon.inventory {
  background-image: url("/public/img/lobby/UI_Inventory_2.svg");
}

.lobby2-side-menu-item-icon.inventory.current {
  background-image: url("/public/img/lobby/UI_Inventory_3.svg") !important;
}

.lobby2-side-menu-item.disabled .lobby2-side-menu-item-icon.inventory {
  background-image: url("/public/img/lobby/UI_Inventory_4.svg") !important;
}

.lobby2-side-menu-item:hover .lobby2-side-menu-item-icon.call_superior {
  background-image: url("/public/img/lobby/UI_Call_Superior_1.svg");
}

.lobby2-side-menu-item:active .lobby2-side-menu-item-icon.call_superior {
  background-image: url("/public/img/lobby/UI_Call_Superior_2.svg");
}

.lobby2-side-menu-item-icon.call_superior.current {
  background-image: url("/public/img/lobby/UI_Call_Superior_3.svg") !important;
}

.lobby2-side-menu-item.disabled .lobby2-side-menu-item-icon.call_superior {
  background-image: url("/public/img/lobby/UI_Call_Superior_4.svg") !important;
}

.lobby2-side-menu-item:hover .lobby2-side-menu-item-icon.mission_objective {
  background-image: url("/public/img/lobby/UI_Mission_Objective_1.svg");
}

.lobby2-side-menu-item:active .lobby2-side-menu-item-icon.mission_objective {
  background-image: url("/public/img/lobby/UI_Mission_Objective_2.svg");
}

.lobby2-side-menu-item-icon.mission_objective.current {
  background-image: url("/public/img/lobby/UI_Mission_Objective_3.svg") !important;
}

.lobby2-side-menu-item.disabled .lobby2-side-menu-item-icon.mission_objective {
  background-image: url("/public/img/lobby/UI_Mission_Objective_4.svg") !important;
}

.lobby2-side-menu-item:hover .lobby2-side-menu-item-icon.mission_final {
  background-image: url("/public/img/lobby/UI_Mission_Final_1.svg");
}

.lobby2-side-menu-item:active .lobby2-side-menu-item-icon.mission_final {
  background-image: url("/public/img/lobby/UI_Mission_Final_2.svg");
}

.lobby2-side-menu-item-icon.mission_final.current {
  background-image: url("/public/img/lobby/UI_Mission_Final_3.svg") !important;
}

.lobby2-side-menu-item.disabled .lobby2-side-menu-item-icon.mission_final {
  background-image: url("/public/img/lobby/UI_Mission_Final_4.svg") !important;
}

.lobby2-highlight {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;

  animation-name: lobby2-highlight;
  animation-duration: 300ms;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

.lobby2-highlight-shadow {
  position: absolute;
  width: 100%;
  height: 100%;

  border-radius: 7px;
  border: 3px solid #ff8d3a;
  filter: blur(3.5px);
}

.lobby2-highlight-background {
  position: absolute;
  width: 100%;
  height: 100%;

  border-radius: 7px;
  border: 3px solid #ffd990;
}

.lobby2-highlight-stroke {
  position: absolute;
  width: 100%;
  height: 100%;

  border-radius: 7px;
  border: 2px solid #fff7e8;
}

.lobby2-highlight-glow {
  position: absolute;
  width: 100%;
  height: calc(100% - 3px);

  border-radius: 7px;
  opacity: 0.1;
  background: #fff;
}

.lobby2-highlight-inner {
  position: absolute;
  width: 100%;
  height: calc(100% - 3px);

  border-radius: 7px;
  background: rgba(255, 255, 255, 0.01);
  box-shadow: 0px 3px 0px 0px rgba(255, 245, 194, 0.4);
}

.lobby2-highlight-hand {
  position: absolute;
  animation-name: highlight-hand;
  animation-duration: 600ms;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in;
}

@keyframes lobby2-highlight {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

.lobby-video-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
}

.lobby-video-container.lobby-video-container-1 {
  position: absolute;
  filter: blur(20px);
}

.lobby-video-container.lobby-video-container-2 {
  position: absolute;
  left: -250px;
  top: 75px;
}
