.figma-tooltip {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  z-index: 25;

  font-family: "Noto Sans";
  color: #3f2609;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  white-space: pre-wrap;

  pointer-events: none;
}

.figma-tooltip.agent {
  background: #241b1b;
  width: 220px;
}

.figma-tooltip .lobby-squad-drawer-item-label,
.figma-tooltip .lobby-squad-drawer-item-value {
  color: #3f2609;
}

.figma-tooltip-marker::after {
  content: "?";
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #a98744;

  color: #3a2c10;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 11px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  aspect-ratio: 1 / 1;
  margin-left: 4px;
}

.figma-tooltip > svg {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

/*
.outgame-lobby span:has(+ .figma-tooltip)::after,
.outgame-lobby button:has(+ .figma-tooltip)::after,
.outgame-lobby div:has(+ .figma-tooltip)::after {
  @apply figma-tooltip-marker;
}
*/

.figma-role-badge-tooltip {
  border: 3px solid #015b61;
  background: #19363f;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.8);

  padding: unset;

  font-family: "Noto Sans";
  color: #befffb;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figma-role-badge-tooltip-descr {
  display: flex;
  padding: 10px 15px 8px 15px;
  align-items: center;
  gap: 2px;
  align-self: stretch;
}

.figma-role-badge-tooltip-info-group {
  display: flex;
  padding-bottom: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.figma-role-badge-tooltip-info {
  display: flex;
  padding: 0px 8px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.figma-role-badge-tooltip-info-label {
  display: flex;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  background: #124a4e;

  overflow: hidden;
  color: #63b3b8;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figma-role-badge-tooltip-info-value {
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  background: #082b2d;
}

.figma-role-badge-tooltip-firearmaptitude {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.figma-role-badge-tooltip-aptitude {
  display: flex;
  justify-content: center;
  align-items: center;
}

.figma-tooltip-shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #5c381d;
  filter: blur(5px);
}

.figma-tooltip-body {
  display: flex;
  min-width: 250px;
  max-width: 496px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border: 2px solid #a6551b;
  background: #f7e169;
  position: relative;
  padding: 5px 7px 7px 7px;
}

.figma-tooltip-body-header {
  height: 2px;
  align-self: stretch;
  background: #ffeb85;
}
