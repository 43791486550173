.figmaplan-testroot {
  width: 1920px;
  height: 1080px;

  background: #241b1b;

  position: relative;

  font-family: "Noto Sans";
}

.figmaplan-root {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 90%;
  overflow-y: scroll;

  border: 7px solid #453325;
}

.figmaplan-root.full {
  height: 100%;
  background: #241b1b;
  overflow-y: hidden;
}

.figmaplan-testroot > .figmaplan-root {
  position: absolute;
  top: 50px;
  left: 310px;

  width: 1413px;
}

.figmaplan-title {
  padding: 6px 15px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  background: #453325;

  color: #8f7054;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  width: 100%;
}

.figmaplan-header {
  display: flex;
  align-items: center;
  gap: 20px;

  width: 100%;
}

.figmaplan-mission {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.figmaplan-mission-mark {
  width: 4px;
  height: 24px;
  background: #917853;
}

.figmaplan-mission-desc {
  color: #917853;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

.figmaplan-mission-value {
  color: #dab988;
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;

  text-align: left;
}

.figmaplan-mission-plan {
  display: flex;
  padding: 4px 15px;
  align-items: center;
  gap: 10px;
  border-radius: 19px;
  background: #ffd569;

  color: #63410d;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

.figmaplan-mission-objective-btn {
  display: flex;
  padding: 4px 0px;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  border: 2px solid #74563f;
  background: #5c422f;

  color: #f7d19d;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.figmaplan-due {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
}

.figmaplan-due-desc {
  color: #dab988;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.figmaplan-due-value {
  color: #f7ba1c;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
}

.figmaplan-map {
  display: flex;
  width: 1280px;
  flex-direction: column;
  align-items: flex-start;
}

.figmaplan-tabs {
  display: flex;
  padding: 0px 2px;
  align-items: flex-end;
  gap: 2px;
  width: 100%;

  border-bottom: 3px solid #5c422f;
}

.figmaplan-tab {
  display: flex;
  height: 32px;
  padding: 8px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  border-radius: 8px 8px 0px 0px;
  border-top: 2px solid #382a1f;
  border-right: 2px solid #382a1f;
  border-left: 2px solid #382a1f;
  background: #443122;

  color: #775b45;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  cursor: pointer;
}

.figmaplan-tab.figmaplan-tab-current {
  border-top: 2px solid #74563f;
  border-right: 2px solid #74563f;
  border-left: 2px solid #74563f;
  background: #5c422f;

  color: #f7d19d;
}

.figmaplan-strategy {
  width: 1280px;
  height: 808px;
  background: rgba(#4c321b, 0.2);

  position: relative;
}

.figmaplan-strategy.entire,
.figmaplan-strategy.segment {
  display: flex;
}

.figmaplan-strategy-sidebar {
  display: flex;
  width: 262px;
  height: 808px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  border-right: 3px solid #5c422f;
  background: rgba(70, 50, 36, 0.4);
}

.figmaplan-strategy-sidebar-header {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.figmaplan-strategy-sidebar-header-button {
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 6px;
  border: 2px solid #382a1f;
  background: #443122;

  color: #775b45;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.figmaplan-strategy-sidebar-header-button.active {
  border: 2px solid #74563f;
  background: #5c422f;

  color: #f7d19d;
}

.figmaplan-sidebar-container {
  display: flex;
  padding: 0px 5px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;
  overflow-y: auto;
  overflow-x: hidden;
}

.figmaplan-strategy-policy-selector,
.figmaplan-strategy-policy-tactical {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.figmaplan-strategy-policy-selector-title,
.figmaplan-strategy-policy-tactical-title {
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  margin: 4px 0px;
  align-self: stretch;

  background: #473324;

  color: #dab988;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.figmaplan-strategy-policy-selector-title.volatile {
  color: #e92424;
  background: #650c0c;
}

.figmaplan-strategy-plan-selector-desc {
  display: flex;
  padding: 10px 0px;
  align-items: center;
  align-self: stretch;

  color: #a98744;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.figmaplan-strategy-policy-selector-title > span {
  display: flex;
  align-items: center;
  gap: 10px;
}

.figmaplan-strategy-policy-selector-options {
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  align-self: stretch;
}

.figmaplan-strategy-policy-selector-option {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  overflow: hidden;
  color: #a98744;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaplan-strategy-policy-selector-option.selected {
  background: unset;
}

.figmaplan-strategy-policy-selector-option.volatile {
  color: #e92424;
  background: #650c0c;
}

.figmaplan-strategy-policy-selector-option > span {
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmaplan-strategy-policy-toggler {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.figmaplan-strategy-policy-toggler-desc {
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;

  overflow: hidden;
  color: #b4915c;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaplan-strategy-policy-toggler-desc > span {
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
}

.figmaplan-strategy-policy-toggle-button {
  width: 65px;
  height: 23px;
  flex-shrink: 0;

  border-radius: 100px;
  border: 2px solid #523d2c;
  background: #30231d;

  position: relative;
}

.figmaplan-strategy-policy-toggle-button-foreground {
  display: flex;
  width: 50px;
  height: 23px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  position: relative;

  top: -1px;
  left: -1px;

  border-radius: 66px;
  border: 2px solid #694c36;
  background: #5c422f;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);

  color: #967359;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  transition: all 0.5s;
}

.figmaplan-strategy-policy-toggle-button-foreground.active {
  left: 13px;

  border: 2px solid #816148;
  background: #74563f;

  color: #f7d19d;
}

.figmaplan-strategy-policy-toggler-options {
  display: flex;
  padding: 5px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3px;
  align-self: stretch;
}

.figmaplan-strategy-body {
  display: flex;
  flex: 1 0 0;
  align-items: flex-start;
  gap: 0px;
  align-self: stretch;
  position: relative;
  overflow: hidden;
}

.figmaplan-strategy-container {
  flex: 1 0 0;
  width: 100%;
  height: 100%;

  position: relative;
  overflow: hidden;

  --c-plancanvas-stroke: #3b2d21;
  --c-plancanvas-bg: #2c201b;
  background: linear-gradient(
      0deg,
      var(--c-plancanvas-stroke) 1px,
      transparent 0,
      transparent 12px
    ),
    linear-gradient(
      90deg,
      var(--c-plancanvas-stroke) 1px,
      transparent 0,
      transparent 12px
    ),
    var(--c-plancanvas-bg);
  background-size: 13px 13px;
}

.figmaplan-strategy-header-overlay {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: fit-content;
  padding: 3px 0px;
  align-items: flex-start;
  flex-shrink: 0;

  background: rgba(34, 28, 19, 0.6);

  position: absolute;
  top: 0px;
  left: 0px;
  transition: all 0s;
  z-index: 2;
}

.figmaplan-strategy-header-overlay-item {
  display: flex;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  flex: 1 0 0;

  overflow: hidden;
  color: #b4915c;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaplan-strategy-header-overlay-item.excess:before {
  content: "😴";
}

.figmaplan-strategy-header-overlay-item.who:before {
  content: "🤔";
}

.figmaplan-strategy-header-overlay-item.short {
  color: #e92424;
}

.figmaplan-strategy-header-overlay-item.short:before {
  content: "👿";
}

.figmaplan-strategy-header-item {
  display: flex;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  gap: 6px;

  overflow: hidden;
  color: #b4915c;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaplan-strategy-header-item > select {
  display: flex;
  height: 28px;
  padding: 3px 10px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;

  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #74563f;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmaplan-strategy-previewbox {
  width: 100%;
  height: 100%;

  display: flex;
}

.figmaplan-strategy-enemy {
  display: flex;
  padding: 8px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;

  border: 2px solid #ff3f14;

  overflow: hidden;
  color: #ff3f14;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaplan-strategy-enemy-firearm,
.figmaplan-strategy-enemy-vest {
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.figmaplan-strategy-enemy-firearm-ty {
  display: flex;
  padding: 6px 4px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: #ff3f14;

  color: #000;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 12px */
  text-transform: capitalize;

  color: #ffb800;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-header-balance {
  display: flex;
  width: 270px;
  padding: 4px 20px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  border-radius: 45px;
  background: #261910;

  margin-left: auto;
}

.figmalist-header-balance-label {
  color: #ffb800;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-header-balance-value {
  color: #ffcf52;
  text-align: right;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmalist-header-balance + .figmalist-header-close {
  margin-left: unset;
}

.figmaplan-strategy-plan-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  flex: 1 0 0;
}

.figmaplan-strategy-plan-selector-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;

  border-radius: 4px;
  border: 3px solid #8a5c32;
  background: #724b26;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.5);
}

.figmaplan-strategy-plan-selector-item.active {
  border: 3px solid #ffd3ab;
}

.figmaplan-strategy-plan-selector-item-img {
  width: 239px;
  height: 90px;
  overflow: hidden;
}

.figmaplan-strategy-plan-selector-item-img > img {
  object-fit: cover;
}

.figmaplan-strategy-plan-selector-item-title {
  display: flex;
  padding: 4px 10px;
  align-items: flex-start;
  align-self: stretch;
  background: #50311b;

  color: #ffd569;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;

  white-space: break-spaces;
}

.figmaplan-strategy-plan-selector-item-desc {
  display: flex;
  padding: 5px 10px;
  align-items: flex-start;
  align-self: stretch;

  color: #be8f47;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
}

.figmaplan-strategy-title-toggle-button {
  width: 65px;
  height: 23px;
  flex-shrink: 0;

  border-radius: 100px;
  border: 2px solid #523d2c;
  background: #30231d;

  position: relative;
}

.figmaplan-strategy-title-toggle-button-foreground {
  display: flex;
  width: 50px;
  height: 23px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  position: relative;

  top: -1px;
  left: -1px;

  border-radius: 66px;
  border: 2px solid #816148;
  background: #74563f;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);

  color: #f7d19d;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  transition: all 0.5s;
}

.figmaplan-strategy-title-toggle-button-foreground.active {
  left: 13px;
}

.figmahistory-map {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.figmahistory-tabs {
  display: flex;
  padding: 5px 2px 0px 2px;
  align-items: flex-end;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmahistory-tab {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 8px 8px 0px 0px;
  border-top: 2px solid #251910;
  border-right: 2px solid #251910;
  border-left: 2px solid #251910;
  background: #281c12;
}

.figmahistory-tab-title {
  color: #5e4836;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmahistory-tab.available {
  border-top: 2px solid #382a1f;
  border-right: 2px solid #382a1f;
  border-left: 2px solid #382a1f;
  background: #3e2b1c;
}

.figmahistory-tab.available.current {
  border-top: 2px solid #74563f;
  border-right: 2px solid #74563f;
  border-left: 2px solid #74563f;
  background: #5c422f;
}

.figmahistory-tab.available > .figmahistory-tab-title,
.figmahistory-tab.current > .figmahistory-tab-title {
  color: #be945c;
}

.figmahistory-tab.available > .figmahistory-tab-value,
.figmahistory-tab.current > .figmahistory-tab-value {
  color: #f7d19d;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaplan-history {
  display: flex;
  width: 1280px;
  height: 808px;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;

  position: relative;
  overflow: hidden;

  --c-plancanvas-stroke: #3b2d21;
  --c-plancanvas-bg: #2c201b;
  background: linear-gradient(
      0deg,
      var(--c-plancanvas-stroke) 1px,
      transparent 0,
      transparent 12px
    ),
    linear-gradient(
      90deg,
      var(--c-plancanvas-stroke) 1px,
      transparent 0,
      transparent 12px
    ),
    var(--c-plancanvas-bg);
  background-size: 13px 13px;
}

.figmaplan-history-sidebar {
  display: flex;
  width: 262px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;

  border-right: 3px solid #5c422f;
  background: rgba(70, 50, 36, 0.4);
}

.figmaplan-history-sidebar-info {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmaplan-history-sidebar-info-title {
  display: flex;
  padding: 2px 5px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  align-self: stretch;

  background: #473324;

  color: #c4a87d;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.figmaplan-history-sidebar-info-squad {
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.figmaplan-history-sidebar-info-squad-agent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border: 2px solid #5a452c;
  background: #3c2b1b;
}

.figmaplan-history-sidebar-info-squad-agent-header {
  display: flex;
  padding: 7px 8px;
  align-items: center;
  gap: 5px;
  align-self: stretch;

  background: #5a452c;
}

.figmaplan-history-sidebar-info-squad-agent-body {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;

  border-radius: 4px;
}

.figmaplan-history-sidebar-info-squad-agent-gear {
  display: flex;
  height: 24px;
  padding: 0px 5px;
  align-items: center;
  gap: 5px;
  align-self: stretch;

  border-radius: 4px;

  color: #baa96c;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaplan-history-sidebar-info-date {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  color: #a98744;
  text-align: center;
  font-family: "Roboto Mono";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
}

.figmaplan-history-sidebar-footer {
  display: flex;
  height: 70px;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.figmaplan-history-sidebar-footer-button {
  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  border-radius: 6px;
  border: 2px solid #74563f;
  background: #5c422f;

  color: #f7d19d;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
