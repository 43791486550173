.typing-effect {
  white-space: pre-wrap;
  font-size: 2em;
  padding: 5em;

  color: #C6BF9E;
  background: #17170F;
  height: 100%;

  font-family: monospace;
}

.typing-effect>p {
  margin: 0.5em 0em;
}

.typing-effect-inv {
  color: black;
  background: white;
}

.typing-effect-skip {
  position: absolute;
  bottom: 5em;
  right: 5em;
  font-size: 2em;
  font-family: monospace;

  color: #524C32;
  animation: blink-animation 2s infinite;
}

.typing-effect-skip-done {
  color: white;
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
