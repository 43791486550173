.title-root0 {
  width: 1920px;
  height: 1080px;
}

.title-root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: #000000;
  display: flex;
  flex-direction: row;
  position: relative;
}

.title-left {
  width: 1094px;
  height: 1080px;
  position: relative;
}

.title-right {
  width: 826px;
  height: 1080px;
  background: url("/public/img/title/PMM_KeyArt_Title.png");
}

.title-logo {
  width: 896px;
  height: 295px;
  top: 191px;
  left: 101px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-options {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.title-options-select {
  color: #000000;
}

.title-buttongroup {
  position: absolute;
  top: 560px;
  bottom: 60px;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 80px;
}

.title-buttongroup-inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 10px;
}

.title-button {
  color: #fdfef7;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 70px;
  vertical-align: middle;

  transition: all 0.05s ease-out;
  user-select: none;
}

.title-button-ribbon {
  width: 100%;
  height: 70px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  transition: all 0.05s ease-out;
}

.title-button p {
  text-align: center;
  font-family: Antonio;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding-bottom: 5px;

  transition: all 0.05s ease-out;
}

.title-button:hover>.title-button-ribbon {
  background-color: #ba0024;
}

.title-button:hover p {
  font-size: 40px;
  padding-bottom: 6px;
}

.title-button:active>.title-button-ribbon {
  background-color: #7c010b;
  height: 66px;
}

.title-button:active p {
  font-size: 39px;
  color: #dc9a9f;
  padding-bottom: 2px;
}

.title-button-exit p {
  color: #878787;
  font-size: 26px;
  padding-bottom: 6px;
}

.title-button-exit:hover>.title-button-ribbon {
  height: 50px;
}

.title-button-exit:hover p {
  color: #ffefd7;
  font-size: 32px;
  padding-bottom: 5px;
}

.title-button-exit:active>.title-button-ribbon {
  height: 46px;
}

.title-button-exit:active p {
  color: #dc9a9f;
  font-size: 32px;
  padding-bottom: 3px;
}

.title-videoroot {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;

  background: rgba(0, 0, 0, 1);
}

.title-videoroot video {
  display: flex;
}

.title-side {
  position: absolute;
  top: 80%;
  right: 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  transform: translateY(-50%);
}

.title-side-button {
  background: #dfa612;
  color: #422f0b;
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: 10px 5px 10px 5px;
  border: 1px solid #dcba86;
  gap: 5px;
}

.title-side-button.lobby {
  background: #422c1c;
  color: #dcba86;

  padding: 10px 5px 10px 15px;
  border: unset;
  clip-path: polygon(10% 0%, 100% 0px, 100% 100%, 10% 100%, 0% 50%);

  justify-content: center;
}

.title-side-button:hover {
  background: #dcba86;
  color: #422c1c;
}

.title-side-button-label {
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;

  text-transform: uppercase;
}

.overlay-flex button.title-side-button {
  margin: 2px 4px;
  box-shadow: var(--fh1-shadow-tiny);
}

.title-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #878787;
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  line-height: 1.5;
}


.title-overlay-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(15px);
}

@keyframes FadeIn {
  0% {
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(15px);
  }

  100% {
    background: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
  }
}


.title-overlay-root.clicked {
  animation: FadeIn 0.7s ease-out forwards;

}

@keyframes scaleAndFade {
  0% {
    transform: scale(0.95);
    opacity: 0.75;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.title-overlay-image {
  animation: scaleAndFade 0.7s ease-out forwards;
}

.title-overlay-button {
  width: 379px;
  height: 54px;
  flex-shrink: 0;

  position: relative;

  border-radius: 7px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.title-overlay-button:hover,
.title-overlay-button:active {
  cursor: pointer;
}

.title-overlay-button-emboss {
  width: 379px;
  height: 51px;
  flex-shrink: 0;

  border-radius: 7px;
  background: #581d15;

  position: absolute;
  top: 3px;
}

.title-overlay-button-background {
  width: 379px;
  height: 51px;
  flex-shrink: 0;

  border-radius: 7px;
  border: 1.4px solid #923c30;
  background: #6f241a;

  position: absolute;
  top: 0px;
}

.title-overlay-button-outline {
  width: 373px;
  height: 45px;
  flex-shrink: 0;

  border-radius: 4px;
  border: 2px solid #3a0606;

  position: absolute;
  top: 3px;
  left: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.title-overlay-button-label {
  color: #ffbd75;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  position: relative;

  display: flex;
  align-items: center;
  gap: 5px;
}

.title-overlay-button:hover .title-overlay-button-emboss {
  background: #872619;
}

.title-overlay-button:hover .title-overlay-button-background {
  border: 1.4px solid #923c30;
  background: #9b382b;
}

.title-overlay-button:hover .title-overlay-button-outline {
  border: 2px solid #85291c;
}

.title-overlay-button:hover .title-overlay-button-label {
  color: #ffd2a1;
}

.title-overlay-button:active .title-overlay-button-emboss {
  background: #581d15;
}

.title-overlay-button:active .title-overlay-button-background {
  border: 1.4px solid #782b21;
  background: #551b13;

  top: 2px;
}

.title-overlay-button:active .title-overlay-button-outline {
  border: 2px solid #3a0606;

  top: 5px;
}

.title-overlay-button:active .title-overlay-button-label {
  color: #ffd2a1;
}

.title-overlay-button.disabled {
  cursor: unset;
}

.title-overlay-button.disabled .title-overlay-button-emboss {
  background: #424242 !important;
}

.title-overlay-button.disabled .title-overlay-button-background {
  border: 1.4px solid #555 !important;
  background: #474747 !important;

  top: 0px !important;
}

.title-overlay-button.disabled .title-overlay-button-outline {
  border: 2px solid #333 !important;

  top: 3px !important;
}

.title-overlay-button.disabled .title-overlay-button-label {
  color: #777 !important;
}