.editor-root {
}

.editor-root textarea {
  border: 1px solid gray;
}
.editor-btn-eye-off {
  color: gray;
}

.editor-layers {
  float: right;
}

.plancanvas {
  position: relative;
  margin: auto;
}

.plancanvas-block {
  position: absolute;
}

.plancanvas-block.shadow {
  background: #1b1109;
}

.plancanvas-block.full {
  background: #634a36;
}

.plancanvas-block.door {
  background-color: #ffe4b1;
}

.plancanvas-route {
  position: absolute;
  background-color: #3cb07a;

  opacity: 1;
  /* background-image: linear-gradient(135deg, #3d795d 25%, transparent 25%),
    linear-gradient(225deg, #3d795d 25%, transparent 25%),
    linear-gradient(45deg, #3d795d 25%, transparent 25%),
    linear-gradient(315deg, #3d795d 25%, #3cb07a 25%); */
  background: linear-gradient(225deg, #3d795d 25%, transparent 25%),
    linear-gradient(315deg, #3d795d 25%, transparent 25%),
    linear-gradient(315deg, #3cb07a 50%, transparent 50%),
    linear-gradient(225deg, #3cb07a 50%, #3b795d 50%);
  background-size: 20px 20px;
  background-repeat: repeat;
  animation: move-to-right 1s linear infinite;

  pointer-events: none;
}

@keyframes move-to-right {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 20px 0;
  }
}

.plancanvas-route.route-0 {
  mask-image: linear-gradient(to right, transparent 0%, black 50%);
}

.plancanvas-route-point {
  position: absolute;
  background-color: #3cb07a;
  border-radius: 50%;

  pointer-events: none;
}

.plancanvas-route-goal {
  position: absolute;
  background: radial-gradient(
      circle farthest-side,
      #3cb07a 6.7%,
      #3b795d 6.7%,
      #3b795d 20%,
      #3cb07a 20%,
      #3cb07a 49%,
      #3b795d 49%,
      #3b795d 100%
    ),
    #3cb07a;
  background-repeat: no-repeat;
  border-radius: 50%;
  animation: move-to-center 1s linear infinite;

  pointer-events: none;
}

@keyframes move-to-center {
  from {
    background-size: 500% 500%;
    background-position: 50% 50%;
  }
  to {
    background-size: 100% 100%;
    background-position: 50% 50%;
  }
}

.figmaplan-area-background {
  --c-figmaplan-arena-overlay-stroke: #463212;
  background: linear-gradient(
      -45deg,
      var(--c-figmaplan-arena-overlay-stroke) 25%,
      transparent 0,
      transparent 50%,
      var(--c-figmaplan-arena-overlay-stroke) 0,
      var(--c-figmaplan-arena-overlay-stroke) 75%,
      transparent 0
    ),
    transparent;
  background-size: 12px 12px;

  position: absolute;
  border: 2px solid transparent;
}

.figmaplan-area-overlay {
  position: absolute;
  border: 2px solid transparent;
  pointer-events: none;
}

.figmaplan-area-background.current {
  --c-figmaplan-arena-overlay-stroke: #683f0e;
  background: linear-gradient(
      -45deg,
      var(--c-figmaplan-arena-overlay-stroke) 25%,
      transparent 0,
      transparent 50%,
      var(--c-figmaplan-arena-overlay-stroke) 0,
      var(--c-figmaplan-arena-overlay-stroke) 75%,
      transparent 0
    ),
    transparent;
  background-size: 12px 12px;

  position: absolute;
}

.figmaplan-area-overlay.current {
  border: 2px solid #b07b3c;
}

.figmaplan-area-background:hover {
  --c-figmaplan-arena-overlay-stroke-hover: #683f0e;
  background: linear-gradient(
      -45deg,
      var(--c-figmaplan-arena-overlay-stroke-hover) 25%,
      transparent 0,
      transparent 50%,
      var(--c-figmaplan-arena-overlay-stroke-hover) 0,
      var(--c-figmaplan-arena-overlay-stroke-hover) 75%,
      transparent 0
    ),
    transparent;
  background-size: 12px 12px;
}

.figmaplan-area-overlay-info {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  flex-wrap: wrap;
}

.figmaplan-area-overlay-segment {
  display: flex;
  width: 37px;
  height: 27px;
  padding: 2px 7px 0px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: #684d38;

  color: #ffe0a3;
  text-align: center;
  font-family: Freshman;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.54px;
}

.current .figmaplan-area-overlay-segment {
  background: #b07b3c;
}

.figmaplan-area-overlay-icons {
  display: flex;
  padding: 2px;
  align-items: center;
  align-content: center;
  gap: 3px 2px;
  flex: 1 0 0;
  flex-wrap: wrap;
}

.figmaplan-area-overlay-tallymarks {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;

  position: absolute;
  right: 0px;
  bottom: 0px;
}

.figmaplan-area-overlay-hall-of-shame {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 2px;
  flex: 1 0 0;
  flex-wrap: wrap;
}

.figmaplan-area-overlay-hall-of-shame .portrait-frame-small5 {
  filter: grayscale(100%);
}

.tab_level_1 {
  padding-left: 10px;
}

.tab_level_2 {
  padding-left: 20px;
}