.debug {
  background-color: #eee;
  padding: 0.25em 0.5em;
  margin: 0.5em 0em;
  overflow-wrap: anywhere;
}

input[type=number] {
  border: 1px solid black;
  border-radius: .3em;
  margin: 1px;
}

.flex-card-short.training {
  width: 900px;
}

.session-select *[title] {
  background: #ddd;
}

.training-pauseinfo {
  color: var(--c-highlight00);
  font-size: 1.1em;
}