.test-comp-wrap {
  background-color: #008000;
  height: 300px;
  width: 400px;
}

.test-comp-wrap .inner {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  background-color: #ffff00;
  display: flex;
  flex-wrap: wrap;

  height: 260px;
  left: 20px;
  padding: 5px;
  position: relative;
  top: 20px;
  width: 360px;
}

.test-comp-wrap .swap-dummer-instance {
  position: relative !important;
}
.test-comp-wrap .inner>* { margin-right: 34px; margin-bottom: 20px; } .test-comp-wrap .inner>*:last-child { margin-right: 0; margin-bottom: 0; }
