.simoverlay-root {
  position: fixed;
  height: 100%;

  font-family: "Noto Sans";
}

.simoverlay-paused {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sim-gamespeed-controller {
  position: absolute;
  top: 13px;
  left: 15px;
  display: flex;
  padding: 5px 10px 5px 15px;
  align-items: center;
  gap: 15px;

  border-radius: 8px;
  background: rgba(0, 0, 0, 0.70);
}

.sim-gamespeed-controller-label {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  color: #8B9E9D;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.sim-gamespeed-controller-buttons {
  display: flex;
  align-items: center;
  gap: 20px;

  border-radius: 10px;
}

.sim-gamespeed-controller-buttons > .speed-button {
  position: relative;
  top: 2px;
  width: 38px;
  height: 31px;
  filter: drop-shadow(0px 2px 0px #000);
}

.sim-gamespeed-controller-buttons > .speed-button:hover .button-rect1 {
  fill: #CBD6D5;
}

.sim-gamespeed-controller-buttons > .speed-button:hover .button-rect2 {
  stroke: #AAB5B4;
}

.sim-gamespeed-controller-buttons > .speed-button:hover .button-path {
  fill: #3C3C3C;
}

.sim-gamespeed-controller-buttons > .speed-button:active {
  filter: drop-shadow(0px 1px 0px #000);
}

.sim-gamespeed-controller-buttons > .speed-button:active .button-rect1 {
  fill: #ADB9B9;
}

.sim-gamespeed-controller-buttons > .speed-button:active .button-rect2 {
  stroke: #9CABAA;
}

.sim-gamespeed-controller-buttons > .speed-button:active .button-path {
  fill: #3C3C3C;
}

.sim-gamespeed-controller-buttons > .xspeed {
  color: #DCE9E9;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2.4px;
}
