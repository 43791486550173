.portrait-noframe {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.portrait-frame {
  position: relative;
  width: 489px;
  height: 567px;

  overflow: hidden;

  border-radius: 4px;
  border: 2px solid #ffd075;
  background-color: #c09c65;
}

.portrait-frame::before {
  content: "";
  position: absolute;
  background-color: transparent;
  top: 0px;
  left: 0px;
  min-width: 30px;
  min-height: 30px;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

/* .clickable .portrait-frame::after {
  content: "";
  position: absolute;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none"><g filter="url(%23filter0_d_404_1502)"><path d="M0 14C0 15.6569 1.34315 17 3 17H17V3C17 1.34315 15.6569 0 14 0H0V14Z" fill="%23FFD075"/></g><path fill-rule="evenodd" clip-rule="evenodd" d="M12.148 8.99736C13.5329 7.22421 13.565 4.69451 12.0762 2.87238C10.3205 0.723648 7.11482 0.372565 4.91601 2.08825C2.7172 3.80391 2.35795 6.93663 4.11361 9.08536C5.60244 10.9076 8.13409 11.4369 10.2102 10.5095L13.0622 14L15 12.4879L12.148 8.99736ZM10.0855 4.42563C10.9634 5.49999 10.7837 7.06635 9.68434 7.92418C8.58493 8.78202 6.98208 8.60648 6.10426 7.53212C5.22644 6.45775 5.40606 4.89139 6.50546 4.03357C7.60486 3.17573 9.20772 3.35126 10.0855 4.42563Z" fill="%23230F00"/><defs><filter id="filter0_d_404_1502" x="0" y="0" width="17" height="18" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="1"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_404_1502"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_404_1502" result="shape"/></filter></defs></svg>');
  top: 2px;
  right: 1px;
  width: 17px;
  height: 17px;
} */

.portrait-frame-small0 {
  width: 244px;
  height: 283px;
}

.portrait-frame-small01 {
  width: 183px;
  height: 212px;
}

.portrait-frame-small1 {
  width: 122px;
  height: 142px;
}

.portrait-frame-small1-figma {
  width: 109px;
  height: 135px;
}

.portrait-frame-small1-figma>img {
  object-fit: cover;
}

.portrait-frame-small2 {
  width: 82px;
  height: 82px;
}

.portrait-frame-small2>.portrait-part {
  top: -7px;
  width: unset;
  height: unset;
}

.portrait-frame-small2>img,
img.portrait-frame-small2 {
  object-fit: cover;
}

.portrait-frame-small3 {
  width: 61px;
  height: 71px;
}

.portrait-frame-small4 {
  width: 44px;
  height: 44px;
}

.portrait-frame-small4>img,
img.portrait-frame-small4 {
  object-fit: cover;
}

.portrait-frame-small4>.portrait-part {
  width: 44px;
  height: 44px;
}

.portrait-frame-small4>img,
img.portrait-frame-small4 {
  object-fit: cover;
}

.portrait-frame-small5 {
  width: 26px;
  height: 34px;
}

.portrait-frame-small5>img,
img.portrait-frame-small5 {
  object-fit: cover;
}

.portrait-frame-ingame {
  width: 73px;
  height: 88px;
  object-fit: cover;
}

.portrait-frame-dialog {
  width: 336px;
  height: 400px;
}

.portrait-frame-detail2 {
  width: 101px;
  height: 120px;
  border-radius: unset;
}

.portrait-frame-detail2.flex-agents-portrait {
  width: 101px;
  height: 120px;
  background-color: #c09c65;
  border: 2px solid #ede2d3;
  box-sizing: content-box;
}

.portrait-frame-detail2>img,
img.portrait-frame-detail2 {
  object-fit: cover;
}

.portrait-frame-card {
  width: 98px;
  height: 116px;
}

.portrait-frame-card>img,
img.portrait-frame-card {
  object-fit: cover;
}

.portrait-frame-card.flex-agents-portrait {
  border: 2px solid #ede2d3;
}

.portrait-part {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.portraitsview-preset-input {
  width: 300px;
}

.portraitsview-preset-row {
  display: flex;
  justify-content: center;
  align-items: center;
}

.portrait-frame-missionreport {
  width: 39px;
  height: 48px;
}

.portrait-frame-missionreport>img {
  object-fit: cover;
}

.portrait-frame-event-agent {
  width: 72px;
  height: 86px;
  box-sizing: border-box;
}

div.portrait-frame-event-agent {
  border: 2px solid #ede2d3;
  overflow: hidden;
}

.portrait-frame-event-agent>img,
img.portrait-frame-card {
  object-fit: cover;
}

.portrait-wrapper2 {
  position: relative;
  overflow: hidden;
}

.portrait-wrapper2.portrait-frame-card {
  width: 134px;
  height: 158px;
}

.portrait-wrapper2.portrait-frame-event {
  width: 114px;
  height: 137px;
}

.portrait-noframe.portrait-frame-card {
  width: 115px;
  height: 137px;
}

.portrait-noframe.portrait-frame-event {
  width: 97.6px;
  height: 119px;
}

.portrait-wrapper2-backgrounds {
  position: absolute;
  width: 100%;
  height: 100%;
}

.portrait-wrapper2-backgrounds-gray {
  position: absolute;
  width: 122.539px;
  height: 146.565px;
  top: 6.24px;
  left: 6.26px;
  right: 5.18px;
  bottom: 5.2px;
  transform: rotate(3.175deg);
  flex-shrink: 0;
  background: rgba(0, 0, 0, 0.5);
}

.portrait-frame-for-armory {
  width: 66px;
  height: 78px;
}

.portrait-noframe.portrait-frame-for-armory {
  width: 57.2px;
  height: 68px;
}

.portrait-frame-for-armory>img {
  object-fit: cover;
}

.portrait-frame-for-armory .portrait-part {
  width: 57.2px;
  height: 68px;
}

.portrait-frame-for-armory .portrait-wrapper2-backgrounds-gray {
  width: 60.355px;
  height: 72.355px;
  top: 1.46px;
  left: 1.14px;
  right: 0.6px;
  bottom: 0.94px;
  transform: rotate(3.168deg);
}

.portrait-frame-for-armory .portrait-wrapper2-backgrounds-brown {
  width: 60.355px;
  height: 72.355px;
  top: 0.43px;
  left: 0.01px;
  right: 0.41px;
  bottom: 0px;
  transform: rotate(3.168deg);
}

.portrait-frame-for-armory .portrait-wrapper2-backgrounds-yellow {
  width: 61.567px;
  height: 74.544px;
  top: 1.97px;
  left: 1.97px;
  right: 2.46px;
  bottom: 1.48px;
}

.portrait-frame-for-armory .portrait-wrapper2-portrait {
  width: 57.2px;
  height: 68px;
  top: 4px;
  left: 3.92px;
  right: 4.88px;
  bottom: 6px;
}

.portrait-frame-for-armory .portrait-wrapper2-portrait-shadow {
  width: 56.642px;
  height: 22.709px;
}

.portrait-frame-for-armory .portrait-wrapper2-portrait .figma-badge2-tier-formal {
  width: 18.224px;
  height: 18.266px;
  bottom: -2.05px;
}

.portrait-frame-for-armory .portrait-wrapper2-portrait .figma-badge2-tier-formal-background>svg {
  width: 54%;
  height: auto;
}

.portrait-frame-for-armory .portrait-wrapper2-level {
  right: 2.82px;
  font-size: 10px;
}

.portrait-frame-for-armory .portrait-wrapper2-tag {
  width: 24.13px;
  height: 24.13px;
}

.portrait-frame-event .portrait-wrapper2-backgrounds-gray {
  width: 104.256px;
  height: 127.078px;
  top: 2.56px;
  left: 1.96px;
  right: 1.04px;
  bottom: 1.66px;
}

.portrait-wrapper2-backgrounds-brown {
  position: absolute;
  width: 125.019px;
  height: 150.416px;
  top: 4.22px;
  left: 4.08px;
  right: 4.89px;
  bottom: 3.36px;
  transform: rotate(3.175deg);
  flex-shrink: 0;
  background: linear-gradient(180deg, #95836a 76.92%, #746c59 100%);
}

.portrait-frame-event .portrait-wrapper2-backgrounds-brown {
  width: 106.366px;
  height: 130.327px;
  top: 0.75px;
  left: 0.01px;
  right: 0.7px;
  bottom: 0.01px;
}

.portrait-wrapper2-backgrounds-yellow {
  position: absolute;
  width: 125px;
  height: 151px;
  top: 4px;
  left: 4px;
  right: 5px;
  bottom: 3px;
  flex-shrink: 0;
  background: linear-gradient(180deg, #f7efc5 76.92%, #c0b392 100%);
  box-shadow: 0px 1px 0px 0px #fff inset;
}

.portrait-frame-event .portrait-wrapper2-backgrounds-yellow {
  width: 106.343px;
  height: 130.93px;
  top: 3.47px;
  left: 3.4px;
  right: 4.25px;
  bottom: 2.6px;
}

.portrait-wrapper2-outer {
  position: relative;
}

.portrait-wrapper2-portrait {
  position: relative;
  display: flex;
  width: 115px;
  height: 137px;
  top: 9px;
  left: 8.92px;
  right: 10.1px;
  bottom: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.portrait-frame-event .portrait-wrapper2-portrait {
  width: 97.6px;
  height: 118.6px;
  top: 8px;
  left: 7.92px;
  right: 8.48px;
  bottom: 10.4px;
}

.portrait-wrapper2-portrait-background {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #c09c65;
}

.portrait-wrapper2-portrait-background.portrait-wrapper2-portrait-background-pointman {
  background: linear-gradient(180deg, #000, #308dc1);
}

.portrait-wrapper2-portrait-background.portrait-wrapper2-portrait-background-vanguard {
  background: linear-gradient(180deg, #000, #dd9e3e);
}

.portrait-wrapper2-portrait-background.portrait-wrapper2-portrait-background-medic {
  background: linear-gradient(180deg, #000, #67ba33);
}

.portrait-wrapper2-portrait-background.portrait-wrapper2-portrait-background-sharpshooter {
  background: linear-gradient(180deg, #000, #a25cc3);
}

.portrait-wrapper2-portrait-background.portrait-wrapper2-portrait-background-breacher {
  background: linear-gradient(180deg, #000, #a7928d);
}

.portrait-wrapper2-portrait-shadow {
  position: absolute;
  width: 115px;
  height: 46px;
  bottom: 0px;
  flex-shrink: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 100%);
}

.portrait-frame-event .portrait-wrapper2-portrait-shadow {
  width: 97.836px;
  height: 39.886px;
}

.portrait-wrapper2-portrait .figma-badge2-tier-formal {
  position: absolute;
  left: 0.08px;
  bottom: -4px;
}

.portrait-frame-event .portrait-wrapper2-portrait .figma-badge2-tier-formal {
  width: 31.478px;
  height: 31.478px;
}

.portrait-wrapper2-squad {
  position: absolute;
  width: 42px;
  height: 42px;
  top: -1px;
  right: -7px;
}

.portrait-frame-event+.portrait-wrapper2-squad {
  width: 40px;
  height: 40px;
  top: -3px;
  right: -6px;
}

.portrait-wrapper2-tag {
  width: 49px;
  height: 49px;
  flex-shrink: 0;
  position: absolute;
  left: 0px;
  top: 0px;
}

.portrait-frame-event .portrait-wrapper2-tag {
  width: 41.69px;
  height: 41.69px;
}

.portrait-wrapper2-level {
  position: absolute;
  bottom: 0px;
  right: 6px;

  color: #a1eac7;
  text-align: right;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: normal;
}

.portrait-frame-event .portrait-wrapper2-level {
  font-size: 18px;
}
