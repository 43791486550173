.contract-option {
  height: 96px;
  position: relative;
  width: 703px;
}

.contract-option .frame-33 {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: inline-flex;
  height: 89px;
  left: 0;
  position: absolute;
  top: 0;
}

.contract-option .frame-34 {
  align-items: center;
  align-self: stretch;
  background-color: #f8df89;
  display: flex;

  justify-content: center;
  padding: 10px;
  position: relative;
  width: 180px;
}

.contract-option .title-19 {
  color: #3d1e0d;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.contract-option .frame-35 {
  align-items: center;
  align-self: stretch;
  display: flex;

  justify-content: center;
  padding: 10px;
  position: relative;
  width: 523px;
}

.contract-option .p {
  color: #956936;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 15px;
  font-weight: 400;
  height: 76px;
  letter-spacing: 0;
  line-height: 18.8px;
  margin-bottom: -2.5px;
  margin-top: -4.5px;
  position: relative;
  width: 495px;
}

.contract-option .vector-5 {
  height: 1px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 96px;
  width: 703px;
}
.contract-option .frame-34>* { margin-left: 10px; } .contract-option .frame-34>*:first-child { margin-left: 0; }
.contract-option .frame-35>* { margin-left: 10px; } .contract-option .frame-35>*:first-child { margin-left: 0; }
