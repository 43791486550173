.icon-checkbox {
  stroke: #625443;
}

.icon-checkbox:hover {
  stroke: #827059;
}

.icon-checkbox:active {
  stroke: #312a21;
}

.tier-and-type-frame {
  display: flex;
  align-items: center;
  gap: 4px;
}

.gear-tier {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  flex-shrink: 0;
}

.in-detail-gear-tier {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.gear-tier-background {
  position: absolute;
}

.gear-tier-frame {
  position: absolute;
  align-self: center;
}

.gear-tier1-label {
  position: absolute;
  top: 2px;

  color: #fff1a7;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.gear-tier2-label {
  position: absolute;
  top: 2px;

  color: #ecb86a;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.gear-tier3-label {
  position: absolute;
  top: 2px;

  color: #a4a7b0;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.gear-tier4-label {
  position: absolute;
  top: 2px;

  color: #a86637;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.gear-tier5-label {
  position: absolute;
  top: 2px;

  color: #7a7070;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.in-detail-gear-tier-label {
  top: auto;
  font-size: 20px;
}

.icon_discount {
  z-index: 2;

  flex-shrink: 0;
  position: absolute;
}

.figmalist-testroot {
  width: 1920px;
  height: 1080px;

  border: 5px solid black;
}

.figmalist-background-vector {
  position: absolute;
  left: -114px;
  top: -144px;
}

.figmalist-background-vector > .path {
  mix-blend-mode: overlay;
}

.figmalist-header-market {
  display: flex;
  align-items: center;
  gap: 20px;
}

.figmalist-header-subtitle-label {
  color: #c5ab78;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmalist-header-subtitle-value-label {
  color: #f2e4b5;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmalist-header-sep {
  width: 0px;
  height: 39px;

  border: 1px solid #1e160d;
}

.figmalist-header-button {
  display: flex;
  width: 238px;
  height: 34px;
  padding: 1px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 6px;
  border: 2px solid #ac8556;
  background: #987347;
  box-shadow: 0px 2px 0px 0px #7a5222;
}

.figmalist-header-button:hover {
  border-radius: 6px;
  border: 2px solid #ccb17d;
  background: #bda679;
  box-shadow: 0px 2px 0px 0px #75633f;
}

.figmalist-header-button:active {
  border-radius: 6px;
  border: 2px solid #ac8556;
  background: #987347;
  box-shadow: 0px 2px 0px 0px #7a5222;
}

.figmalist-header-button-label {
  color: #382500;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-header-button:disabled {
  border-radius: 6px;
  border: 2px solid #88744c;
  background: #796846;
  box-shadow: 0px 1px 0px 0px #75633f;
}

.figmalist-header-button:hover .figmalist-header-button-label {
  color: #644e24;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-header-button:disabled .figmalist-header-button-label {
  color: #382500;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-header-inventory {
  display: flex;
  align-items: center;
  gap: 31px;
}

.figmalist-header-title-frame {
  display: flex;
  align-items: center;
  gap: 12px;
}

.figmalist-header-title-rectangle {
  width: 3px;
  height: 26px;
  background: #6b543f;
}

.figmalist-header-title-label {
  color: #e4c89e;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-header-close {
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 53px;
  height: 35px;

  background: #9e8662;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 66px;

  margin-left: auto;
}

.figmalist-header-close:hover {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #c7af8b;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmalist-header-close:active {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #7a6442;
}

.figmalist-body {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;

  box-sizing: border-box;
  overflow: hidden;

  width: 1748px;
  max-width: 100%;
  height: 100%;
  max-height: calc(100% - 56px);
  margin-bottom: 4px;
}

.figmalist-body-inner {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.figmalist-body-header {
  display: flex;
  width: 69px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.figmalist-body-header-left {
  display: flex;
  height: 24px;
  align-items: top;
  gap: 20px;
  flex: 1 0 0;
}

.figmalist-body-header-left-types {
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 25px;
}

.figmalist-body-header-left-type {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.figmalist-body-header-left-type-frame {
  position: absolute;
}

.figmalist-body-header-left-type-title {
  position: relative;
  display: flex;
  top: 11px;
  width: 39px;
  height: 39px;
  padding: 7px;
  justify-content: center;
  align-items: center;
}

.figmalist-body-header-left-type-number {
  position: relative;
  top: 11px;
  color: #7f6053;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-header-left-type-number-hover {
  position: relative;
  top: 11px;
  color: #9a7e72;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-header-left-type-number-selected {
  position: relative;
  top: 11px;
  color: #c3a079;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-header-left-type-title-lable {
  color: #908173;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-header-right-sorts {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  align-items: center;
  gap: 25px;
}

.figmalist-body-header-right-sort {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: transparent;
  border-color: transparent;
}

.figmalist-body-header-right-sort-label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: #f2e4b5;
  text-align: center;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.64px;
}

.figmalist-body-list {
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex: 1 0 0;
  align-self: stretch;
  width: 1805px;
  overflow: auto;

  background: #000;
}

.figmalist-body-list-item {
  display: flex;
  padding: 4px 10px;
  align-items: center;
  align-self: stretch;

  border-radius: 6px;
  background: #352c22;
}

.figmalist-body-list-item-image {
  width: 200px;
  height: 80px;

  border-radius: 4px;
  background: #352c22;
}

.figmalist-body-list-item-detail {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmalist-body-list-item-detail-inform {
  width: 1274px;
  height: 72;

  display: flex;
  padding: 5px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 2px;
}

.figmalist-body-list-item-detail-inform-header {
  display: flex;
  align-items: center;
  align-self: stretch;
}

.figmalist-body-list-item-detail-inform-title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.figmalist-body-list-item-detail-inform-title-firearm {
  display: flex;
  align-items: center;
  gap: 5px;
}

.figmalist-body-list-item-detail-inform-title-firearm-ty {
  display: inline-flex;
  align-items: center;
}

.figmalist-body-list-item-detail-inform-title-firearm-ty-frame {
  display: flex;
  padding: 0px 2px;
  justify-content: center;
  align-items: center;
  gap: 5px;

  background: #dbc5a3;
}

.figmalist-body-list-item-detail-inform-title-firearm-ty-label-frame {
  display: flex;
  padding-bottom: 2px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.figmalist-body-list-item-detail-inform-title-firearm-ty-label {
  color: #5a3a29;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-list-item-detail-inform-title-label {
  color: #d3c6b2;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-list-item-detail-inform-firearm {
  display: flex;
  width: 1274px;
  height: 43px;
  padding: 2px 10px;
  align-items: center;
  gap: 5px;
  align-self: stretch;

  border-radius: 6px;
  background: #433725;

  height: 39px;
}

.figmalist-body-list-item-detail-inform-firearm-texts {
  display: flex;
  padding: 0px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.figmalist-body-list-item-detail-inform-firearm-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.figmalist-body-list-item-detail-inform-firearm-text-title {
  color: #c4a87d;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  white-space: nowrap;
}

.figmalist-body-list-item-detail-inform-firearm-text-value {
  width: 100px;

  color: #f2e4b5;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.figmalist-body-list-item-detail-inform-firearm-charts {
  width: 874px;
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
}

.figmalist-body-list-item-detail-inform-firearm-chart {
  width: 161px;
  display: flex;
  padding: 0px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmalist-body-list-item-detail-inform-firearm-chart-title {
  display: flex;
  padding: 0px 5px;
  align-items: flex-start;
  gap: 10px;
}

.figmalist-body-list-item-detail-inform-firearm-chart-title-label {
  color: #c4a87d;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}

.figmalist-body-list-item-detail-inform-firearm-chart-graph {
  display: flex;
  height: 10px;
  width: 160px;
  padding: 2px 62.8px 2px 2px;
  align-items: center;
  align-self: stretch;

  border-radius: 4px;
  background: #1f1b1b;
}

.figmalist-body-list-item-detail-inform-firearm-chart-graph-value {
  width: 96px;
  height: 6px;

  border-radius: 2px;
  background: #f5c036;
}

.figmalist-body-list-item-detail-buy {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.figmalist-body-list-item-detail-buy-price-label {
  color: #f2e4b5;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-list-item-detail-buy-price-discount {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.figmalist-body-list-item-detail-buy-price-before {
  width: 68px;
  height: 29px;
  position: relative;
  display: flex;

  justify-content: center;
  align-items: center;
}

.figmalist-body-list-item-detail-buy-price-before-label {
  position: absolute;
  z-index: 1;
  color: #88745c;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-list-item-detail-buy-btn {
  display: flex;
  width: 255px;
  height: 38px;
  padding: 13px 85px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #dfa612;
}

.figmalist-body-list-item-detail-buy-btn:hover {
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #ffd15b;
}

.figmalist-body-list-item-detail-buy-btn:active {
  border-radius: 6px;
  border: 3px solid rgba(55, 55, 55, 0.2);
  background: #a27500;
}

.figmalist-body-list-item-detail-buy-btn-label {
  color: #422f0b;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-list-item-detail-inform-title-throwable {
  display: flex;
  align-items: center;
  gap: 5px;
}

.figmalist-body-list-item-detail-inform-throwable {
  display: flex;
  width: 1274px;
  height: 43px;
  padding: 2px 10px;
  align-items: center;
  gap: 5px;

  border-radius: 6px;
  background: #433725;
}

.figmalist-body-list-item-detail-inform-throwable-texts {
  display: flex;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
}

.figmalist-body-list-item-detail-inform-throwable-text {
  color: #c4a87d;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.figmalist-body-list-item-detail-inform-throwable-text-negative {
  color: #c75242;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.figmalist-body-list-item-detail-inform-title-equipment {
  display: flex;
  align-items: center;
  gap: 5px;
}

.figmalist-body-list-item-detail-inform-equipments {
  display: flex;
  width: 1274px;
  height: 43px;
  padding: 2px 10px;
  align-items: center;
  gap: 5px;

  border-radius: 6px;
  background: #433725;
}

.figmalist-body-list-item-detail-inform-equipments-texts {
  display: flex;
  padding: 0px 10px;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.figmalist-body-list-item-detail-inform-throwable-text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.figmalist-body-list-item-detail-inform-throwable-text-label {
  color: #c4a87d;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.figmalist-body-list-item-detail-inform-throwable-text-value-label {
  width: 100px;

  color: #f2e4b5;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.figmalist-body-content {
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  width: 100%;
  height: 100%;
  gap: 4px;
}

.figmalist-body-cardboard {
  display: grid;
  grid-template-columns: 361px 361px 361px;
  position: relative;
  padding: 4px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 5px;
  flex: 1 0 0;
  flex-wrap: wrap;
  align-self: stretch;

  background: #0e0808;
  overflow-y: auto;
  scrollbar-width: none;
  border: 4px solid #39201b;
}

.figmalist-body-cardboard::-webkit-scrollbar {
  display: none;
}

.figmalist-body-cardboard-card {
  display: flex;
  width: 361px;
  height: 213px;
  padding: 2px 0px;
  flex-direction: column;
  align-items: center;
  gap: 1px;
  border: 2px solid #482e2a;
  background: #3c2421;
  position: relative;
}

.figmalist-body-cardboard-card.selected {
  border: 2px solid #ecb16b;
  outline: 2px solid #ecb16b;
  background: #3c2421;
}

.figmalist-body-cardboard-card.selected.disposal {
  border: 2px solid #ecb16b;
  outline: 2px solid #ecb16b;
  background: #3c2421;
}

.figmalist-body-cardboard-card-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
}

.figmalist-body-cardboard-card-overlay > svg {
  position: absolute;
  left: 11px;
  top: 9px;
}

.figmalist-body-cardboard-card-body {
  height: 130px;
  align-self: stretch;

  border: 1.5px solid #140b03;
  background: #2a170f;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  position: relative;
  overflow: hidden;
}

.figmalist-body-cardboard-card-item-image {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  align-self: stretch;
  align-items: center;
  justify-content: center;

  position: relative;
}

.figmalist-body-cardboard-card-item-image-rect {
  position: relative;
  bottom: 0px;
  width: 357px;
  height: 2px;

  background: #482e2a;
}

.figmalist-body-cardboard-card-item-image-background {
  position: relative;
  z-index: 0;
  width: 357px;
  height: 139px;

  background: #281c19;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25) inset;
}

.figmalist-body-cardboard-card-item-image-upper-background {
  position: absolute;
  width: 353px;
  height: 135px;
  top: 2px;

  background: linear-gradient(180deg, #583d29 0%, #472e24 100%);
}

.figmalist-body-cardboard-card-item-image-grid {
  width: 353px;
  height: 135px;
  background: linear-gradient(to top, transparent 9px, #614837 10px) 0 0 / 100vw
      10px repeat-y,
    linear-gradient(to left, transparent 9px, #614837 10px) 0 0 / 10px 100vh
      repeat-x;
}

.figmalist-body-cardboard-card-item-image > img {
  position: absolute;
  z-index: 1;
  width: 178px;
  height: 69px;
  filter: drop-shadow(5px 5px 5px #000);
}

.figmalist-body-cardboard-card-item-image-owner {
  position: absolute;
  bottom: 2px;
  left: 0;
  display: inline-flex;
  padding: 2px;
  align-items: flex-end;
  gap: 2px;
}

.figmalist-body-cardboard-card-item-image-owner-portrait {
  width: 66px;
  height: 78px;
}

.figmalist-body-cardboard-card-item-image-owner-text-frame {
  display: flex;
  padding-bottom: 2px;
  align-items: flex-end;
  gap: 2px;
}

.figmalist-body-cardboard-card-item-image-owner-text-frame > .equipped-by {
  color: #a38868;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-cardboard-card-item-image-owner-text-frame > .owner-name {
  color: #67ba33;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-gear-name-line {
  display: flex;
  height: 33px;
  padding: 0px 10px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 5px;
}

.figmalist-body-gear-name-line-vector {
  position: relative;
  width: 2px;
  height: 24px;

  background-color: #646f3e;
}

.figmasquad-body-squad-slot-inform-gear-title-label
  > .figmalist-body-gear-name-line {
  justify-content: unset;
  width: unset;
}

.figmalist-body-gear-name-line-name-frame {
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.figmalist-body-gear-name-line-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  flex: 1 0 0;

  overflow: hidden;
  color: #dbc5a3;
  text-overflow: ellipsis;
  word-break: break-all;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-cardboard-card-banner {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 71px;
  height: 62px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.figmalist-body-cardboard-card-banner-frame {
  position: absolute;
  top: -8px;
  left: -8px;
}

.figmalist-body-cardboard-card-banner > .rate {
  position: absolute;
  top: 0px;
  color: #c4db67;
  text-shadow: 0px 2px 0px #367220;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
}

.figmalist-body-cardboard-card-banner > .discount {
  position: absolute;
  top: 28px;
  color: #c4db67;
  text-shadow: 0px 2px 0px #367220;
  font-family: "Noto Sans";
  font-size: 12px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
}

.figmalist-body-cardboard-card-banner-disposal {
  position: absolute;
  left: -15.074px;
  top: 37.8px;
  width: 307.749px;
  height: 35.462px;
  transform: rotate(-5.688deg);

  z-index: 2;

  background: rgba(57, 8, 8, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  color: #d25656;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  text-shadow: 2px 2px 2px #000;
}

.figmalist-body-cardboard-card-footer {
  position: relative;
  bottom: 1.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.figmalist-body-cardboard-card-price-origin-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 27px;
}

.figmalist-body-cardboard-card-price-origin-frame > .svg {
  position: absolute;
}

.figmalist-body-cardboard-card-price-origin {
  position: relative;
  color: #99845d;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  position: relative;
}

.figmalist-body-cardboard-card-price {
  color: #e35f35;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.for-selling {
  color: #64b763 !important;
}

.figmalist-body-gear-detail {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;

  border: 2px solid #5a3b2a;
  background: #392521;
}

.figmalist-body-gear-detail-img-stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  gap: 1px;
}

.figmalist-body-gear-detail-overview {
  position: relative;
  display: flex;
  width: 554px;
  flex-direction: column;
  align-items: flex-start;
}

.figmalist-body-gear-detail-title-frame {
  display: flex;
  height: 42px;
  padding: 3px 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  background: #5a3a29;
}

.figmalist-body-gear-detail-title-frame > .inner-frame {
  display: flex;
  height: 36px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  align-self: stretch;

  border-top: 1px solid #734f3b;
  border-bottom: 1px solid #734f3b;
}

.figmalist-body-gear-detail-title {
  flex: 1 0 0;
  color: #c3a079;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-gear-detail-imagearea {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 260px;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}

.figmalist-body-gear-detail-imagearea > .rect {
  position: absolute;
  width: 554px;
  height: 260px;
  flex-shrink: 0;

  background: #472e24;
}

.figmalist-body-gear-detail-imagearea > .grid {
  position: absolute;
  width: 548px;
  height: 254px;
  background: linear-gradient(to top, transparent 9px, #614837 10px) 0 0 / 100vw
      10px repeat-y,
    linear-gradient(to left, transparent 9px, #614837 10px) 0 0 / 10px 100vh
      repeat-x;
}

.figmalist-body-gear-detail-imagearea > .ellipse {
  position: absolute;
  width: 491px;
  height: 169px;
  flex-shrink: 0;
  align-self: center;

  border-radius: 491px;
  opacity: 0.5;
  background: #765237;
  filter: blur(50px);
}

.figmalist-body-gear-detail-imagearea > .img {
  position: absolute;
  filter: drop-shadow(5px 5px 5px #000);
}

.figmalist-body-gear-detail-imagearea > .inner-rect {
  position: absolute;
  width: 548px;
  height: 254px;
  flex-shrink: 0;

  background: rgba(217, 217, 217, 0.01);
  box-shadow: 0px 0px 20px 0px #1e140a inset;
}

.figmalist-body-gear-detail-imagearea-type-tier-frame {
  position: absolute;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.type-frame {
  bottom: 39px;
  right: 32px;
}

.tier-frame {
  top: 23px;
  left: 37.5px;
}

.figmalist-body-gear-detail-imagearea-type-tier-frame > .text {
  color: #ab8e74;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-gear-detail-imagearea-type-tier-frame > .tier {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
}

.detail-image-vector {
  position: absolute;
}

.bottom-vector {
  top: 236px;
  left: 24px;
}

.left-vector {
  top: 26px;
  left: 18px;
}

.figmalist-body-gear-detail-canvas {
  display: flex;
  height: 260px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  border-top: 2px solid #745436;
  background: #2a1a0f;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.figmalist-body-gear-detail-canvas-background {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;

  border-radius: 493.532px;
  background: #61411c;
  filter: blur(60px);
}

.figmalist-body-gear-detail-canvas > img {
  z-index: 1;
  width: 80%;
  filter: drop-shadow(5px 5px 5px #000);
}

.figmalist-body-gear-detail-canvas .figmalist-body-gear-name-line {
  position: absolute;
  top: 9px;
  left: 11px;
  justify-content: flex-start;
}

.figmalist-body-gear-detail-spec {
  display: flex;
  padding: 2px 5px 0px 5px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  align-self: stretch;
}

.figmalist-body-gear-detail-spec-itemrow {
  display: flex;
  height: 28px;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.figmalist-body-gear-detail-spec-itemrow-equippable-roles {
  display: flex;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.figmalist-body-gear-detail-spec-item {
  display: flex;
  height: 28px;
  padding: 0px 5px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.figmalist-body-gear-detail-spec-item-equippable-roles {
  display: flex;
  padding: 0px 5px;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
}

.figmalist-body-gear-detail-spec-item.wide {
  grid-column: 1 / 3;
}

.figmalist-body-gear-detail-spec-item-label {
  color: #876c59;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-gear-detail-spec-item-value {
  color: #bfaa8a;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-gear-detail-spec-item-value.badges {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 2px 10px;
  flex: 1 0 0;
  flex-wrap: wrap;
}

.figmalist-body-gear-detail-spec-item-value-badges-frame {
  display: flex;
  align-items: center;
  height: 28px;
}

.figmalist-body-gear-detail-spec-item-value.badges
  > .figma-badge-role[title]::after {
  content: unset;
}

.figmalist-body-gear-detail-spec > .sep-vector {
  position: relative;
  height: 0px;
  align-self: stretch;

  border-width: 1px;
  border-color: #543934;
}

.figmalist-body-gear-detail-desc {
  display: flex;
  padding: 0px 5px 5px 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 4px;
  background: #4c3325;
}

.figmalist-body-gear-detail-desc-title {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.figmalist-body-gear-detail-desc-title > .title {
  display: flex;
  height: 28px;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;

  color: #93765b;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmalist-body-gear-detail-desc-paragraph {
  display: flex;
  padding: 10px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 4px;
  background: #2c1e16;
}

.figmalist-body-gear-detail-desc-paragraph > .parag {
  flex: 1 0 0;
  align-self: stretch;

  overflow: hidden;
  color: #93765b;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: keep-all;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
