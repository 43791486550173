.firearm-shape {
  height: 110px;
  overflow: hidden;
  width: 338px;
}

.firearm-shape .overlap-7 {
  height: 110px;
  left: -1px;
  position: relative;
  width: 340px;
}

.firearm-shape .bg {
  background-color: #f8df89;
  height: 110px;
  left: 0;
  position: absolute;
  top: 0;
  width: 340px;
}

.firearm-shape .overlap-group-4 {
  height: 101px;
  left: 1px;
  position: relative;
  top: 9px;
  width: 338px;
}

.firearm-shape .inner-rect {
  background-color: #f1d77a;
  height: 80px;
  left: 10px;
  position: absolute;
  top: 11px;
  width: 318px;
}

.firearm-shape .union {
  height: 101px;
  left: 0;
  position: absolute;
  top: 0;
  width: 338px;
}

.firearm-shape .corner-inside {
  height: 64px;
  left: 18px;
  object-fit: cover;
  position: absolute;
  top: 19px;
  width: 303px;
}

.firearm-shape .corner-outside {
  height: 92px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 5px;
  width: 330px;
}

.firearm-shape .image-firearm {
  position: absolute;
}

.firearm-shape .typlaceholder-1 {
  height: 78px;
  left: 12px;
  top: 21px;
  width: 316px;
}

.firearm-shape .tydmr-1 {
  height: 54px;
  left: 1px;
  top: 33px;
  width: 338px;
}

.firearm-shape .tysg-1 {
  height: 57px;
  left: 2px;
  top: 32px;
  width: 337px;
}

.firearm-shape .tysmg-1 {
  height: 79px;
  left: 28px;
  top: 20px;
  width: 283px;
}

.firearm-shape .tysr-1 {
  height: 75px;
  left: 1px;
  top: 22px;
  width: 338px;
}

.firearm-shape .tyhg-1 {
  height: 56px;
  left: 148px;
  top: 32px;
  width: 56px;
}

.firearm-shape .tyar-1 {
  height: 63px;
  left: 1px;
  top: 28px;
  width: 338px;
}

.firearm-shape .typlaceholder-2 {
  height: 78px;
  left: 12px;
  top: 21px;
  width: 316px;
}
