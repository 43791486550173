.grind-root h1 {
  font-size: 1.5em;
  margin: 0;
  padding: 0;

  text-transform: uppercase;
}

[title] {
  cursor: help;
}

[data-clickable] {
  cursor: pointer;
}

.grind-root .agent-thumbnail {
  height: auto;
}

.grind-inlinebox {
  display: inline-block;
  border: 1px solid #ccc;
}

.grind-agentbox1 {
  display: flex;
}

.grind-agentbox {
  width: 500px;
}

.grind-itembox {
  border: 1px solid #ccc;
  width: 250px;
}

.grind-squadbox {
  display: inline-block;
}

.grind-previewbox {
  display: inline-block;
  vertical-align: top;
}

.grind-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 1;
}

.grind-header-inline {
  display: flex;
}

.grind-body {
  padding-top: 320px;
  background-color: white;
}

.grind-agentNotification {
  display: flex;
}

.grind-market-item-detail {
  position: absolute;
  left: 100px;
  bottom: 10px;

  background-color: var(--c-popup01);
  border: 5px solid;
  /* --c-popup00 */
  border-color: #e1995e;
  z-index: 1;
  display: flex;
  text-align: center;
}

.lobby-pendings {
  position: fixed;
  top: 90px;
  bottom: 73px;
  left: 100px;
  right: 100px;
  overflow: auto;
  scrollbar-width: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grind-weekly-report {
  position: relative;
  width: 700px;
  height: 600px;
}

.grind-weekly-report-body {
  margin-left: 10px;
  margin-right: 10px;
  height: 500px;
  overflow-y: auto;
}

.grind-weekly-report-body th {
  width: 100px;
  word-break: break-all;
}

.grind-weekly-report-leftbutton {
  position: absolute;
  bottom: 10px;
  left: 150px;
}

.grind-weekly-report-rightbutton {
  position: absolute;
  bottom: 10px;
  right: 150px;
}

.grind-weekly-report-page {
  width: 100%;
  margin-top: 0.6em;
  text-align: center;
  font-size: 2em;
  font-weight: bold;
}

.grind-weekly-report-market {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.grind-ceoschedule-button {
  display: flex;
  width: 204px;
  height: 36px;
  padding: 3px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #9e8662;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.grind-ceoschedule-button:hover {
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #d1b182;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.grind-ceoschedule-button:active {
  border-radius: 4px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  background: #6c593c;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.grind-ceoschedule-button-label {
  color: #3d301c;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.grind-ceoschedule-button:disabled {
  border-radius: 4px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #4b4942;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.grind-ceoschedule-button:disabled .grind-ceoschedule-button-label {
  color: #737069;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.grind-fire-button {
  display: flex;
  width: 100px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #5c2311;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.grind-fire-button:hover {
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: #7f3a24;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.grind-fire-button:active {
  border-radius: 4px;
  border: 1px solid rgba(21, 20, 20, 0.1);
  background: #39180e;
}

.grind-fire-button-label {
  color: #9f5d48;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.grind-fire-button2 {
  display: flex;
  min-width: 61px;
  height: 26px;
  padding-bottom: 2px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  box-shadow: 0px 3px 2px 0px rgba(0, 0, 0, 0.5);
}

.grind-fire-button2-inner {
  display: flex;
  padding: 1px 10px 2px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 3px;
  border: 1.5px solid #bf5858;
  background: #b24343;
  box-shadow: 0px 2px 0px 0px #5f2121;
}

.grind-fire-button2-label {
  color: #431111;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  white-space: nowrap;
}

.grind-allschedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #291917;
  border: 2px solid #3c2421;
  padding: 8px;
  box-sizing: border-box;
}

.grind-allschedule-header {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.grind-allschedule-body {
  width: 100%;
}

.grind-allschedule-body button:hover {
  /* TODO: 일정에서 hover가 색 바뀌어서 보기 불편한 문제 우회 */
  background: #352c22;
  cursor: inherit;
}

.grind-allschedule-radios {
  display: flex;
  padding-right: 18px;
  margin-left: auto;
  gap: 25px;
}

.grind-allschedule-radios-label {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #f2e4b5;
}

.grind-allschedule .figmalist-body-list-tabs {
  width: 100%;
  justify-content: center;
}

.grind-allschedule .figmalist-body-list-tab-name,
.grind-allschedule .figmalist-body-list-row-name {
  flex: 2 0 0;
}

.grind-allschedule .figmalist-body-list-tab-etc.power,
.grind-allschedule .figmalist-body-list-row-etc.power {
  flex: 2 0 0;
}

.grind-allschedule .figmalist-body-list-tab-etc,
.grind-allschedule .figmalist-body-list-row-etc {
  flex: 1 0 0;
}

.grind-allschedule .figmalist-body-list-body {
  width: 100%;
  scrollbar-width: none;
}

.grind-allschedule .figmalist-body-list-row,
.grind-allschedule .figmalist-body-list-row-info {
  width: 100%;
  justify-content: center;
}

.grind-AgentDetail-root {
  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  background: #2b241c;
  border: 3px solid #625c4e;
  border-radius: 12px;

  user-select: none;
}

.grind-AgentDetail-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 8px 12px 8px 24px;
  width: 100%;
  height: 56px;

  background: #4e4233;
  border-radius: 12px 12px 0px 0px;
}

.grind-AgentDetail-header-inner {
  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 31px;

  width: 100%;
  height: 40px;
}

.grind-AgentDetail-header-title {
  height: 40px;

  font-style: normal;
  font-weight: 900;
  font-size: 30px;
  line-height: 40px;
  text-align: center;

  color: #f2e4b5;
}

.grind-AgentDetail-header-btn-close {
  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 53px;
  height: 35px;

  background: #9e8662;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 66px;
}

.grind-AgentDetail-header-btn-close:hover {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #c7af8b;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.grind-AgentDetail-header-btn-close:active {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #7a6442;
}

.grind-AgentDetail-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 10px;
}

.grind-agent-modifier {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.grind-agent-modifier-icon {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #ce9f75;
  background: #a97f58;

  color: #4a2e2e;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 19px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.grind-agent-modifier-good {
  color: #137850;
}

.grind-agent-modifier-bad {
  color: #bd2420;
}

.grind-agent-modifier-neutral {
  color: #bca992;
}

.grind-agent-modifier-identity {
  color: #844ed5;
}

.grind-agent-modifier-icon.grind-agent-modifier-good {
  background: #032913;
}

.grind-agent-modifier-icon.grind-agent-modifier-bad {
  background: #380200;
}

.grind-agent-modifier-icon.grind-agent-modifier-neutral {
  background: #372816;
}

.grind-agent-modifier-icon.grind-agent-modifier-identity {
  background: #220d47;
}

.grind-agent-modifier-tooltip-content {
  display: flex;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}

.grind-agent-modifier-tooltip-icon {
  width: 54px;
  height: 54px;
  position: relative;
}

.grind-agent-modifier-tooltip-icon>svg {
  position: absolute;
}

.grind-agent-modifier-tooltip-icon-inner {
  width: 54px;
  height: 54px;
  display: inline-flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.grind-agent-modifier-tooltip-desc {
  display: flex;
  padding: 0px 10px 4px 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex: 1 0 0;
  align-self: stretch;
}

.grind-unablewarning {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  justify-content: center;
  background: #000;
  position: fixed;
  display: flex;
  width: 100%;
  height: 103px;
  align-items: flex-start;
  gap: 1px;
  flex-shrink: 0;
}

.grind-unablewarning-label {
  justify-content: center;
  flex-shrink: 0;

  color: #d5ccb6;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 110% */
}

.grind-missionReport-header {
  width: 100%;
  position: relative;
}

.grind-missionReport-header-btn-close {
  /* 닫기 버튼 */
  box-sizing: border-box;

  position: absolute;
  right: 5px;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 53px;
  height: 35px;

  background: #9e8662;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 66px;

  /* Inside auto layout */
  /*
flex: none;
flex-grow: 0;
*/

  margin-left: auto;
}

.agentPerkAcquire {
  gap: 10px;
  display: flex;
  padding: 10px 12px;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  position: relative;
}

.agentPerkAcquire-detail {
  background: #2b241c;
  border: 3px solid #625c4e;
  border-radius: 12px;
}

.figmalist-root.figmapopup-perks-acquire {
  display: flex;
  width: 1301px;
  height: 959px;
  left: calc(50% - 650px);
  top: calc(50% - 479px);
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
}

.figmalist-root.figmapopup-perks-acquire .figmalist-body {
  display: flex;
  padding: 0px 15px;
  align-items: center;
  gap: 20px;
  flex: 1 0 0;
  align-self: stretch;
  flex-direction: unset;
  width: unset;
}

.figmapopup-perks-acquire .figmalist-body-detail {
  display: flex;
  width: 600px;
  padding: 15px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.figmapopup-perks-acquire-right {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.figmapopup-perks-acquire-desc {
  display: flex;
  padding: 40px 0px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  color: #887756;
  text-align: center;
  font-family: "Roboto Slab";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 110% */
}

.figmapopup-perks-acquire-list {
  display: flex;
  padding: 10px 8px;
  flex-direction: column;
  align-items: center;
  gap: 14px;
  flex: 1 0 0;
  align-self: stretch;

  border-radius: 10px;
  border: 1px solid #9e7c66;
}

.figmalist-reroll-button {
  width: 379px;
  height: 54px;
  flex-shrink: 0;

  position: relative;

  border-radius: 7px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
}

.figmalist-reroll-button:hover,
.figmalist-reroll-button:active {
  cursor: pointer;
}

.figmalist-reroll-button-emboss {
  width: 379px;
  height: 51px;
  flex-shrink: 0;

  border-radius: 7px;
  background: #581d15;

  position: absolute;
  top: 3px;
}

.figmalist-reroll-button-background {
  width: 379px;
  height: 51px;
  flex-shrink: 0;

  border-radius: 7px;
  border: 1.4px solid #923c30;
  background: #6f241a;

  position: absolute;
  top: 0px;
}

.figmalist-reroll-button-outline {
  width: 373px;
  height: 45px;
  flex-shrink: 0;

  border-radius: 4px;
  border: 2px solid #3a0606;

  position: absolute;
  top: 3px;
  left: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.figmalist-reroll-button-label {
  color: #ffbd75;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;

  position: relative;

  display: flex;
  align-items: center;
  gap: 5px;
}

.figmalist-reroll-button:hover .figmalist-reroll-button-emboss {
  background: #872619;
}

.figmalist-reroll-button:hover .figmalist-reroll-button-background {
  border: 1.4px solid #923c30;
  background: #9b382b;
}

.figmalist-reroll-button:hover .figmalist-reroll-button-outline {
  border: 2px solid #85291c;
}

.figmalist-reroll-button:hover .figmalist-reroll-button-label {
  color: #ffd2a1;
}

.figmalist-reroll-button:active .figmalist-reroll-button-emboss {
  background: #581d15;
}

.figmalist-reroll-button:active .figmalist-reroll-button-background {
  border: 1.4px solid #782b21;
  background: #551b13;

  top: 2px;
}

.figmalist-reroll-button:active .figmalist-reroll-button-outline {
  border: 2px solid #3a0606;

  top: 5px;
}

.figmalist-reroll-button:active .figmalist-reroll-button-label {
  color: #ffd2a1;
}

.figmalist-reroll-button.disabled {
  cursor: unset;
}

.figmalist-reroll-button.disabled .figmalist-reroll-button-emboss {
  background: #424242 !important;
}

.figmalist-reroll-button.disabled .figmalist-reroll-button-background {
  border: 1.4px solid #555 !important;
  background: #474747 !important;

  top: 0px !important;
}

.figmalist-reroll-button.disabled .figmalist-reroll-button-outline {
  border: 2px solid #333 !important;

  top: 3px !important;
}

.figmalist-reroll-button.disabled .figmalist-reroll-button-label {
  color: #777 !important;
}

.agent-perks-acquire-item {
  display: flex;
  width: 630px;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;

  border-radius: 6px;
  border: 3px solid #553923;
  background: #432c1a;
}

.agent-perks-acquire-item-header {
  display: flex;
  width: 100%;
  padding: 0px 5px;
  height: 46px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: #352512;
  color: #f2e4b5;
  text-align: center;
  font-family: "Roboto Slab";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agent-perks-acquire-item-tags {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.agent-perks-acquire-item-tag {
  color: #6d5130;
  text-align: center;
  font-family: "Roboto Slab";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agent-perks-acquire-item-body {
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;

  color: #a98744;
  text-align: center;
  font-family: "Roboto Slab";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 110% */
}

.agent-perks-acquire-perk-body-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
}

.agent-perks-acquire-item-body .figmalist-body-detail-perk-button {
  flex-grow: 0;
}

.agentPerkInfo-body {
  display: flex;
  padding: 5px 5px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  overflow: auto;
  height: 66px;
}

.agentPerkInfo-body-detail-label {
  color: #3a2c10;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.agentPerkInfo-body-detail-descr {
  color: #3a2c10;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.flex-config-cta {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 10px;
}

.overlay-week-transition {
  display: flex;
  background: black;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
}

.overlay-week-transition-content {
  font-size: 8em;
  font-weight: 900;

  opacity: 0;
  animation: fadeInOut 3s;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }

  66% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.milestone-notice-root {
  width: 100%;
}

.milestone-notice-root>.lobby2-clipboard-section-border h1 {
  font-size: 1.8em;
  font-weight: 900;
  margin-bottom: 0.5em;

  /* shadow */
  text-shadow: 2px 2px 4px gray;
}

.milestone-notice-root>.lobby2-clipboard-section-border h2 {
  font-size: 1.2em;
  font-weight: 900;
  margin-top: 0.4em;
  margin-bottom: 0.2em;
}

.enemy-badge {
  background: lightgray;
}

.enemy-badge-tier:before {
  content: 'T';
}

.enemy-badge-role {
  text-transform: uppercase;
}
