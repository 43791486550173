
.outloop-root {
  background: white;
  width: 100%;
  height: 100%;
}

.outloop-root h1 {
  font-size: 1.5em;
  font-weight: bold;
}

.outloop-root h2 {
  font-size: 1.2em;
  font-weight: bold;
}
