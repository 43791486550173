
.research-root table {
  background-color: white;
}

.research-root tr {
  color: black;
}

.research-root td {
  border: 1px solid gray;
  min-width: 10em;
}

.research-color {
  font-weight: bold;
}
.research-color-locked {
  color: lightgray;
}
.research-color-active{
  color: green;
}
.research-color-available {
  color: purple;
}
.research-color-inprogress {
  color: blue;
}
.research-color-invalid {
  color: darkgray;
}

.research-rel-self {
  background: #bbb;
}
.research-rel-child {
  background: #bbf;
}
.research-rel-parent {
  background: #bfb;
}
.research-rel-exclusive {
  background: #fbb;
}

.research-reward {
  font-style: italic;
}

.research-effect-title {
  font-weight: bold;
}

.research-table-empty {
  border: none !important;
}
