.perktooltip-SI {
  height: 154px;
  width: 398px;
}

.perktooltip-SI .overlap-13 {
  height: 154px;
  position: relative;
}

.perktooltip-SI .div-4 {
  height: 154px;
  left: 0;
  position: absolute;
  top: 0;
  width: 398px;
}

.perktooltip-SI .subtract-4 {
  height: 149px;
  left: 6px;
  position: absolute;
  top: 7px;
  width: 393px;
}

.perktooltip-SI .overlap-group-12 {
  background-image: url(../../../static/anima/img/subtract-1.svg);
  background-size: 100% 100%;
  height: 148px;
  left: 3px;
  position: relative;
  top: 3px;
  width: 392px;
}

.perktooltip-SI .rectangle-5 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.perktooltip-SI .rectangle-6 {
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.perktooltip-SI .subtract-5 {
  height: 142px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 386px;
}

.perktooltip-SI .tooltipcontent {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  display: flex;

  height: 148px;
  left: 3px;
  padding: 20px;
  position: absolute;
  top: 3px;
  width: 392px;
}

.perktooltip-SI .frame-36 {
  align-items: /* PMC */ flex-start; align-content: flex-start;
  align-self: stretch;
  display: inline-flex;
  flex: 0 0 auto;

  position: relative;
}

.perktooltip-SI .perkimg-sample-wrapper {
  background-color: #00000080;
  border-radius: 1px;
  height: 56px;
  position: relative;
  width: 56px;
}

.perktooltip-SI .perkimg-sample {
  height: 48px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 48px;
}

.perktooltip-SI .tooltip-text {
  height: 108px;
  position: relative;
  width: 283px;
}

.perktooltip-SI .pt-desc-2 {
  color: #82ccb6;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 19.2px;
  position: absolute;
  top: 29px;
  width: 292px;
}

.perktooltip-SI .pt-title-2 {
  color: #baf1dd;
  font-family: "The Jamsil OTF", Helvetica;
  font-size: 16px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  top: -1px;
  width: 283px;
}

.perktooltip-SI .style-dummy-2 {
  color: #00000003;
  font-family: "Inter", Helvetica;
  font-size: 1px;
  font-weight: 400;
  left: 8px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 7px;
  width: 4px;
}
.perktooltip-SI .frame-36>* { margin-left: 10px; } .perktooltip-SI .frame-36>*:first-child { margin-left: 0; }
.perktooltip-SI .tooltipcontent>* { margin-left: 13px; } .perktooltip-SI .tooltipcontent>*:first-child { margin-left: 0; }
