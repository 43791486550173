.progress {
  display: inline-block;
  text-align: center;
  margin: 2px 5px;
}

.progress-text {
  vertical-align: middle;
  left: 0;
  right: 0;
  position: absolute;
  color: var(--c-fh1-progressbar-fg);
  text-shadow:
    -1px -1px 0 black,
    1px -1px 0 black,
    -1px 1px 0 black,
    1px 1px 0 black;
}

.progress {
  background-color: var(--c-fh1-progressbar-empty-bg);
  /*
  border: 1px solid var(--c-fh1-progressbar-empty-border);
  outline: 1px solid var(--c-fh1-progressbar-empty-outline);
  */
}

.progress .value {
  background-color: var(--c-fh1-progressbar-value-bg);
}

.progress .growth {
  background-color: var(--c-fh1-progressbar-growth-bg);
}

.progress.stat .cap {
  background-color: var(--c-fh1-progressbar-cap-bg);
}

.progress.forces .value {
  background-color: var(--c-fh1-forces-bg);
}

.progress.forces.owner .value {
  background-color: var(--c-fh1-forces-owner-bg);
}

.progress.forces .cap {
  background-color: var(--c-fh1-forces-cap-bg);
}

.progress.departmentstaffstat .cap {
  background-color: var(--c-fh1-progressbar-cap-bg);
}

.progress.better .growth {
  background-color: var(--c-fh1-progressbar-better-bg);
}

.progress.worse .growth {
  background-color: var(--c-fh1-progressbar-worse-bg);
}

.progress.overlay-progress {
  background-color: var(--c-fh1-flexoverlay-progressbar-empty-bg);
  outline: 2px solid var(--c-fh1-flexoverlay-progressbar-empty-border);
}

.progress.overlay-progress .value {
  background-color: var(--c-fh1-flexoverlay-progressbar-value-bg);
}

.progress.overlay-progress .progress-text {
  color: var(--c-fh1-flexoverlay-progressbar-fg);
}