.root body {
  /*
  background-image: url('../public/figma-dummy/background.png');
  */
}

.root {
  box-sizing: border-box;
  font-family: 'The Jamsil OTF';
  background: transparent;
}

.flexr {
  display: flex;
  flex-direction: row;
}

.flexc {
  display: flex;
  flex-direction: column;
}

.grow1 {
  flex-grow: 1;
}

.upcoming {
  position: absolute;
  width: 17.7rem;

  /* border: 1px solid red; */
  left: 24.70rem;
  top: 20.14rem;
}

.upcoming>div {
  margin-top: .3rem;
  border-radius: .3rem;
}

.upcoming .upcoming-icon-up {
  display: inline-block;
  background-image: url('../public/figma-dummy/upcoming-arrow-up.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.4rem;
  height: 1.1rem;
  vertical-align: middle;
}

.upcoming .upcoming-icon-down {
  display: inline-block;
  background-image: url('../public/figma-dummy/upcoming-arrow-down.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.4rem;
  height: 1.1rem;
  vertical-align: middle;
}

.upcoming .upcoming-icon-right {
  display: inline-block;
  background-image: url('../public/figma-dummy/upcoming-arrow-right.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.4rem;
  height: 1.1rem;
  vertical-align: middle;
}

.upcoming .upcoming-icon-item-checked {
  display: inline-block;
  background-image: url('../public/figma-dummy/upcoming-item-checked.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.6rem;
  height: 1.9rem;
  margin: 0 .3rem 0 .5rem;
}

.upcoming .upcoming-icon-item-unchecked {
  display: inline-block;
  background-image: url('../public/figma-dummy/upcoming-item-unchecked.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.6rem;
  height: 1.9rem;
  margin: 0 .3rem 0 .5rem;
}

.upcoming .upcoming-icon-delete {
  display: inline-block;
  background-image: url('../public/figma-dummy/upcoming-item-delete.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.6rem;
  height: 1.7rem;
  margin: .15rem .5rem 0 0;
}

.upcoming .upcoming-icon-agent {
  display: inline-block;
  background-image: url('../public/figma-dummy/upcoming-agent.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.1rem;
  height: 1.1rem;
}

.upcoming .upcoming-icon-mission {
  display: inline-block;
  background-image: url('../public/figma-dummy/upcoming-mission.png');
  background-size: 0.96rem;
  background-repeat: no-repeat;
  background-position: -.02rem -.02rem;
  width: 1.1rem;
  height: 1.1rem;
}

.upcoming .upcoming-icon-check {
  display: inline-block;
  background-image: url('../public/figma-dummy/upcoming-check-green.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 1.1rem;
  height: 1.1rem;
}


.upcoming .upcoming-milestone {
  background: linear-gradient(180deg, #DFBA35 0%, #917D37 100%);
}

.upcoming .upcoming-milestone.unlocked {
  background: #6D0000;
  padding: .11rem;
}

.upcoming .upcoming-milestone.unlocked p.warning {
  color: #FF2D2D;
  font-size: .75rem;
  text-decoration: underline;
  padding: .07rem 0 .23rem .9rem;
}

.upcoming .upcoming-milestone .upcoming-milestone-inner {
  background: linear-gradient(180deg, #DFBA35 0%, #917D37 100%);
  padding: .125rem;
  border-radius: .3rem;
}

.upcoming .upcoming-milestone.unlocked .upcoming-milestone-warning-container {
  position: relative;
  width: 0;
  height: 0;
}

.upcoming .upcoming-milestone.unlocked .upcoming-milestone-warning {
  background-image: url('../public/figma-dummy/upcoming-milestone-exclamation.svg');
  background-position: center;
  width: 5.5rem;
  height: 5.5rem;
  position: absolute;
  top: -2.13rem;
  left: -3.15rem;
}

.upcoming .upcoming-milestone-well {
  background: #796225;
  margin: 0;
  border-radius: .15rem;
  box-shadow: inset 0 0 .2rem #00000099;
  padding: .18rem .3rem .1rem;
}

.upcoming .upcoming-milestone-icon-map {
  background-image: url('../public/figma-dummy/upcoming-milestone-map.svg');
  background-position: center;
  background-repeat: no-repeat;
  width: 2.79rem;
  height: 3.2rem;
}

.upcoming .upcoming-milestone-icon-locked {
  background-repeat: no-repeat;
  background-image: url('../public/figma-dummy/upcoming-milestone-locked.svg');
  background-position: -.7rem -.6rem;
  background-position: center;
  background-repeat: no-repeat;
  width: 1.7rem;
  height: 1.3rem;
  display: inline-block;
  vertical-align: top;
}

.upcoming .upcoming-milestone-icon-unlocked {
  background-repeat: no-repeat;
  background-image: url('../public/figma-dummy/upcoming-milestone-unlocked.svg');
  background-position: .1rem 0;
  background-position: center;
  width: 1.7rem;
  height: 1.3rem;
  display: inline-block;
  vertical-align: top;
}

.upcoming .upcoming-milestone-well a {
  color: #FFD600;
  font-size: .75rem;
  text-decoration: underline;
  margin-left: -.1rem;
}

.upcoming .upcoming-milestone-well a strong {
  color: #FFF9C3;
}

.upcoming .upcoming-milestone.locked .upcoming-milestone-well a {
  color: #CCCCCC;
}

.upcoming .upcoming-milestone-well-detail-left {
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  padding: .1rem .2rem 0;
}

.upcoming .upcoming-milestone-well-detail-divider {
  width: .07rem;
  background: linear-gradient(0deg,
      #B7A57500 0%,
      #B7A57500 10%,
      #B7A575FF 10%,
      #B7A575FF 90%,
      #B7A57500 90%,
      #B7A57500 100%);
}

.upcoming .upcoming-milestone-well-detail-reward {
  flex-grow: 5;

  display: flex;
  flex-direction: column;

  padding: .1rem .2rem .1rem .5rem;
}

.upcoming .upcoming-milestone-well h2 {
  color: #e1e1e1;
  font-size: 0.89em;
  padding: 0 0 0 .12rem;
}

.upcoming .upcoming-milestone-well-detail-left h2 {
  margin-left: .5rem;
}

.upcoming .upcoming-milestone-well time {
  font-size: 0.91em;
  color: #ebebeb;
  background: #0000004D;
  border-radius: 0.6rem;
  padding: .06rem .5rem 0;
  margin: 0 .15rem;
}

.upcoming .upcoming-milestone-well .reward-amount {
  font-size: 0.91em;
  color: #F4D42A;
  background: #0000004D;
  border-radius: 0.6rem;
  padding: .06rem .5rem 0;
}


.upcoming .upcoming-milestone-well-progress {
  flex-grow: 1;
  background: #0000009A;
  border-radius: 0.2rem;
  padding: .2rem;
  margin: .1rem -.15rem;
  text-align: center;
}

.upcoming .upcoming-milestone-well-progress h2 {
  color: #B1ADA1;
  padding: .08rem .2rem 0 0;
}

.upcoming .upcoming-milestone-well-progress-bar {
  background: linear-gradient(90deg,
      #00BBB0 0%,
      #00BBB0 60%,
      #1BFFF1FF 80%,
      #1BFFF100 80%,
      #1BFFF100 100%);
  border: 0.1rem solid #6A5D3C;
  border-radius: 0.7rem;
  font-size: 0.72rem;
  color: #FFFFFF;
  text-shadow: 0px 0px 2px #000000;
  box-shadow: inset 0 0 .1rem rgba(0, 0, 0, 1);
  line-height: 104%;
  padding: 0;
  margin: 0 .2rem .3rem;
}

.upcoming .upcoming-milestone-description {
  padding: .3rem .3rem 0;
  color: #F3E5C0;
  font-size: 0.75rem;
  line-height: 130%;
}

.upcoming .upcoming-milestone-summary-toggle {
  color: #DAC07F;
  font-size: .75rem;
}


.upcoming .upcoming-item {
  background: #354318;
  border: .15rem solid #223417;
  padding: .2rem .09rem .1rem;
  font-size: .76rem;
  line-height: 128%;
}

/* <코너 점박이> */
/* https://stackoverflow.com/questions/20964278 */
.upcoming-item-inner {
  position: relative;
}

.upcoming-item-inner:before,
.upcoming-item-inner:after {
  content: "";
  position: absolute;
  height: 100%;
  width: .3rem;
  top: 0px;
  background-image:
    radial-gradient(circle at center, #586435 .13rem, transparent .13rem),
    radial-gradient(circle at center, #586435 .13rem, transparent .13rem);
  background-size: .17rem .17rem;
  background-position: top center, bottom center;
  background-repeat: no-repeat;
}

.upcoming-item-inner:before {
  left: 0px;
}

.upcoming-item-inner:after {
  right: 0px;
}

/* </코너 점박이> */

.upcoming .upcoming-item-content {
  flex-grow: 1;
}

.upcoming .upcoming-item-content a {
  text-decoration: underline;
}

.upcoming .upcoming-item-coming {
  color: #FFF9C3;
}

.upcoming .upcoming-item-coming a {
  color: #FDFBF4;
}

.upcoming .upcoming-item-arrived {
  color: #CCFF00;
}

.upcoming .upcoming-item-arrived a {
  color: #8BA937;
}

.upcoming .upcoming-item i:first-child {
  margin-top: .11rem;
}

.upcoming .upcoming-title {
  padding: 0;
}

.upcoming .upcoming-title.collapsed {
  padding: 0 0 .14rem;
}

.upcoming .upcoming-title h1 {
  background: linear-gradient(180deg, #FFFFFF 0%, #C3C9BA 100%);
  filter: drop-shadow(1px 1px #00000080);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  padding: 0.14rem 0.23rem 0 0.18rem;
  font-size: 0.87rem;
}

.upcoming .upcoming-title h1 i {
  margin-right: 0.1rem;
  vertical-align: middle;
}

.upcoming .upcoming-title h2 {
  color: #00DDB5;
  margin: 0.17rem 0.8rem 0 0;
  font-size: 0.78rem;
}

.upcoming .upcoming-title h2 strong {
  text-decoration: underline;
}

.upcoming .upcoming-training {
  background: #07444D;
  border: .09rem solid #1A5360;
}

.upcoming .upcoming-mission {
  background: #5B2D0B;
  border: .09rem solid #67412B;
}

.upcoming .upcoming-list-container {
  background: #142225;
  padding: 0.2rem 0.14rem;
}

.upcoming .upcoming-list {
  background: #E9E2A7;
  height: 8.4rem;
  overflow-y: scroll;
  overflow-x: hidden;
  border: .09rem solid #A8A05C;
  border-radius: 0.2rem;
  box-shadow: inset 0px 2px 3px rgba(0, 0, 0, 0.25);
  scrollbar-color: #AD9F57 #D7CA86;
}

.upcoming .upcoming-list::-webkit-scrollbar-track {
  background: #D7CA86;
}

.upcoming .upcoming-list::-webkit-scrollbar-thumb {
  background: #AD9F57;
}

.upcoming .upcoming-list li {
  list-style: none;
  border: 0.08rem dashed #CCBD89;
  border-width: 0 0 .08rem 0;
  margin: 0.3rem;
  font-size: 0.75rem;
  color: #3E3518;
}

.upcoming .upcoming-list li i:first-child {
  margin: 0.2rem 0.14rem 0.2rem 0.02rem;
  vertical-align: middle;
}

.upcoming .upcoming-list li i:not(:first-child) {
  margin: 0.2rem 0.44rem 0.2rem 0;
}

.upcoming .upcoming-list li time {
  font-size: 0.73rem;
  margin: 0.1rem 0.25rem 0 0;
  color: #272316;
  font-weight: 500;
}
