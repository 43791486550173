@font-face { font-family: "Freshman"; src: url(/public/fonts/Freshman.ttf) format("truetype"); }
@font-face { font-family: "The Jamsil OTF"; src: url(/public/fonts/jamsil1.ttf) format("opentype"); font-weight: 100; }
@font-face { font-family: "The Jamsil OTF"; src: url(/public/fonts/jamsil3.ttf) format("opentype"); font-weight: normal; }
@font-face { font-family: "The Jamsil OTF"; src: url(/public/fonts/jamsil4.ttf) format("opentype"); font-weight: medium; }
@font-face { font-family: "The Jamsil OTF"; src: url(/public/fonts/jamsil5.ttf) format("opentype"); font-weight: 700; }
@font-face { font-family: "The Jamsil OTF"; src: url(/public/fonts/jamsil6.ttf) format("opentype"); font-weight: 800; }

@font-face { font-family: "Noto Sans"; src: url("/public/fonts/NotoSans-VariableFont_wdth,wght.ttf"); }
@font-face { font-family: "Noto Sans CJK"; src: url("/public/fonts/NotoSansKR-Bold.ttf"); font-weight: 700; }
@font-face { font-family: "Roboto Slab"; src: url("/public/fonts/RobotoSlab-VariableFont_wght.ttf"); }

/* TitleView */
@font-face { font-family: "Antonio"; src: url(/public/fonts/Antonio-VariableFont_wght.ttf) format("truetype"); font-weight: 700; }

/* 필기체 */
@font-face { font-family: "나눔손글씨"; src: url("/public/fonts/나눔손글씨 강부장님체.ttf"); }
@font-face {
  font-family: "Caveat";
  src: url("/public/fonts/Caveat-VariableFont_wght.ttf") format("truetype");
  font-weight: 700;
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+0020-002F,
  U+003A-0040, U+005B-0060, U+007B-007E;
}


html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --c-title00: #e9e1c0;
  --c-title01: #ffb400;
  --c-title02: #ff6000;
  --c-title03: #ef5011;

  --c-title10: #f2b75f;
  --c-title11: #e98b3f;

  --c-grid00: #1c1b18;
  --c-grid01: #282421;
  --c-grid02: #4a4a4a;
  --c-grid03: #35414b;
  --c-grid04: #22524a;

  --c-popup00: #e1995e;
  --c-popup01: #f0edd2;

  --c-highlight00: #fc4c07;
  --c-highlight01: #2fe9bf;
  --c-highlight02: #f600ff;
  --c-highlight03: #2def84;

  --c-bg00: #7F7F7F;

  --c-gray00: #373737;
  --c-red00: #742b11;

  --figmalist-tabitem-width: 80px;
}

body {
  margin: 0;
  font-size: 12px;
}

code {
  font-family: monospace;
}

strong {
  font-weight: bolder;
  text-decoration: underline;
}

td,
th {
  border: 1px solid;
  padding: 0.25em;
}

.flex-none {
  flex: none !important;
}


