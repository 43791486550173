.dialogview {
  width: 100%;
  height: 400px;
  position: absolute;
  bottom: 0px;

  background: linear-gradient(
    180deg,
    rgba(61, 32, 5, 0) 0%,
    rgba(61, 32, 5, 0.6) 60%,
    rgba(61, 32, 5, 0.9) 90%,
    rgba(61, 32, 5, 1) 100%
  ) !important;
  background-blend-mode: overlay;

  display: flex;
  padding: 0px 40px;
  align-items: flex-end;

  user-select: none;
}

.dialogview-svg {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.dialogview-stripe-pattern {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 351px;
  background: linear-gradient(
      to top,
      transparent 7px,
      rgba(189, 134, 117, 0.5) 10px
    )
    0 0 / 100vw 10px repeat-y;
}

.dialogview-rectangle {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 360px;
  flex-shrink: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    #000 50.5%,
    #000 100%
  );
}

.dialogview-anidisplay {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  width: 1920px;
  height: 461px;
  padding-top: 470px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  overflow: hidden;

  background: linear-gradient(
    rgba(192, 115, 43, 0) 0%,
    rgba(192, 115, 43, 1) 100%,
    rgba(192, 115, 43, 1) 100%,
    rgba(192, 115, 43, 0) 0%
  );
}

.dialogview-vector {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 5px;
  background: linear-gradient(
    90deg,
    rgba(192, 115, 43, 0) 0%,
    #c0732b 25%,
    #c0732b 74.5%,
    rgba(192, 115, 43, 0) 100%
  );
  opacity: 0.4;
  filter: blur(4px);
  animation: slide-up 6s linear infinite;
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
    opacity: 0.4;
  }
  100% {
    transform: translateY(-350px);
    opacity: 0;
  }
}

.svgicon-container {
  width: 140px;
  height: 169px;
  position: relative;
}

.svg-ellipse {
  width: 99px;
  height: 59px;
  left: 20.5px;
  top: 44.91px;
  flex-shrink: 0;
  position: absolute;

  border-radius: 99px;
  background: #5e380b;
  filter: blur(35px);
}

.svgicon {
  position: absolute;
  top: 59.5px;
  left: 43px;
  width: 54px;
  height: 50px;
}

.svgicon-default {
  position: absolute;
  top: 59.5px;
  left: 53px;
  width: 34px;
  height: 29.822px;
  filter: drop-shadow(0px 10px 7px #321005);

  transition: opacity 400ms;

  animation: showSvgIcon 400ms ease-out infinite alternate;
}

@keyframes showSvgIcon {
  0% {
    margin-top: 10px;
    opacity: 0.9;
    filter: drop-shadow(0px 3px 5px #321005);
  }
  100% {
    margin-top: 0px;
    opacity: 1;
    filter: drop-shadow(0px 10px 5px #321005);
  }
}

.dialogview:active {
  background: linear-gradient(
    0deg,
    #2e1a0f 0%,
    rgba(20, 11, 4, 0.97) 66%,
    rgba(0, 0, 0, 0) 100%
  );
}

.dialogview-portrait {
  position: relative;
  width: 336px;
  height: 400px;
}

.dialogview-content {
  display: flex;
  position: relative;
  height: 260px;
  padding: 30px 0px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  margin-left: 30px;
  overflow: hidden;
}

.dialogview-glow {
  position: absolute;
  left: 386px;
  bottom: 43px;
  width: 1148px;
  height: 174px;
  flex-shrink: 0;
  border-radius: 1148px;
  background-color: rgba(135, 75, 42, 1);
  filter: blur(50px);
}

.dialogview-name {
  position: relative;
  display: flex;
  padding: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  font-family: "Noto Sans", "Noto Sans CJK";
  color: rgba(248, 208, 148, 1);
  text-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  font-size: 40px;
  line-height: normal;
}

.dialogview-body {
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  margin-top: 10px;
}

.dialogview-autocontainer {
  position: fixed;
  right: 6em;
  bottom: 3em;
}

.dialogtextview {
  display: flex;
  padding: 10px 20px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;

  font-family: "Noto Sans", "Noto Sans CJK";
  color: rgba(255, 201, 98, 1);
  text-shadow: 0px 3px 0px rgba(87, 51, 9, 0.7);
  font-size: 28px;
  font-weight: 700;
  line-height: normal;
}

.dialogtextview-wrapper {
  display: flex;
  align-items: flex-start;
  padding: 10px 10px;
  flex: 1 0 0;
  align-self: stretch;

  position: relative;
}

.dialogtextview-base {
  margin-left: 20px;
  white-space: pre-wrap;
}

.quote {
  font-family: "Podkova", serif;
  font-size: 70px;
  font-style: normal;
  color: rgba(194, 125, 22, 1);
  align-items: flex-start;
  margin-top: -15px;
}

.quote-right {
  font-family: "Podkova", serif;
  font-size: 70px;
  font-style: normal;
  color: rgba(194, 125, 22, 1);
  align-items: flex-start;
  margin-top: -15px;
  margin-left: 20px;
}

.quote-right.fixed {
  position: absolute;
  align-self: flex-start;
  margin-left: 20px;
  top: -15px;
}

.dialogview2 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  /* center child */
  justify-content: center;
  align-items: center;

  background-color: black;

  user-select: none;
}

.dialogview2-text {
  position: fixed;
  bottom: 150px;
  left: 100px;
  right: 100px;
  height: 100px;

  background: rgba(128, 128, 128, 0.5);

  display: flex;
  /* center child */
  justify-content: center;
  align-items: center;
}

.dialogview2-text-inner {
  color: white;
  font-size: 2em;
}
