.card-root {
  overflow: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.card-root-letterbox-upper {
  width: 100%;
  height: 15%;
  background-color: black;
  z-index: 1;

}

.card-root-letterbox-contents {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70%;
  background-color: black;
}

.card-root-letterbox-lower {
  width: 100%;
  height: 15%;
  background-color: black;
  z-index: 1;
}

.card-root-letterbox-contents-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
}

.card-root-letterbox-contents-container-image-base,
.card-root-letterbox-contents-container-image-multiply,
.card-root-letterbox-contents-container-image-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: none;
  pointer-events: none;
  user-select: none;
  will-change: transform;
  backface-visibility: hidden;
}


@keyframes rotate-smooth {
  0%, 100% {
    top: -7%;
    left: 7%;

    transform: translate(0%, -9%) scale(1.1) rotate(0deg);
    animation-timing-function: ease-in-out;
  }


  50% {
    top: -7%;
    left: 7%;

    transform: translate(0%, -9%) scale(1.1) rotate(-0.3deg);
    animation-timing-function: ease-in-out;
  }

}


.card-root-letterbox-contents-container-image-multiply,
.card-root-letterbox-contents-container-image-overlay {
  transform-origin: top right;

  animation: rotate-smooth 1s infinite;

}

.card-root-letterbox-contents-container-image-multiply {
  mix-blend-mode: multiply;
}

.card-root-letterbox-contents-container-image-overlay {
  mix-blend-mode: overlay;
}

.card-root-letterbox-contents-container::before,
.card-root-letterbox-contents-container::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 40%;
  pointer-events: none;
  z-index: 1;
  backdrop-filter: blur(20px);
  mask-image: linear-gradient(to bottom, black, transparent);
}

.card-root-letterbox-contents-container::after {
  bottom: 0;
  mask-image: linear-gradient(to top, black, transparent);
}

.card-root-letterbox-contents-container::before {
  top: 0;
  mask-image: linear-gradient(to bottom, black, transparent);
}

@keyframes moveGradient {
  0% {
    background-position: 200% 0%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.input-container {
  position: relative;
  width: 500px;
  height: 100px;
}

.highlighted-textarea {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  text-align: center;
  width: 100%;
  height: 100%;
  animation: moveGradient 3s linear infinite;
  color: transparent;
  font-size: 15px;
  font-weight: bold;
  border: none;
  outline: none;
  box-sizing: border-box;
  font-family: 'Noto Sans';
  resize: none;
  text-transform: uppercase;
  letter-spacing: 3pt;
}

.highlighted-underline {
  position: absolute;
  top: 32%;
  left: 50%;
  transform: translate(-50%, -00%);

}

.card-root-letterbox-lower-directive {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  font-size: 28px;
  font-family: 'Noto Sans';
  font-weight: bold;
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.card-root-continuebutton {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: fit-content;
  width: fit-content;
}

.card-root-continuebutton-label {
  text-align: center;
  font-size: 28px;
  font-family: 'Noto Sans';
  font-weight: bold;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}