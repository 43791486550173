.figmamissionresult-testroot {
  width: 1920px;
  height: 1080px;

  background: #241b1b;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.figmamissionresult-root {
  display: flex;
  width: 1728px;
  flex-direction: column;
  align-items: center;

  border: 4px solid #2f1e09;
  background: #2f1e09;
}

.figmamissionresult-title {
  display: flex;
  height: 36px;
  padding: 0px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  color: #cba16e;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-segment-report {
  display: flex;
  align-items: center;
  gap: -4px;
  align-self: stretch;
}

.figmamissionresult-segment-panel {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
}

.figmamissionresult-segment-panel-title {
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  background: #1f1203;

  color: #f7d19d;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-segment-panel-title.active {
  background: #644426;
}

.figmamissionresult-segment-panel-content {
  display: flex;
  height: 58px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  background: #080502;

  overflow: hidden;
  color: #948c8c;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
}

.figmamissionresult-segment-panel-content-row-1 {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.figmamissionresult-segment-panel-content-progress {
  display: flex;
  padding: 0px 7px;
  align-items: center;
}

.figmamissionresult-segment-panel-content-progress-total {
  color: #e92424;
}

.figmamissionresult-segment-panel-content-divider {
  width: 2px;
  height: 20px;
  background: #5c4222;
}

.figmamissionresult-segment-panel-content-time {
  display: flex;
  padding: 0px 7px;
  align-items: center;

  overflow: hidden;
  color: #588ba1;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.9px;
}

.figmamissionresult-segment-panel-content-row-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.figmamissionresult-segment-panel-content-survival-desc {
  color: #4a8835;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-segment-panel-content-survival-value {
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-segment-panel-content-survival-value.survived {
  color: #62c453;
}

.figmamissionresult-segment-panel-checkbox {
  display: flex;
  padding: 5px 0px;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  align-self: stretch;

  background: #080502;
}

.figmamissionresult-report-statistics {
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
}

.figmamissionresult-report-statistics-tabs {
  display: flex;
  align-items: flex-end;
  gap: 2px;
  align-self: stretch;
}

.figmamissionresult-report-statistics-tab {
  display: flex;
  padding: 5px 21px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  border-radius: 8px 8px 0px 0px;
  border-top: 2px solid #231409;
  border-right: 2px solid #231409;
  border-left: 2px solid #231409;
  background: #231409;

  color: #6a4d35;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-report-statistics-tab.active {
  border-radius: 8px 8px 0px 0px;
  border-top: 2px solid #815a36;
  border-right: 2px solid #815a36;
  border-left: 2px solid #815a36;
  background: #644426;

  color: #f7d19d;
}

.figmamissionresult-report-statistics-content {
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;

  border: 3px solid #644426;
  background: #080502;
}

.figmamissionresult-personal-statistics {
  display: flex;
  padding: 3px;
  align-items: center;
  align-self: stretch;

  border-radius: 10px;
  background: #181006;
}

.figmamissionresult-personal-statistics-agent-frame {
  position: relative;
  width: 280px;
}

.figmamissionresult-personal-statistics-agent {
  display: flex;
  width: 280px;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  border-radius: 7px;
  border: 2px solid #3b301e;
  background: #251a0e;
}

.figmamissionresult-personal-statistics-agent-up {
  display: flex;
  padding: 3px 0px 0px 3px;
  align-items: flex-start;
  gap: 7px;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agent-up-frame {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 50px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agent-agentnumbericon {
  width: 40px;
  height: 49.565px;
}

.figmamissionresult-personal-statistics-agent-up-agentdata {
  display: flex;
  align-items: center;
  gap: 7px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agent-portraitframe {
  display: flex;
  width: 43px;
  height: 52px;
  padding: 2px;
  align-items: flex-start;
  gap: 10px;

  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.figmamissionresult-personal-statistics-agent-up-roleandname {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agent-up-name {
  display: flex;
  align-items: center;
  gap: 5px;
}

.figmamissionresult-personal-statistics-agent-up-nametext-frame {
  position: relative;
  display: flex;
  padding-bottom: 3px;
  align-items: center;
  gap: 10px;
}

.figmamissionresult-personal-statistics-agent-up-nametext-text {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;

  overflow: hidden;
  color: #ffe2aa;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agent-tiericon {
  position: relative;
  width: 21px;
  height: 20px;
}

.figmamissionresult-personal-statistics-agent-tiericon-background {
  position: absolute;
  top: -6px;
  left: -3px;
  width: 24px;
  height: 25px;
  flex-shrink: 0;

  stroke-width: 2px;
  stroke: #88614b;
}

.figmamissionresult-personal-statistics-agent-tiericon-text {
  position: absolute;
  top: 6px;
  left: 6px;

  display: flex;
  width: 9.882px;
  height: 8.647px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;

  color: #f5d0ae;
  text-align: center;
  font-family: Freshman;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.figmamissionresult-personal-statistics-agent-down {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agent-gears {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agent-gears-firearm {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;

  border: 1px solid #362e1a;
  background: #131009;
}

.figmamissionresult-personal-statistics-agent-gears-firearm-icon {
  width: 26px;
  height: 26px;

  fill: #362e1a;
}

.figmamissionresult-personal-statistics-agent-gears-firearm-detail {
  display: flex;
  padding-right: 5px;
  align-items: center;
  gap: 3px;
  flex: 1 0 0;
}

.figmamissionresult-personal-statistics-agent-gears-firearm-role {
  display: flex;
  padding: 6px 4px;
  align-items: center;
  gap: 12px;

  border-radius: 4px;
  background: #baa96c;

  color: #000;
  text-align: center;

  font-family: "Roboto Slab";
  font-size: 12px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  /* 12px */
  text-transform: capitalize;
}

.figmamissionresult-personal-statistics-agent-gears-firearm-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: #baa96c;

  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agent-gears-armor {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  margin-top: -1px;

  border: 1px solid #362e1a;
  background: #131009;
}

.figmamissionresult-personal-statistics-agent-gears-armor-icon {
  width: 26px;
  height: 26px;

  fill: #362e1a;
}

.figmamissionresult-personal-statistics-agent-gears-armor-name {
  display: flex;
  align-items: center;
  gap: 3px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agent-gears-throwable {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  margin-top: -2px;

  border: 1px solid #362e1a;
  background: #131009;
}

.figmamissionresult-personal-statistics-agent-gears-throwable-icon {
  width: 26px;
  height: 26px;

  fill: #362e1a;
}

.figmamissionresult-personal-statistics-agent-gears-throwable-name {
  display: flex;
  align-items: center;
  gap: 3px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agent-power {
  display: flex;
  height: 26px;
  padding: 3px 0px;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;

  background: #472200;
}

.figmamissionresult-personal-statistics-agent-power-icon {
  display: flex;
  align-items: center;

  color: #e78b00;
  font-family: "Roboto Slab";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agent-power-text {
  color: #ffbf44;
  text-align: right;

  font-family: "Roboto Slab";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail {
  display: flex;
  align-items: center;
  gap: -1px;
  flex: 1 0 0;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agentdetail-kill {
  display: flex;
  width: 155px;
  padding: 0px 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agentdetail-dealing {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agentdetail-kill-header {
  color: #a58471;
  text-align: center;

  font-family: "Roboto Slab";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-kill-content {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  overflow: hidden;
  color: #b0a7a7;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-divider {
  width: 0px;
  height: 162px;

  stroke-width: 2px;
  stroke: #3b301e;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed {
  display: flex;
  height: 56.667px;
  align-items: center;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-header {
  display: flex;
  width: 140px;
  height: 46px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-header-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: #877266;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-header-damagetotal {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: #5da5e7;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-detail {
  display: flex;
  width: 881px;
  height: 46px;
  align-items: center;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-detail-frame {
  width: 881px;
  height: 34px;
  flex-shrink: 0;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-detail-damage {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-detail-damage-textwithicon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-detail-damagetext {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: #a4e6fb;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-detail-gauge {
  position: relative;
  width: 881px;
  height: 18px;
  flex-shrink: 0;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-detail-gauge-background {
  position: absolute;
  width: 881px;
  height: 18px;
  flex-shrink: 0;

  border: 1px solid #032d54;
  background: #000;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-detail-gauge-firearm {
  position: absolute;
  left: 0px;
  height: 14px;
  flex-shrink: 0;

  background: #0077e4;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-dealed-detail-gauge-throwable {
  position: absolute;
  left: 360px;
  height: 14px;
  flex-shrink: 0;

  background: #409ef5;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged {
  display: flex;
  height: 56.667px;
  align-items: center;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-header {
  display: flex;
  width: 140px;
  height: 46px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-header-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: #877266;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-header-damagetotal {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: #e16043;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-detail {
  display: flex;
  width: 881px;
  height: 46px;
  align-items: center;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-detail-frame {
  width: 881px;
  height: 44px;
  flex-shrink: 0;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-detail-damage {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-detail-damagetext {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: #ffcc9e;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-detail-gauge {
  position: relative;
  width: 881px;
  height: 18px;
  flex-shrink: 0;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-detail-gauge-background {
  position: absolute;
  width: 881px;
  height: 18px;
  flex-shrink: 0;

  border: 1px solid #4d0e0a;
  background: #000;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-detail-gauge-health {
  position: absolute;
  left: 0px;
  height: 14px;
  flex-shrink: 0;

  background: #e63b16;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-detail-gauge-shield {
  position: absolute;
  height: 14px;
  flex-shrink: 0;

  background: #fe6543;
}

.figmamissionresult-personal-statistics-agentdetail-dealing-damaged-detail-gauge-armor {
  position: absolute;
  height: 14px;
  flex-shrink: 0;

  background: #ffa96b;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator {
  display: flex;
  height: 56.667px;
  align-items: center;
  align-self: stretch;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-total {
  position: relative;
  display: flex;
  width: 140px;
  height: 46px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-total-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: #877266;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-total-amount {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail {
  display: flex;
  align-items: center;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-frame {
  width: 881px;
  height: 44px;
  flex-shrink: 0;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-header {
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-textwithicon {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  color: #ffcc9e;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-gauge {
  position: relative;
  width: 881px;
  height: 18px;
  flex-shrink: 0;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-gauge-background {
  position: absolute;
  left: 0px;
  width: 881px;
  height: 18px;
  flex-shrink: 0;

  background: #000;
}

.figmamissionresult-personal-statistics-agentdetail-damageIndicator-detail-gauge-foreground {
  position: absolute;
  height: 14px;
  flex-shrink: 0;
}

.figmamissionresult-personal-statistics-agent-deathindicator {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 280px;
  height: 172px;
  flex-shrink: 0;
}

.figmamissionresult-personal-statistics-agent-deathindicator-background {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 280px;
  height: 172px;
  flex-shrink: 0;

  border-radius: 7px;
  opacity: 0.6;
  background: #000;
}

.figmamissionresult-personal-statistics-agent-deathindicator-foreground {
  position: absolute;
  top: 49px;
  width: 276px;
  height: 72px;
  flex-shrink: 0;
}

.figmamissionresult-personal-statistics-agent-deathindicator-foreground-rectangle {
  position: absolute;
  width: 276px;
  height: 72px;
  flex-shrink: 0;

  fill: #600;
}

.figmamissionresult-personal-statistics-agent-deathindicator-foreground-text {
  position: absolute;
  top: 18px;
  left: 112px;
  transform: rotate(-4deg);

  color: #ff4c4c;
  text-align: center;
  font-family: "Roboto Slab";
  font-size: 27px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-statistics-agent-deathindicator-foreground-icon {
  position: absolute;
  top: 23px;
  left: 79px;
  width: 27px;
  height: 30px;
  transform: rotate(-4deg);
  flex-shrink: 0;

  fill: #981111;
}

.figmamissionresult-segment-footer {
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmamissionresult-segment-footer > .figmalist-body-detail-perk-button {
  width: 299px;
  height: 43px;
}

.figmamissionresult-personal-exp-frame{
  display: flex;
  flex-direction: row;
}

.figmamissionresult-personal-exp-detail{
  position: relative;
  display: flex;
  width: 280px;
  height: 60px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
}

.figmamissionresult-personal-exp-text{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-top: 16px;
}

.figmamissionresult-personal-exp-amount{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;

  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Roboto Slab";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmamissionresult-personal-exp-gauge-background {
  position: absolute;
  left: 0px;
  width: 1000px;
  height: 18px;
  flex-shrink: 0;

  background: #000;
}

.figmamissionresult-personal-exp-gauge {
  position: relative;
  width: 1000px;
  height: 18px;
  flex-shrink: 0;
}
