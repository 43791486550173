html {

  --c-yd-window-border: #DC9832;

  --c-yd-content-bg: #EDD28E;
  --c-yd-content-fg: #614924;
  --c-yd-content-fg-desc: #8A660E;
  --c-yd-content-fg-alert: #7D2500;
  --c-yd-content-border: #F8E5B2;
  --c-yd-content-positive: #800000;
  --c-yd-content-negative: #000080;

  --c-yd-content-separator: #B16B00;

  --c-yd-content-deco-1: #FFF4CF;
  --c-yd-content-deco-2: #B0751E;

  --c-yd-title-bg: #32260F;
  --c-yd-title-fg: #FFF3E2;

  --c-yd-subtitle-bg: #674C17;
  --c-yd-subtitle-fg: #EDD390;

  --c-yd-strong-bg: #FBC400;
  --c-yd-strong-fg: #D37200;

  --c-yd-substrong-bg: #936B00;
  --c-yd-substrong-fg: #FFEDD1;
  --c-yd-substrong-fg-desc: #EDD28E;

  --c-yd-smalltitle-bg: #D2B058;
  --c-yd-smalltitle-fg: #7B5E11;

  --c-yd-list-bg: #B59250;

  --c-yd-list-item-border: #755B29;
  --c-yd-list-item-hover-border: orange;
  --c-yd-list-item-active-border: #673216;
  --c-yd-list-item-selected-border: #442311;

  --c-yd-grid-header-border: #000000;
  --c-yd-grid-item-unlocked-bg: #FBE2A2;
  --c-yd-grid-item-locked-bg: #A89164;
  --c-yd-grid-item-empty-bg: #D3B873;
  --c-yd-grid-item-completed-bg: #00812C;
  --c-yd-grid-item-fg: #67583B;
  --c-yd-grid-item-empty-fg: #9D864B;
  --c-yd-grid-item-completed-fg: #E9F699;
  --c-yd-grid-item-border: #987E3C;
  --c-yd-grid-item-completed-border: #006121;

  --c-yd-symbolbox-bg: #E8CB80;
  --c-yd-symbolbox-border: #D2B058;
  --c-yd-symbolbox-fg: #5E3D0B;

  --c-yd-grid2-item-bg: #FFE49E;
  --c-yd-grid2-item-fg: #49270E;
  --c-yd-grid2-item-separator: #D2AE52;
  --c-yd-grid2-item-icon: #37311A;
  --c-yd-grid2-item-title-bg: #E6C573;
  --c-yd-grid2-item-border: #5F4913;

  --c-yd-button-bg: #D75A00;
  --c-yd-button-fg: #FFF8D2;
  --c-yd-button-border: #F2742E;

  --c-yd-button-hover-bg: #CC4125;
  --c-yd-button-hover-fg: #BBA46E;
  --c-yd-button-hover-border: #F2482E;

  --c-yd-button-destructive-bg: #924141;
  --c-yd-button-destructive-border: #BC4B4B;

  --c-yd-button-disabled-border: #9D8446;

  --c-yd-progressbar-empty-bg: #C3A761;
  --c-yd-progressbar-empty-border: #AD8F41;
  --c-yd-progressbar-empty-outline: #D4B86F;

  --c-yd-progressbar-cap-bg: #BB6629;
  --c-yd-progressbar-value-bg: #E08111;
  --c-yd-progressbar-growth-bg: #BB6629; /* temporary value */
  --c-yd-progressbar-fg: #FFFCF9;

  --c-yd-condition-ok: #326825;
  --c-yd-condition-nok: #A62828;

  --c-yd-tier1-bg: #655454;
  --c-yd-tier1-fg: #CFC8C5;
  --c-yd-tier2-bg: #974634;
  --c-yd-tier2-fg: #F9AE3E;
  --c-yd-tier3-bg: #5A6D83;
  --c-yd-tier3-fg: #CEEDFF;
  --c-yd-tier4-bg: #B1771F;
  --c-yd-tier4-fg: #FFD88B;
  --c-yd-tier5-bg: #794292;
  --c-yd-tier5-fg: #FFCFE9;

  --c-bd-window-border: #2A241F;
  --c-bd-content-bg: #443C37;
  --c-bd-content-fg: #9A8B82;
  --c-bd-content-fg-important: #3AC6E5;
  --c-bd-content-fg-progress: #169E3C;

  --c-bd-title-bg: #29231F;
  --c-bd-title-fg: #A5978E;

  --c-bd-content-box-bg: #1C1B18;
  --c-bd-content-box-fg: #FBF6E3;
  --c-bd-content-box-fg-desc: #EAE1C0;
  --c-bd-content-box-border: #656565;

  --c-bd-progressbar-empty-bg: #201D14;
  --c-bd-progressbar-empty-border: rgba(255, 255, 255, 0.2);

  --c-bd-progressbar-value-bg: #169E3C;

  --c-bd-button-bg: #886406;
  --c-bd-button-fg: #F5DD86;
  --c-bd-button-border: #CB8E17;

  --c-bd-portrait-bg: #C8C8C8;

  --c-bd-scrollbar-bg: #474747;
  --c-bd-scrollbar-fg: #8A8585;

  --c-cd-content-bg: #A0EEFF;
  --c-cd-content-fg: #003D76;
  --c-cd-content-fg-desc: #00596C;

  --c-cd-title-bg: #005280;
  --c-cd-title-fg: #91EBFF;

  --c-cd-subtitle-bg: #007E9A;
  --c-cd-subtitle-fg: #92F2FF;

  --c-cd-window-border: #1BA8C7;

  --c-cd-button-bg: #82E9FF;
  --c-cd-button-fg-desc: #00629A;

  --c-cd-box-bg: #0E2735;
  --c-cd-box-condition-ok: #00E64E;
  --c-cd-box-condition-nok: #FF6332;

  --c-fh1-flexoverlay-bg: var(--c-bd-content-bg);
  --c-fh1-flexoverlay-fg: var(--c-bd-content-fg);
  --c-fh1-flexoverlay-fg-important: var(--c-bd-content-fg-important);
  --c-fh1-flexoverlay-border: var(--c-bd-window-border);

  --c-fh1-flexoverlay-title-bg: var(--c-bd-title-bg);
  --c-fh1-flexoverlay-title-fg: var(--c-bd-title-fg);

  --c-fh1-flexoverlay-box-bg: var(--c-bd-content-box-bg);
  --c-fh1-flexoverlay-box-fg: var(--c-bd-content-box-fg);
  --c-fh1-flexoverlay-box-desc-fg: var(--c-bd-content-box-fg-desc);

  --c-fh1-flexoverlay-portrait-bg: var(--c-bd-portrait-bg);

  --c-fh1-flexoverlay-progress-fg: var(--c-bd-content-fg-progress);
  --c-fh1-flexoverlay-progress-desc-fg: var(--c-bd-content-box-fg-desc);

  --c-fh1-flexoverlay-progressbar-empty-bg: var(--c-bd-progressbar-empty-bg);
  --c-fh1-flexoverlay-progressbar-empty-border: var(--c-bd-progressbar-empty-border);
  --c-fh1-flexoverlay-progressbar-value-bg: var(--c-bd-progressbar-value-bg);
  --c-fh1-flexoverlay-progressbar-fg: var(--c-bd-content-box-fg-desc);

  --c-fh1-flexoverlay-scrollbar-thumb: var(--c-bd-scrollbar-fg);
  --c-fh1-flexoverlay-scrollbar-track: var(--c-bd-scrollbar-bg);

  --c-fh1-button-general-fg: var(--c-bd-button-fg);
  --c-fh1-button-general-bg: var(--c-bd-button-bg);
  --c-fh1-button-general-border: var(--c-bd-button-border);

  --c-fh1-window-bg: var(--c-yd-content-bg);
  --c-fh1-window-border: var(--c-yd-window-border);
  --c-fh1-window-title-bg: var(--c-yd-title-bg);
  --c-fh1-window-title-fg: var(--c-yd-title-fg);
  --c-fh1-window-deco-1: var(--c-yd-content-deco-1);
  --c-fh1-window-deco-2: var(--c-yd-content-deco-2);

  --c-fh1-panel-bg: var(--c-yd-content-bg);
  --c-fh1-panel-fg: var(--c-yd-content-fg);
  --c-fh1-panel-title-bg: var(--c-yd-subtitle-bg);
  --c-fh1-panel-title-fg: var(--c-yd-subtitle-fg);

  --c-fh1-panel-small-bg: var(--c-yd-content-bg);
  --c-fh1-panel-small-title-bg: var(--c-yd-smalltitle-bg);
  --c-fh1-panel-small-title-fg: var(--c-yd-smalltitle-fg);

  --c-fh1-panel-style2-bg: var(--c-yd-content-bg);

  --c-fh1-well-bg: var(--c-yd-list-bg);

  --c-fh1-wellseg-bg: var(--c-yd-content-bg);
  --c-fh1-wellseg-border: var(--c-yd-content-border);

  --c-fh1-wellseg-hover-border: var(--c-yd-list-item-hover-border);
  --c-fh1-wellseg-active-bg: var(--c-yd-content-bg);
  --c-fh1-wellseg-active-border: var(--c-yd-list-item-active-border);

  --c-fh1-wellseg-title-bg: var(--c-yd-content-bg);
  --c-fh1-wellseg-title-fg: var(--c-yd-content-fg);

  --c-fh1-wellseg-box-bg: var(--c-yd-content-bg);
  --c-fh1-wellseg-box-fg: var(--c-fh1-wellseg-title-fg);
  --c-fh1-wellseg-box-desc: var(--c-yd-content-fg-desc);
  --c-fh1-wellseg-box-alert: var(--c-yd-content-fg-alert);
  --c-fh1-wellseg-box-border: var(--c-yd-list-item-border);

  --c-fh1-wellseg-selected-bg: var(--c-yd-content-bg);
  --c-fh1-wellseg-selected-border: var(--c-yd-list-item-selected-border);
  --c-fh1-wellseg-selected-hover-border: var(--c-yd-list-item-hover-border);
  --c-fh1-wellseg-selected-title-bg: var(--c-yd-content-bg);

  --c-fh1-wellseg-special-bg: var(--c-yd-content-bg);
  --c-fh1-wellseg-special-border: var(--c-yd-content-border);
  --c-fh1-wellseg-special-title-bg: var(--c-yd-content-bg);

  --c-fh1-wellseg-overlay-fg: white;
  --c-fh1-wellseg-overlay-bg: #000000b4;

  --c-fh1-tabs-bg: #B17316;
  --c-fh1-tabs-fg: #E9B566;
  --c-fh1-tabs-border: #C57F15;

  --c-fh1-tabs-tab-selected-fg: #FFEAB2;
  --c-fh1-tabs-tab-selected-bg: #D09600;
  --c-fh1-tabs-tab-selected-border: #E7A90A;

  --c-fh1-tabs-style2-tab-selected-border: #2d2d2d;

  --c-fh1-overlay-flex-bg: var(--c-yd-content-bg);
  --c-fh1-overlay-flex-fg: var(--c-yd-content-fg);
  --c-fh1-overlay-flex-border: var(--c-yd-window-border);
  --c-fh1-overlay-flex-separator: var(--c-yd-content-separator);

  --c-fh1-overlay-flex-special-bg: var(--c-cd-content-bg);
  --c-fh1-overlay-flex-special-fg: var(--c-cd-content-fg);
  --c-fh1-overlay-flex-special-fg-desc: var(--c-cd-content-fg-desc);
  --c-fh1-overlay-flex-special-title-bg: var(--c-cd-title-bg);
  --c-fh1-overlay-flex-special-title-fg: var(--c-cd-title-fg);
  --c-fh1-overlay-flex-special-border: var(--c-cd-window-border);

  --c-fh1-button-bg: var(--c-yd-button-bg);
  --c-fh1-button-fg: var(--c-yd-button-fg);
  --c-fh1-button-border:  var(--c-yd-button-border);

  --c-fh1-button-hover-bg: var(--c-yd-button-hover-bg);
  --c-fh1-button-hover-fg: var(--c-yd-button-hover-fg);
  --c-fh1-button-hover-border: var(--c-yd-button-hover-border);

  --c-fh1-button-disabled-bg: var(--c-yd-grid-item-locked-bg);
  --c-fh1-button-disabled-fg: var(--c-yd-grid-item-fg);
  --c-fh1-button-disabled-border: var(--c-yd-button-disabled-border);

  --c-fh1-button-destructive-bg: var(--c-yd-button-destructive-bg);
  --c-fh1-button-destructive-fg: var(--c-yd-button-fg);
  --c-fh1-button-destructive-border: var(--c-yd-button-destructive-border);

  --fh1-button-padding-normal: 10px 50px;

  --c-fh1-button-foldable-fg: #FFEAB2;
  --c-fh1-button-foldable-bg: #D09600;
  --c-fh1-button-foldable-border: #E7A90A;

  --c-fh1-button-foldable-hover-bg: #bfbfbf;

  --c-fh1-button-foldable-special-bg: var(--c-cd-button-bg);
  --c-fh1-button-foldable-special-fg: var(--c-cd-content-fg);
  --c-fh1-button-foldable-special-desc-fg: var(--c-cd-button-fg-desc);
  --c-fh1-button-foldable-special-border: var(--c-cd-window-border);

  --c-fh1-button-equip-bg: #FBE2A2;
  --c-fh1-button-equip-fg: #176E34;
  --c-fh1-button-equip-border: #987E3C;

  --c-fh1-button-equip-disabled-fg: #807046;

  --c-fh1-checkbox-bg: var(--c-yd-progressbar-empty-bg);
  --c-fh1-checkbox-border: var(--c-yd-progressbar-empty-border);
  --c-fh1-checkbox-outline: var(--c-yd-progressbar-empty-outline);
  --c-fh1-checkbox-text: #907739;

  --c-fh1-table-bg: var(--c-yd-content-bg);
  --c-fh1-table-fg: var(--c-yd-content-fg);
  --c-fh1-table-border: var(--c-yd-grid-header-border);
  --c-fh1-table-th-bg: var(--c-yd-content-bg);
  --c-fh1-table-th-fg: var(--c-yd-content-fg);
  --c-fh1-table-thead-bg: var(--c-yd-content-bg);
  --c-fh1-table-thead-fg: var(--c-yd-content-fg);
  --c-fh1-table-disabled-fg: var(--c-yd-grid-item-empty-fg);

  --c-fh1-drawer-bg: var(--c-yd-content-bg);
  --c-fh1-drawer-fg: var(--c-yd-content-fg);
  --c-fh1-drawer-border: var(--c-yd-window-border);
  --c-fh1-drawer-title-bg: var(--c-yd-subtitle-bg);
  --c-fh1-drawer-title-fg: var(--c-yd-subtitle-fg);

  --c-fh1-kvp-split-border: #999999;

  --c-fh1-grade-bg: var(--c-yd-symbolbox-bg);
  --c-fh1-grade-fg: var(--c-yd-symbolbox-fg);
  --c-fh1-grade-border: var(--c-yd-symbolbox-border);
  --c-fh1-grade-title-fg: var(--c-yd-content-fg-desc);

  --c-fh1-forces-bg: #955B5B;
  --c-fh1-forces-owner-bg: #1A7986;
  --c-fh1-forces-cap-bg: #C3A761;

  --c-fh1-condition-ok: var(--c-yd-condition-ok);
  --c-fh1-condition-nok: var(--c-yd-condition-nok);

  --c-fh1-condition-box-bg: var(--c-cd-box-bg);
  --c-fh1-condition-box-ok: var(--c-cd-box-condition-ok);
  --c-fh1-condition-box-nok: var(--c-cd-box-condition-nok);

  --c-fh1-delta-positive: var(--c-yd-content-positive);
  --c-fh1-delta-negative: var(--c-yd-content-negative);

  --c-fh1-better: var(--c-yd-condition-ok);
  --c-fh1-worse: var(--c-yd-condition-nok);

  --c-fh1-expire-bg: var(--c-yd-strong-bg);
  --c-fh1-expire-fg: var(--c-yd-strong-fg);

  --c-fh1-label-bg: var(--c-yd-substrong-bg);
  --c-fh1-label-fg: var(--c-yd-substrong-fg);

  --c-fh1-item-panel-bg: var(--c-yd-grid2-item-bg);
  --c-fh1-item-panel-fg: var(--c-yd-grid2-item-fg);
  --c-fh1-item-panel-separator: var(--c-yd-grid2-item-separator);
  --c-fh1-item-panel-icon: var(--c-yd-grid2-item-icon);
  --c-fh1-item-panel-title-bg: var(--c-yd-grid2-item-title-bg);
  --c-fh1-item-panel-border: var(--c-yd-grid2-item-border);
  --c-fh1-item-panel-hover-border: var(--c-yd-button-hover-border);

  --c-fh1-item-label-tier1-bg: var(--c-yd-tier1-bg);
  --c-fh1-item-label-tier1-fg: var(--c-yd-tier1-fg);
  --c-fh1-item-label-tier2-bg: var(--c-yd-tier2-bg);
  --c-fh1-item-label-tier2-fg: var(--c-yd-tier2-fg);
  --c-fh1-item-label-tier3-bg: var(--c-yd-tier3-bg);
  --c-fh1-item-label-tier3-fg: var(--c-yd-tier3-fg);
  --c-fh1-item-label-tier4-bg: var(--c-yd-tier4-bg);
  --c-fh1-item-label-tier4-fg: var(--c-yd-tier4-fg);
  --c-fh1-item-label-tier5-bg: var(--c-yd-tier5-bg);
  --c-fh1-item-label-tier5-fg: var(--c-yd-tier5-fg);

  --c-fh1-warn-fg: var(--c-yd-content-fg-alert);
  --c-fh1-info-fg: var(--c-yd-content-fg);
  --c-fh1-notice-fg: var(--c-yd-content-fg-desc);

  --c-fh1-highlight-fg: var(--c-yd-strong-fg);

  --c-fh1-progressbar-empty-bg: var(--c-yd-progressbar-empty-bg);
  --c-fh1-progressbar-empty-border: var(--c-yd-progressbar-empty-border);
  --c-fh1-progressbar-empty-outline: var(--c-yd-progressbar-empty-outline);

  --c-fh1-progressbar-cap-bg: var(--c-yd-progressbar-cap-bg);
  --c-fh1-progressbar-value-bg: var(--c-yd-progressbar-value-bg);
  --c-fh1-progressbar-growth-bg: var(--c-yd-progressbar-growth-bg);
  --c-fh1-progressbar-better-bg: var(--c-yd-condition-ok);
  --c-fh1-progressbar-worse-bg: var(--c-yd-condition-nok);
  --c-fh1-progressbar-fg: var(--c-yd-progressbar-fg);

  --c-fh1-scrollbar-thumb: #B8944F;
  --c-fh1-scrollbar-track: #8D7240;

  --fh1-button-tiny-margin-normal: 2px 4px;
  --fh1-button-tiny-padding-normal: 6px 20px;

  --fh1-button-inline-padding-normal: 3px 8px;

  --fh1-radius-tiny: 4px;
  --fh1-radius-small: 6px;
  --fh1-radius-medium: 8px;

  --fh1-shadow-tiny: 0px 1px 2px #0006;
  --fh1-shadow-tiny-inset: inset 1px 1px 2px #0006;
  --fh1-shadow-small: 0px 2px 4px #0006;
  --fh1-shadow-medium-inset: inset 0px 4px 3px #0006;

}


/* <Window> */
.fh1-window {
  position: relative;
  min-width: 300px;
  min-height: 300px;
  background-color: var(--c-fh1-window-bg);
  display: flex;
  flex-direction: column;

  --window-title-height: 32px;
}

.fh1-window::before {
  content: '';
  position: absolute;
  z-index: -3;
  background-color: var(--c-fh1-window-border);
  top: 0px;
  left: 0px;
  min-width: 300px;
  min-height: 300px;
  width: calc(100% + 70px);
  height: calc(100% + 50px);
  transform: translate(-40px, -30px);
  clip-path: polygon(39px 11px, 39px 11px, 38.0735081px 11.0544px, 37.0280128px 11.2312px, 35.9145147px 11.5508px, 34.7840144px 12.0336px, 33.6875125px 12.7px, 32.6760096px 13.5704px, 31.8005063px 14.6652px, 31.1120032px 16.0048px, 30.6615009px 17.6096px, 30.5px 19.5px,
    30.5px calc(100% - 145px), 30.5px calc(100% - 145px), 30.4835px calc(100% - 144.4px), 30.428px calc(100% - 143.8px), 30.3245px calc(100% - 143.2px), 30.164px calc(100% - 142.6px), 29.9375px calc(100% - 142px), 29.636px calc(100% - 141.4px), 29.2505px calc(100% - 140.8px), 28.772px calc(100% - 140.2px), 28.1915px calc(100% - 139.6px), 27.5px calc(100% - 139px), 13.5px calc(100% - 130px), 13.5px calc(100% - 130px), 12.8085px calc(100% - 129.5485px), 12.228px calc(100% - 129.088px), 11.7495px calc(100% - 128.6095px), 11.364px calc(100% - 128.104px), 11.0625px calc(100% - 127.5625px), 10.836px calc(100% - 126.976px), 10.6755px calc(100% - 126.3355px), 10.572px calc(100% - 125.632px), 10.5165px calc(100% - 124.8565px), 10.5px calc(100% - 124px), 10.5px calc(100% - 11px), 10.5px calc(100% - 11px), 10.5448px calc(100% - 10.237081px), 10.6904px calc(100% - 9.3761279999998px), 10.9536px calc(100% - 8.459147px), 11.3512px calc(100% - 7.528144px), 11.9px calc(100% - 6.625125px), 12.6168px calc(100% - 5.792096px), 13.5184px calc(100% - 5.0710630000001px), 14.6216px calc(100% - 4.5040320000001px), 15.9432px calc(100% - 4.1330089999999px), 17.5px calc(100% - 4px),
    calc(50% - -10.25px) calc(100% - 0px), calc(100% - 28.5px) calc(100% - 3px), calc(100% - 28.5px) calc(100% - 3px), calc(100% - 27.57431px) calc(100% - 3.0543999999999px), calc(100% - 26.52928px) calc(100% - 3.2311999999998px), calc(100% - 25.41597px) calc(100% - 3.5508000000001px), calc(100% - 24.28544px) calc(100% - 4.0336px), calc(100% - 23.18875px) calc(100% - 4.7px), calc(100% - 22.17696px) calc(100% - 5.5703999999999px), calc(100% - 21.30113px) calc(100% - 6.6652000000001px), calc(100% - 20.61232px) calc(100% - 8.0047999999999px), calc(100% - 20.16159px) calc(100% - 9.6096px), calc(100% - 20px) calc(100% - 11.5px),
    calc(100% - 20px) 153px, calc(100% - 20px) 153px, calc(100% - 19.9835px) 152.4px, calc(100% - 19.928px) 151.8px, calc(100% - 19.8245px) 151.2px, calc(100% - 19.664px) 150.6px, calc(100% - 19.4375px) 150px, calc(100% - 19.136px) 149.4px, calc(100% - 18.7505px) 148.8px, calc(100% - 18.272px) 148.2px, calc(100% - 17.6915px) 147.6px, calc(100% - 17px) 147px, calc(100% - 3px) 138px, calc(100% - 3px) 138px, calc(100% - 2.3084999999999px) 137.5485px, calc(100% - 1.7279999999998px) 137.088px, calc(100% - 1.2495000000001px) 136.6095px, calc(100% - 0.86400000000003px) 136.104px, calc(100% - 0.5625px) 135.5625px, calc(100% - 0.33600000000001px) 134.976px, calc(100% - 0.17550000000006px) 134.3355px, calc(100% - 0.072000000000116px) 133.632px, calc(100% - 0.016499999999724px) 132.8565px, calc(100% - 2.2737367544323E-13px) 132px, calc(100% - 0px) 19px, calc(100% - 0px) 19px, calc(100% - 0.044799999999896px) 18.2370081px, calc(100% - 0.1903999999995px) 17.3760128px, calc(100% - 0.45360000000005px) 16.4590147px, calc(100% - 0.85120000000006px) 15.5280144px, calc(100% - 1.4000000000001px) 14.6250125px, calc(100% - 2.1168px) 13.7920096px, calc(100% - 3.0183999999999px) 13.0710063px, calc(100% - 4.1216000000002px) 12.5040032px, calc(100% - 5.4431999999999px) 12.1330009px, calc(100% - 7.0000000000002px) 12px, calc(50% - -10.25px) 16px, 39px 11px);
}

.fh1-window::backdrop {

}
.fh1-window::after {
  content: '';
  position: absolute;
  z-index: -2;

  background-color: var(--c-fh1-window-deco-1);

  width: calc(100% + 2px);
  height: calc(100% - 10px);

  transform: rotate(0.7deg);
}

.fh1-window>.fh1-title::after {
  content: '';
  position: absolute;
  z-index: -1;

  background-color: var(--c-fh1-window-deco-2);

  width: calc(100% - 10px);
  height: calc(100% - 10px);

  transform: rotate(-0.4deg) translateY(-2px);
}

.fh1-window>.fh1-content::after {
  content: '';
  position: absolute;
  z-index: -1;

  background-color: var(--c-fh1-window-deco-2);

  width: calc(100% - 10px);
  height: calc(100% - 10px);

  transform: rotate(-0.4deg) translateY(-25px);
}

.fh1-window>.fh1-title {
  background-color: var(--c-fh1-window-title-bg);
  display: flex;
  flex-direction: row;
  height: var(--window-title-height);

  position: relative;
}

.fh1-window>.fh1-title>.fh1-title-left {
  position: absolute;
  left: 0;
}

.fh1-window>.fh1-title>.fh1-title-right {
  position: absolute;
  right: 0;
}
.fh1-title-left>button,
.fh1-title-right>button {
  width: var(--window-title-height);
  height: var(--window-title-height);
  border: 0px;
  color: #FFF7DD;
  background-color: var(--c-fh1-button-bg);
}

.fh1-window>.fh1-title>.fh1-title-label {
  flex-grow: 1;
  text-align: center;
  font-size: 18px;
  color: var(--c-fh1-window-title-fg);
  padding: 4px;
}

.fh1-window>.fh1-content {
  padding: 8px;
  display: flex;
  flex-direction: row;
}

/* </Window> */

.fh1-panel-stack>*:not(:last-child) {
  margin-bottom: 0.5em;
}

/* <Panel> */
.fh1-panel {
  min-width: 64px;
  min-height: 16px;
  background-color: var(--c-fh1-panel-bg);
  display: flex;
  flex: 1;
  flex-direction: column;
}

.fh1-panel>.fh1-title {
  background-color: var(--c-fh1-panel-title-bg);
  display: flex;
  flex-direction: row;
  margin: 1px;
}

.fh1-panel>.fh1-title>.fh1-title-label {
  flex-grow: 1;
  text-align: left;
  font-size: 16px;
  color: var(--c-fh1-window-title-fg);
  padding: 3px 3px 3px 7px;
}

.fh1-panel>.fh1-title>.fh1-subtitle-label {
  flex-shrink: 1;
  text-align: right;
  font-size: 14px;
  color: var(--c-fh1-window-title-fg);
  padding: 5px 7px 0 3px;
}

.fh1-panel>.fh1-content {
  padding: 2px;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  max-height: 880px;
  overflow-y: auto;
  color: var(--c-fh1-panel-fg);
}

.fh1-panel.fh1-col>.fh1-content {
  flex-direction: column;
}

.fh1-panel.fh1-row>.fh1-content {
  flex-direction: row;
}

.fh1-panel-left {
  flex: none;
  width: 600px;
}
/* </Panel> */

/* <Panel-style2> */
.fh1-panel.fh1-style2 {
  background-color: var(--c-fh1-panel-style2-bg);
  border: 1px solid var(--c-fh1-tabs-tab-selected-border);
  border-top-width: 0;
  border-bottom-right-radius: var(--fh1-radius-medium);
  border-bottom-left-radius: var(--fh1-radius-medium);
  color: white;
}

/* </Panel-style2> */

/* <PanelSmall> */
.fh1-panel.fh1-panel-small {
  background-color: var(--c-fh1-panel-small-bg);
}

.fh1-panel.fh1-panel-small>.fh1-title {
  background-color: var(--c-fh1-panel-small-title-bg);
}

.fh1-panel.fh1-panel-small>.fh1-title>.fh1-title-label {
  font-size: 14px;
  color: var(--c-fh1-panel-small-title-fg);
}

/* </PanelSmall> */

/* <Button> */
.fh1-button,
button.fh1-button,
button.fh1-button[title] {
  background-color: var(--c-fh1-button-bg);
  color: var(--c-fh1-button-fg);
  border-color: var(--c-fh1-button-border);
  border-width: 1px;
  /* border-radius: var(--fh1-radius-small); */
  box-shadow: var(--fh1-shadow-tiny);
  font-size: 18px;
  padding: var(--fh1-button-padding-normal);
  margin: 1px 1px 4px;
}

button.fh1-button[title]::after {
  content: ' ❔';
  border-radius: 52px;
  background: #A98744;
}

button.fh1-button:disabled {
  background-color: var(--c-fh1-button-hover-bg);
  color: var(--c-fh1-button-hover-fg);
  border-color: var(--c-fh1-button-hover-border);
}

button.fh1-button:hover {
  background-color: var(--c-fh1-button-hover-bg);
  color: var(--c-fh1-button-hover-fg);
  border-color: var(--c-fh1-button-hover-border);
}

button.fh1-button:active {
  background-color: var(--c-fh1-button-hover-bg);
  color: var(--c-fh1-button-hover-fg);
  border-color: var(--c-fh1-button-hover-border);
  box-shadow: var(--fh1-shadow-tiny-inset);
}

button.fh1-button:disabled,
button.fh1-button:disabled[title],
button.fh1-button:disabled:hover,
button.fh1-button:disabled[title]:hover,
button.fh1-button:disabled:active {
  background-color: var(--c-fh1-button-disabled-bg);
  color: var(--c-fh1-button-disabled-fg);
  border-color: var(--c-fh1-button-disabled-border);
  box-shadow: var(--fh1-shadow-tiny);
  padding: var(--fh1-button-padding-normal);
}

/* </Button> */

/* <ButtonWindowTitle> */
.fh1-window>.fh1-title .fh1-button {
  width: 52px;
  margin: 4px;
  font-size: 20px;
}

/* </ButtonWindowTitle> */

/* <ButtonFoldable> */
.fh1-button.fh1-button-foldable,
.fh1-button.fh1-button-foldable:hover,
.fh1-button.fh1-button-foldable[title] {
  color: var(--c-fh1-button-foldable-fg);
  background-color: var(--c-fh1-button-foldable-bg);
  border-color: var(--c-fh1-button-foldable-border);
  border-width: 3px;
  width: 100%;
  display: flex;
  font-size: 18px;
}

.fh1-button-inline.fh1-button-foldable,
.fh1-button-inline.fh1-button-foldable:hover,
.fh1-button-inline.fh1-button-foldable[title] {
  font-size: 12px;
}

.fh1-button.fh1-button-foldable:hover {
  background-color: var(--c-fh1-button-foldable-hover-bg);
}

.fh1-button.fh1-button-foldable .fh1-label {
  display: flex;
}

.fh1-button.fh1-button-foldable .fh1-label>svg {
  margin: 1px 4px 0 -2px;
  width: 18px;
  height: 14px;
}

.fh1-button.fh1-button-foldable.fh1-folded .fh1-label>svg {
  transform: rotate(270deg)
}

.fh1-button.fh1-button-foldable.fh1-unfolded .fh1-label>svg {
  transform: rotate(0deg);
}

/* </ButtonFoldable> */

/* <ButtonTiny> */
.fh1-button.fh1-button-tiny,
.fh1-button.fh1-button-tiny[title] {
  font-size: 14px;
  margin: var(--fh1-button-tiny-margin-normal);
  padding: var(--fh1-button-tiny-padding-normal) !important;
  /* border-radius: var(--fh1-radius-tiny); */
}

.fh1-button.fh1-button-tiny:disabled:hover {
  padding: var(--fh1-button-tiny-padding-normal);
}

/* </ButtonTiny> */


/* <ButtonInline> */
.fh1-button.fh1-button-inline,
.fh1-button.fh1-button-inline[title] {
  font-size: 12px;
  margin: 0px 1px;
  padding: var(--fh1-button-inline-padding-normal) !important;
  /* border-radius: var(--fh1-radius-inline); */
}

.fh1-button.fh1-button-inline:disabled:hover {
  padding: var(--fh1-button-inline-padding-normal);
}

.fh1-button.fh1-button-inline.equip {
  background-color: var(--c-fh1-button-equip-bg);
  color: var(--c-fh1-button-equip-fg);
  border: 1px solid var(--c-fh1-button-equip-border);
  width: 99%;

  display: flex;
  flex-direction: row;
  justify-content: center;
}

.fh1-button.fh1-button-inline.equip:disabled {
  color: var(--c-fh1-button-equip-disabled-fg);
}

.fh1-button.fh1-button-inline.equip:disabled:hover {
  background-color: var(--c-fh1-button-equip-bg);
}

/* </ButtonInline> */

/* <Well> */
.fh1-well {
  background-color: var(--c-fh1-well-bg);
  border-radius: var(--fh1-radius-medium);
  box-shadow: var(--fh1-shadow-medium-inset);
  padding: 4px;
  overflow-y: scroll;
}

.fh1-well>* {
  margin-bottom: 4px;
}

.fh1-well>*:last-child {
  margin-bottom: 0;
}

/* </Well> */

/* <WellSegment> */
.fh1-well .fh1-wellseg,
.fh1-well .fh1-wellseg:hover {
  background-color: var(--c-fh1-wellseg-bg);
  border-color: var(--c-fh1-wellseg-border);
  border-radius: var(--fh1-radius-medium);
  box-shadow: var(--fh1-shadow-small);
  border-width: 4px;
  padding: 4px;
}

.fh1-well .fh1-wellseg.fh1-selected {
  background-color: var(--c-fh1-wellseg-selected-bg);
  border-color: var(--c-fh1-wellseg-selected-border);
}

.fh1-well .fh1-wellseg.fh1-selected:hover {
  border-color: var(--c-fh1-wellseg-selected-hover-border);
}

.fh1-well .fh1-wellseg.fh1-special {
  background-color: var(--c-fh1-wellseg-special-bg);
  border-color: var(--c-fh1-wellseg-special-border);
}

.fh1-well .fh1-wellseg.fh1-special.fh1-selected {
  background-color: var(--c-fh1-wellseg-special-bg);
  border-color: var(--c-fh1-wellseg-selected-border);
}

.fh1-well .fh1-wellseg.fh1-special.fh1-selected:hover {
  border-color: var(--c-fh1-wellseg-selected-hover-border);
}

.fh1-well .fh1-wellseg:hover {
  border-color: var(--c-fh1-wellseg-hover-border);
  border-radius: var(--fh1-radius-medium);
  border-width: 4px;
}

.fh1-well .fh1-wellseg.fh1-active {
  background-color: var(--c-fh1-wellseg-active-bg);
  border-color: var(--c-fh1-wellseg-active-border);
}

.fh1-wellseg .fh1-title {
  color: var(--c-fh1-wellseg-title-fg);
  background-color: var(--c-fh1-wellseg-title-bg);
  padding: 3px 7px 1px;
}

.fh1-wellseg.fh1-selected .fh1-title {
  background-color: var(--c-fh1-wellseg-selected-title-bg);
}

.fh1-wellseg.fh1-special .fh1-title {
  background-color: var(--c-fh1-wellseg-special-title-bg);
}

.fh1-wellseg .fh1-content {
  padding: 0px;
}


.fh1-wellseg .fh1-overlay {
  color: var(--c-fh1-wellseg-overlay-fg);
  background: var(--c-fh1-wellseg-overlay-bg);

  font-size: 16px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  pointer-events: none;
}

/* </WellSegment> */

/* <Tabs> */
.fh1-tabs {
  color: var(--c-fh1-tabs-fg);
  background-color: var(--c-fh1-tabs-bg);
  border: 2px solid var(--c-fh1-tabs-border);
  border-radius: var(--fh1-radius-small);
  font-size: 14px;
  display: flex;
  flex-direction: row;
}

.fh1-tabs .fh1-tab {
  flex: 1 1 0;
  justify-content: center;
  border: 1px solid transparent;
  margin: 1px;
  cursor: pointer;
}

.fh1-tabs .fh1-tab.fh1-selected {
  background-color: var(--c-fh1-tabs-tab-selected-bg);
  border-color: var(--c-fh1-tabs-tab-selected-border);
  color: var(--c-fh1-tabs-tab-selected-fg);
  box-shadow: inset 0 0 2px white;
  cursor: default;
}

.fh1-tabs .fh1-badge-wrapper {
  position: relative;
}

.fh1-tabs .fh1-badge-new {
  display: flex;
  position: absolute;
  top: -12px;
  left: 8px;
  width: 20px;
  height: 20px;
  background-color: red;
  justify-content: center;
  align-content: center;
  font-size: 14px;
  border-radius: var(--fh1-radius-tiny);
}

/* </Tabs> */

/* <Tabs-style2> */
.fh1-tabs.fh1-style2 {
  border-width: 0;
  font-size: 19px;
  background-color: transparent;
}

.fh1-tabs.fh1-style2 .fh1-tab {
  border-top-right-radius: var(--fh1-radius-medium);
  border-top-left-radius: var(--fh1-radius-medium);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border: 1px solid;
  border-top-color: var(--c-fh1-tabs-tab-selected-border);
  border-left-color: var(--c-fh1-tabs-tab-selected-border);
  border-right-color: var(--c-fh1-tabs-tab-selected-border);
  border-bottom-color: transparent;

  margin: 0;
  padding: 4px 0px 0px;
  box-shadow: inset 0 1px 2px #ffffff99;
}

.fh1-tabs.fh1-style2 .fh1-tab:hover {
  border-top-color: black;
  border-left-color: black;
  border-right-color: black;
}

.fh1-tabs.fh1-style2 .fh1-tab:active {
  border-top-color: var(--c-highlight00);
  border-left-color: var(--c-highlight00);
  border-right-color: var(--c-highlight00);
}

.fh1-tabs.fh1-style2 .fh1-tab.fh1-selected {
  border-top-color: var(--c-fh1-tabs-style2-tab-selected-border);
  border-left-color: var(--c-fh1-tabs-style2-tab-selected-border);
  border-right-color: var(--c-fh1-tabs-style2-tab-selected-border);
}

/* </Tabs-style2> */

/* <ProgressBar> */
.fh1-progress {
  border-radius: var(--fh1-radius-small);
  border: 1px solid #c8c8c8;
  background-color: #171717;
  display: inline-block;
  height: 24px;
  min-width: 74px;
}

.fh1-progress>div {
  margin: 1px;
  border-radius: var(--fh1-radius-tiny);
}

.fh1-progress>div>div {
  border-radius: var(--fh1-radius-tiny);
  /* TODO: 양끝에 맞닿은 그래프들만 해당 코너에 roundness 넣어줘야한다 */
}

.flexcenter-upgrading {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

.flexcenter-upgrading .fh1-progress {
  border: none;
  background-color: transparent;
  display: flex;
  margin: 2px 0px;
  min-width: auto;
}

.flexoverlay-item .fh1-progress {
  border: none;
  background-color: transparent;
  display: flex;
  margin: 2px 0px;
  min-width: auto;
}

.flexoverlay-item .fh1-progress>div {
  margin: auto;
  border-radius: unset;
}

.flexoverlay-item .fh1-progress>div>div {
  border-radius: unset;
  /* TODO: 양끝에 맞닿은 그래프들만 해당 코너에 roundness 넣어줘야한다 */
}

/* </ProgressBar> */

/* <KVP> */
.fh1-kvp {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.fh1-kvp>.fh1-title {
  background: none;
  flex: 0 1 1;
  padding: 4px;
}

.fh1-kvp>.fh1-dash {
  background: none;
  flex: 1 1 0;
  height: 1px;
  min-height: 1px;
  max-height: 1px;
  border-bottom: 1px dashed var(--c-fh1-kvp-split-border);
}

.fh1-kvp>.fh1-content {
  background: none;
  flex: 0 1 1;
  padding: 4px;
}

/* </KVP> */

/* <Grade> */
.fh1-grade {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--c-fh1-grade-bg);
  color: var(--c-fh1-grade-fg);
  border: 2px solid var(--c-fh1-grade-border);
  min-width: 64px;
  margin: 2px;
}

.fh1-grade>.fh1-title {
  background-color: transparent;
  flex: 0 1 1;
  padding: 12px 0 0;
  font-size: 12px;
  color: var(--c-fh1-grade-title-fg);
}

.fh1-grade>.fh1-content {
  flex: 0 1 1;
  padding: 0 0 4px;
  font-size: 24px;
  font-family: 'Freshman';
}

/* </Grade> */

/* <Check> */
.fh1-check {
  display: flex;
  flex-direction: row;
  background-color: var(--c-fh1-overlay-flex-bg);
  margin: 1px;
  padding: 4px 4px 2px 4px;
  border-radius: var(--fh1-radius-small);
  color: var(--c-fh1-checkbox-text);
  cursor: pointer;
  user-select: none;
}

.fh1-check>.fh1-checkshape>svg {
  width: 18px;
  height: 18px;
  stroke: white;
  background-color: var(--c-fh1-checkbox-bg);
  border: 1px solid var(--c-fh1-checkbox-border);
  outline: 1px solid var(--c-fh1-checkbox-outline);
  box-shadow: 0 0 0 1px #bbbbbb, inset 0 2px 0 0 #00000033;
  border-radius: var(--fh1-radius-tiny);
  margin-right: 4px;
}

.fh1-check>.fh1-checkshape.checked>svg {
  stroke: white;
}

.fh1-check>.fh1-checkshape.unchecked>svg {
  stroke: #ffffff0a;
}

.fh1-check>.fh1-checkshape.disabled>svg {
  box-shadow: 0 0 0 1px #bbbbbb, inset 0 2px 0 0 #00000000;
  background-color: #cccccc;
}

/* </Check> */


.fh1-content-inner,
.fh1-content .box {
  color: var(--c-fh1-wellseg-box-fg);
  background-color: var(--c-fh1-wellseg-box-bg);
}

.fh1-window select {
  color: var(--c-yd-smalltitle-fg);
  background-color: var(--c-yd-smalltitle-bg);
  border: 1px solid var(--c-fh1-subtitle-border);
}
