.figmaintermission-testroot {
  width: 1920px;
  height: 1080px;

  background: #241b1b;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
}

.figmaintermission-lobby {
  background-color: var(--c-gr1-bg);
  color: var(--c-gr1-transpbox-fg);
  height: 100%;
  width: 100%;

  font-family: "Noto Sans";

  display: flex;
  justify-content: center;
  align-items: center;
}

.figmaintermission-root {
  display: flex;
  width: 1258px;
  height: 959px;
  padding: 20px 30px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border-radius: 10px;
  border: 3px solid #583c25;
  background: #3d291a;

  gap: 10px;

  margin: auto;
}

.figmaintermission-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.figmaintermission-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  color: #dab988;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 24px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.figmaintermission-subtitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  color: #92e22f;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaintermission-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;

  width: 100%;
}

.figmaintermission-progressbar {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.figmaintermission-progressbar-phase {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;

  display: flex;
  padding: 4px 0px;
  justify-content: center;
  align-items: center;
  gap: 9px;
  flex: 1 0 0;

  background: #987a3e;

  clip-path: polygon(
    0% 0%,
    calc(100% - 10px) 0%,
    100% 50%,
    calc(100% - 10px) 100%,
    0% 100%,
    10px 50%
  );

  margin-right: -5px;
}

.figmaintermission-progressbar-phase:last-child {
  margin-right: unset;
}

.figmaintermission-progressbar-phase.active {
  background: #ceab68;
}

.figmaintermission-progressbar-phase-order {
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  background: #000;
  border-radius: 50%;

  color: #987a3e;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.figmaintermission-progressbar-phase-desc {
  color: #000;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.figmaintermission-agentresult {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
  width: 100%;
}

.figmaintermission-agentlist {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
  width: 100%;
  overflow-x: hidden;
}

.figmaintermission-agentlist-header {
  display: flex;
  padding: 4px 20px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  background: #5c422f;

  color: #dab988;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.figmaintermission-agentlist-body {
  display: flex;
  width: calc(100% + 16px);
  height: 310px;
  padding: 6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  border: 1px solid #5c422f;
  background: #0a0707;

  overflow-y: scroll;
  padding-right: 12px;
}

.figmaintermission-agentrow {
  display: flex;
  height: 71px;
  align-items: center;
  gap: 2px;
  align-self: stretch;
  flex-shrink: 0;

  border: 4px solid #553923;
  background: #432c1a;
}

.figmaintermission-agentrow-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  padding-left: 10px;
  gap: 8px;

  width: 283px;
}

.figmaintermission-agentrow-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.figmaintermission-agentrow-item-title {
  display: flex;
  justify-content: center;
  align-items: center;

  color: #b1805c;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  flex: 1 0 0;
}

.figmaintermission-agentrow-item-value {
  display: flex;
  align-items: center;
  gap: 6px;

  overflow: hidden;
  color: #f2e4b5;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  flex: 1 0 0;
}

.figmaintermission-agentrow-item-value-secondary {
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 2px;
  align-self: stretch;

  border-radius: 48px;
  background: #92e22f;

  overflow: hidden;
  color: #293a14;
  text-align: center;
  text-overflow: ellipsis;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figma-general-toggle-button {
  width: 42px;
  height: 21px;
  margin: 2px;
  flex-shrink: 0;

  border-radius: 100px;
  border: 2px solid #694b34;
  background: #0a0906;

  position: relative;
}

.figma-general-toggle-button-foreground {
  position: absolute;
  top: -2px;
  left: -1px;

  width: 23px;
  height: 23px;
  flex-shrink: 0;

  border-radius: 100px;
  background: #74563f;
  border: 4px solid #816148;
  filter: drop-shadow(0px 2px 0px rgba(0, 0, 0, 0.25));

  transition: all 0.5s;
}

.figma-general-toggle-button-foreground.active {
  left: 17px;

  background: #bbcb09;
}

.figmaintermission-upgrades {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  align-self: stretch;

  flex: 1 0;
}

.figmaintermission-upgrades-header {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmaintermission-upgrades-header-title {
  color: #dab988;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.figmaintermission-upgrades-header-value {
  color: #5fdcbe;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.figmaintermission-upgrades-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.figmaintermission-upgrades-body-title {
  display: flex;
  padding: 4px 20px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  align-self: stretch;

  background: #5c422f;

  color: #dab988;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.figmaintermission-upgrades-skills-list {
  display: flex;
  width: 100%;
  padding: 0px 15px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;

  border: 1px solid #5c422f;
  background: #0a0707;
}

.figmaintermission-upgrades-skills-row {
  display: flex;
  padding-right: 30px;
  align-items: center;
  gap: 30px;
  align-self: stretch;
  padding-top: 17px;
  padding-bottom: 17px;
  border-bottom: 1px solid #a49886;
  flex: 1 0 0;
}

.figmaintermission-upgrades-skills-row:last-child {
  border-bottom: unset;
}

.fiamaintermission-upgrades-skills-row-header {
  width: 60px;
  align-self: stretch;

  background: #866635;
  clip-path: polygon(0% 0%, 84% 0%, 100% 50%, 84% 100%, 0% 100%);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fiamaintermission-upgrades-skills-row-header-value {
  color: #f7d083;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: -10%;
}

.fiamaintermission-upgrades-skills-row-header-desc {
  color: #f7d083;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.figmaintermission-upgrades-skills-row-body {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 14px;
  flex: 1 0 0;
}

.figmaintermission-upgrade-skills-row-item {
  display: flex;
  padding: 2px;
  align-items: center;
  gap: 2px;
  flex: 1 1 0;

  border-radius: 84px;
  background: #281e18;

  min-width: 0;
  border: 3px solid transparent;

  position: relative;
  z-index: 0;
}

.figmaintermission-upgrade-skills-row-item:hover {
  border: 3px solid #bb9979;
  background: #3e2e25;
}

.figmaintermission-upgrade-skills-row-item:active {
  border: 3px solid #8e7257;
  background: #281e18;
}

.figmaintermission-upgrade-skills-row-item.locked:hover {
  border: 3px solid transparent;
  background: #281f18;
}

.figmaintermission-upgrade-skills-row-item.locked:active {
  border: 3px solid transparent;
  background: #281f18;
}

.figmaintermission-upgrade-skills-row-item.selected,
.figmaintermission-upgrade-skills-row-item.complete {
  background: #003405;
}

.figmaintermission-upgrade-skills-row-item.selected
  .figmaintermission-upgrade-skills-item-icon,
.figmaintermission-upgrade-skills-row-item.complete
  .figmaintermission-upgrade-skills-item-icon {
  background: #92e22f;
}

.figmaintermission-upgrade-skills-item-icon {
  width: 83px;
  height: 83px;
  flex-shrink: 0;
  background: #d9d9d9;
  border-radius: 84px;
  overflow: hidden;
  position: relative;
}

.figmaintermission-upgrade-skills-item-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 4px;
  align-self: stretch;

  width: 78%;
}

.figmaintermission-upgrade-skills-item-title {
  width: 100%;
  align-self: stretch;

  color: #ffe9b1;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.figmaintermission-upgrade-skills-item-effect {
  display: flex;
  padding: 0px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;

  min-width: 0;
  overflow: hidden;
}

.figmaintermission-upgrade-skills-item-effect-ty {
  color: #c4a466;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.figmaintermission-upgrade-skills-item-effect-value {
  color: #92e22f;

  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.figmaintermission-upgrade-skills-item-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 84px;
  z-index: 1;
}

.figmaintermission-upgrade-skills-item-lock {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.figmaintermission-upgrade-skills-item-complete {
  position: absolute;
  top: 0;
  left: 0;
}

.figmaintermission-footer {
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}

.figmamission-button-next {
  display: flex;
  width: 340px;
  height: 50px;
  padding: 13px 85px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #dfa612;

  color: #2a1305;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}
