

.stat-overlay {
  position: fixed;
  top: 0;
  top: 10em;
  bottom: 10em;
  left: 5em;
  right: 5em;
  padding: 1em;
  border: 1px solid;
  background: white;
  opacity: 0.8;
}
