.figmagearexchange-root {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: inline-flex;
  width: 1274px;
  height: 735px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 12px;
  border: 3px solid #583c25;
  background: #583c25;
}

.figmagearexchange-header {
  display: flex;
  position: relative;
  height: 54px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 424px;
  align-self: stretch;
  background: #3d291a;
}

.figmagearexchange-header-title {
  color: #dcba86;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmagearexchange-header-btn-close {
  box-sizing: border-box;
  position: absolute;
  right: 12px;
  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;

  width: 53px;
  height: 35px;

  background: #9e8662;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 66px;
}

.figmagearexchange-header-btn-close:hover {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #c7af8b;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmagearexchange-header-btn-close:active {
  border-radius: 66px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  background: #7a6442;
}

.figmagearexchange-body {
  display: flex;
  padding: 15px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex: 1 0 0;
  align-self: stretch;
  background: #0c0602;
}

.figmagearexchange-body-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmagearexchange-body-detail-header {
  display: flex;
  width: 380px;
  height: 54px;
  padding: 2px 3px 3px 3px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 1 0 0;
  border-radius: 6px;
  border: 3px solid #4d341e;
  background: #442b14;
}

.figmagearexchange-body-detail-header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmagearexchange-body-detail-header-title-label {
  color: #dcba86;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmagearexchange-body-detail-header-title-label.cur {
  color: #98d03d;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmagearexchange-body-detail-header-main {
  position: relative;
  align-self: stretch;
  height: 226px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  border: 1.5px solid #140b03;
  background: #2a170f;
}

.background {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.figmagearexchange-body-detail-header-main-name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;

  z-index: 0;
}

.figmagearexchange-body-detail-header-main-name-ty {
  display: flex;
  padding: 7px 5px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: #dbbc6b;
}

.figmagearexchange-body-detail-header-main-name-ty-label {
  color: #000;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  /* 14px */
  text-transform: capitalize;
}

.figmagearexchange-body-detail-header-main-name-label {
  color: #f2e4b5;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmagearexchange-body-detail-header-main > img {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.figmagearexchange-body-detail-header-main-exception {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}

.figmagearexchange-body-detail-header-main-exception-label {
  display: flex;
  white-space: nowrap;
  justify-content: center;
  align-self: stretch;
  color: #a98744;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 122.222% */
}

.figmagearexchange-body-detail-options {
  display: flex;
  height: 284px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  align-self: stretch;
  overflow: visible;
}

.figmagearexchange-body-detail-options-sep {
  height: 1px;
  align-self: stretch;
  background: #422c1c;
}

.figmagearexchange-body-detail-option {
  display: flex;
  height: 21px;
  padding: 0px 10px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}

.figmagearexchange-body-detail-option-title {
  color: #dab988;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.figmagearexchange-body-detail-option-value {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.figmagearexchange-body-detail-option-value {
  color: #ffe2aa;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.figmagearexchange-body-detail-option-value.better {
  color: #71c507;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.figmagearexchange-body-detail-option-value.worse {
  color: #e92424;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.figmagearexchange-body-cur {
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: #29190d;
}

.figmagearexchange-body-list {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 10px;
  background: #29190d;
}

.figmagearexchange-body-list-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7px;
  align-self: stretch;
}

.figmagearexchange-body-list-body-title {
  display: flex;
  padding: 0px 13px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
}

.figmagearexchange-body-list-body-title-label {
  flex: 1 0 0;
  color: #a48250;
  font-family: "Noto Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
}

.figmagearexchange-body-list-body-rows {
  display: flex;
  width: 364px;
  padding: 10px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  background: #0e0808;
  overflow: auto;
}

.figmagearexchange-body-list-body-row {
  width: 344px;

  display: flex;
  padding: 8px 5px;
  align-items: center;
  gap: 3px;
  align-self: stretch;
  border-radius: 6px;
  border: 3px solid #553923;
  background: #432c1a;
}

.figmagearexchange-body-list-body-row.selected {
  width: 344px;
  display: flex;
  padding: 8px 5px;
  align-items: center;
  gap: 3px;
  align-self: stretch;
  border-radius: 6px;
  border: 3px solid #e8c47f;
}

.figmagearexchange-body-list-body-row-firearmty {
  display: flex;
  padding: 7px 5px;
  align-items: center;
  gap: 12px;
  border-radius: 4px;
  background: #baa96c;
}

.figmagearexchange-body-list-body-row-name {
  color: #f2e4b5;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmagearexchange-body-list-body-row-firearmty-label {
  color: #000;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 100%;
  /* 14px */
  text-transform: capitalize;
}

.figmagearexchange-footer {
  display: flex;
  height: 70px;
  padding: 7px 24px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  align-self: stretch;
  background: #3d291a;
}

.figmagearexchange-footer-cancel-btn {
  display: flex;
  width: 300px;
  padding: 8px 0px 7px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #d0bd8b;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmagearexchange-footer-cancel-btn:hover {
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #f8ebc8;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmagearexchange-footer-cancel-btn:active {
  border-radius: 6px;
  border: 3px solid rgba(55, 55, 55, 0.2);
  background: #857751;
}

.figmagearexchange-footer-cancel-btn-label {
  color: #422f0b;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmagearexchange-footer-exchange-btn {
  display: flex;
  width: 300px;
  padding: 8px 0px 7px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #dfa612;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmagearexchange-footer-exchange-btn:hover {
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: #ffd15b;
}

.figmagearexchange-footer-exchange-btn:active {
  border-radius: 6px;
  border: 3px solid rgba(55, 55, 55, 0.2);
  background: #a27500;
}

.figmagearexchange-footer-exchange-btn-unable {
  display: flex;
  width: 300px;
  padding: 8px 0px 7px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  background: #4b4942;
}

.figmagearexchange-footer-exchange-btn-label-unable {
  color: #737069;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmagearexchange-footer-exchange-btn-label {
  color: #422f0b;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmagearexchange-footer-clear-btn {
  display: flex;
  width: 300px;
  padding: 8px 0px 7px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  border: 3px solid #b25739;
  background: #9d472b;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmagearexchange-footer-clear-btn-unable {
  display: flex;
  width: 300px;
  padding: 8px 0px 7px 0px;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  border: 3px solid #593838;
  background: #4a3423;
  box-shadow: 0px 4px 0px 0px rgba(0, 0, 0, 0.25);
}

.figmagearexchange-footer-clear-btn-label-unable {
  color: #815b4f;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmagearexchange-footer-clear-btn-label {
  color: #e4896c;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
