.tree-branch {
  height: 45px;
  width: 9px;
}

.tree-branch .rectangle-7 {
  position: relative;
}

.tree-branch .rectangle-8 {
  background-color: #ffe382;
  height: 38px;
  left: 3px;
  position: relative;
  width: 3px;
}

.tree-branch.branch-d {
  background-color: #dba415;
}

.tree-branch.branch-x .rectangle-7 {
  background-color: #dba415;
  height: 45px;
}

.tree-branch.branch-d .rectangle-7 {
  background-color: #ffe382;
  height: 45px;
  left: 3px;
  width: 3px;
}

.tree-branch.branch-b .rectangle-7 {
  background-color: #dba415;
  height: 36px;
  left: 3px;
  top: 8px;
  width: 3px;
}

.tree-branch.branch-c .rectangle-7 {
  background-color: #dba415;
  height: 45px;
  left: 3px;
  width: 3px;
}

.tree-branch.branch-a .rectangle-7 {
  background-color: #dba415;
  height: 38px;
  top: 7px;
}
