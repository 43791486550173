.PGB-con-b {
  align-items: center;
  display: flex;
  height: 47px;
  position: relative;
  width: 708px;
}

.PGB-con-b .PGB-button-6 {
  align-self: stretch !important;
  flex: 1 !important;
  flex-grow: 1 !important;
  height: unset !important;
  position: relative !important;
  width: unset !important;
}

.PGB-con-b .PGB-button-7 {
  width: 351px !important;
}

.PGB-con-b .PGB-button-8 {
  width: 298px !important;
}

.PGB-con-b .PGB-splitter-a {
  position: relative !important;
}

.PGB-con-b .PGB-button-b {
  margin-left: unset !important;
}

.PGB-con-b .PGB-button-b-2 {
  margin-right: unset !important;
}
