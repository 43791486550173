
.ingameoverlay-root {
  position: fixed;
  background-color: gray;
  width: 100%;
  height: 100%;
}

.ingameprompt-root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

.ingamepopup-root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ingamestatus-root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.ingamepolicy-root {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.ingameoverlay-name-emtpy {
  margin-left: 75px;
}

.ingameoverlay-name-label {
  font-family: The Jamsil OTF;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;

  height: 31px;
  line-height: 31px;
}

.ingameoverlay-name-label.unselected {
  color: #FFF;
}

.ingameoverlay-name-label.selected {
  color: #000;
  background: transparent;
}

.ingameoverlay-name-bg {
  display: flex;
  flex-direction: row;

  position: relative;
  width: 371px;
  height: 31px;
  flex-shrink: 0;
  left: -12px;

  border-radius: 0px 5px 5px 0px;

  align-items: center;
}

.ingameoverlay-name-bg.selected {
  opacity: 0.8;
  background: linear-gradient(90deg, #00FF85 62.74%, rgba(0, 255, 133, 0.00) 91.79%);
}

.ingameoverlay-leadericon {
  position: relative;
  margin-right: 10px;
}

.ingameoverlay-leadericon-label {
  position: absolute;

  top: 2px;
  left: 5.5px;

  height: 20px;
  line-height: 20px;

  color: #FFF;

  font-family: The Jamsil OTF;
  font-size: 15px;
  font-style: normal;
  font-weight: 800;
  text-align: center;
}

.ingameoverlay-firearm {
  position: relative;
  margin-left: 6px;
}

.ingameoverlay-firearm-label {
  position: absolute;

  top: 0;
  left: 0;

  height: 20px;
  line-height: 20px;

  font-weight: 800;
  width: 43px;
  text-align: center;
}

.ingameoverlay-status-bg {
  display: flex;
  flex-direction: row;
  width: max-content;
  height: 30px;
  flex-shrink: 0;
  background: linear-gradient(90deg, rgba(96, 96, 96, 0.3) 81.68%, rgba(96, 96, 96, 0.00) 100%);
}

.ingameoverlay-bg {
  position: absolute;
  width: 563px;
  height: 61px;
  flex-shrink: 0;

  border-radius: 5px 0px 0px 5px;
  opacity: 0.3;
  background: linear-gradient(90deg, #111A19 91.12%, rgba(17, 26, 25, 0.00) 100%);

}

.ingameoverlay-portrait {
  position: absolute;
  width: 57px;
  height: 65px;
  top: 7px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.ingameentity {
  position: relative;
  left: 12px;
  height: 72px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.ingameoverlay-status-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding-left: 68px;
}

.ingameoverlay-status-stat-group {
  display: flex;
  flex-direction: row;
  height: 23.59px;
  align-items: center;
}

.ingameoverlay-status-stat {
  display: flex;
  align-items: center;
  align-self: stretch;

  margin-right: 15px;

  color: #879F9F;

  text-align: center;
  font-family: The Jamsil OTF;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.ingameoverlay-status-stat-icon {
  width: 20px;
  height: 22px;
  flex-shrink: 0;
  margin-right: 6px;
}

.ingameoverlay-status-stat-progressbar {
  width: 51px;
  height: 11px;
  flex-shrink: 0;
}

.ingameoverlay-portrait1 {
  display: inline-block;
  width: 57px;
  height: 65px;
  flex: 0 0 auto !important;
}

.ingameoverlay-portrait1>.portrait-frame {
  width: 57px;
  height: 65px;
}

.ingameoverlay-portrait-state {
  position: relative;
  top: -36px;
  width: 27px;
  height: 27px;
}

.ingameoverlay-status-stat-amount-health-current {
  color: #90CD2C;
}

.ingameoverlay-status-stat-amount-general-current {
  color: #FFF;
}

.ingameoverlay-status-stat-amount-inflict-kill {
  color: #FF5858;
}

.ingameoverlay-status-stat-amount-inflict-damage {
  color: #FF9029;
}

.ingameprompt {
  position: fixed;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ingameprompt-panel {
  position: relative;
  width: 616px;
  height: 258px;
}

.ingameprompt-panel-bg {
  position: absolute;
}

.ingameprompt-panel-line {
  position: absolute;
  left: 5px;
  top: 27px;
}

.ingameprompt-panel-title {
  position: absolute;
  left: 76px;
  top: 5px;
  width: 464px;
  height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ingameprompt-panel-title>svg{
  position: absolute;
  left: 0px;
  top: 0px;
}

.ingameprompt-panel-title-label {
  position: relative;

  color: #5F2C06;

  text-align: center;
  font-family: The Jamsil OTF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}

.ingameprompt-remaining-time {
  position: relative;
  width: 249px;
  height: 106px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.ingameprompt-remaining-time-description {
  position: relative;
  width: 249px;
  height: 30px;

  color: #DDB57A;

  font-family: The Jamsil OTF;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.3px;
  text-align: center;

  background: rgba(55, 17, 17, 0.7);

  backdrop-filter: blur(10px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.ingameprompt-remaining-time-amount {

  position: relative;
  width: 79px;
  height: 79px;

  color: #FFE6A5;

  font-family: The Jamsil OTF;
  font-size: 26px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2.6px;
  text-align: center;

  background: rgba(55, 17, 17, 0.7);

  backdrop-filter: blur(10px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.ingameprompt-choice-list {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 7px;

  position: relative;
  width: 590px;
  height: 185px;
  left: 13px;
  top: 51px;
}

.ingameprompt-choice.selected {
  position: relative;
  width: 546px;
  height: 41px;

  background: #FFE08F;

  stroke-width: 2px;
  stroke: #CCB04F;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ingameprompt-choice-label.selected {
  color: #000;
  background: transparent;
}

.ingameprompt-choice.unselected {
  position: relative;
  width: 546px;
  height: 41px;

  background: #C2761D;

  stroke-width: 2px;
  stroke: #000;
  stroke-opacity: 0.5;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ingameprompt-choice-label.unselected {
  color: #FFE5B4;
}

.ingameprompt-choice-bg{
  position: relative;
  width: 590px;
  height: 57px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.ingameprompt-choice-bg.selected {
  background: #90663e;
}

.ingamepopup-bg {
  position: relative;
  width: 771px;
  height: 313px;
  background: #DF9A5B;
  border: #CB884B solid;
  border-width: 2px;
  border-radius: 9px;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: -2;

  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.70));
}

.ingamepopup-panel-bg {
  width: 744px;
  height: 288px;
  background: #EEC36F;
  border: 5px #F6CD7C solid;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ingamepopup-panel-bg::before {
  content: "";
  position: absolute;
  width: 750px;
  height: 288px;
  background: #FFDFA2;
  transform: rotate(-0.8deg) translateX(-2px);
  z-index: -1;
}

.ingamepopup-content {
  position: relative;
  width: 678px;
  height: 180px;
  border-top: 3px #D7AE5D solid;
  border-bottom: 3px #D7AE5D solid;

  color: #583A09;

  text-align: center;
  font-family: The Jamsil OTF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ingamepopup-content::before {
  content: "";
  position: absolute;
  width: 678px;
  height: 170px;
  border-top: 1px #D7AE5D solid;
  border-bottom: 1px #D7AE5D solid;
}

.ingamepopup-button-group {
  position: relative;
  display: inline-flex;
  align-items: flex-start;
  gap: 59px;
  padding-top: 21px;
}

.ingamepopup-button {
  display: flex;
  padding: 10px 80px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.50);

  color: #500E00;

  font-family: The Jamsil OTF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px;
}

.ingamepopup-button.level0, .ingamestatus-button.level0 {
  border: 1px solid #FFCB45;
  background: #FFC122;
}

.ingamepopup-button.level1, .ingamestatus-button.level1 {
  border: 1px solid #FF6B00;
  background: #EE5600;
}

.ingamestatus-container {
  display: inline-flex;
  padding: 10px 14px;
  justify-content: center;
  align-items: center;
  gap: 20px;

  border-radius: 0px 0px 14px 14px;
  background: rgba(0, 0, 0, 0.80);



  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.40);
}

.ingamestatus-morale {
  display: flex;
  align-items: center;
  gap: 6px;
}

.ingamestatus-button {
  display: flex;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.50);

  font-family: The Jamsil OTF;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.6px;
}

.ingamestatus-morale-progress-with-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
}

.ingamestatus-morale-textgroup {
  display: flex;
  align-items: flex-start;
  gap: 6px;

  font-family: The Jamsil OTF;
  font-size: 15px;
  font-style: normal;
  line-height: normal;
}

.ingamestatus-morale-text.label {
  color: #00FF85;
  font-weight: 400;
}

.ingamestatus-morale-text.value {
  color: #FFF;
  font-weight: 500;
}

.ingamepolicy-container {
  display: flex;
  flex-direction: column;
}

.ingamepolicy-log {
  height: 35px;
  background: rgba(0, 0, 0, 0.60);

  display: flex;
  white-space: pre-wrap;
  text-align: right;
  align-items: center;
  padding-right: 23px;

  color: #F1C077;
  text-align: right;
  font-family: The Jamsil OTF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ingamepolicy-log::before {
  content: "";
  background: linear-gradient(270deg, rgba(0, 0, 0, 0.60) 20%, rgba(0, 0, 0, 0.00) 82.26%);
  width: 100px;
  height: 35px;
  position: absolute;
  top: inherit;
  transform: translateX(-100%);
}

.ingamepolicy-log-label {
  color: #FFFBEE;
  font-weight: 500;
}

.ingamepolicy-button-group-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 4px;
  padding: 10px;
}

.ingamepolicy-button-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 4px;
}

.ingamepolicy-button {
  width: 68px;
  height: 68px;
  flex-shrink: 0;

  border-radius: 34px;
  background: rgba(0, 0, 0, 0.20);

  display: flex;
  justify-content: center;
  align-items: center;
}

.ingamepolicy-button:hover {
  background: rgba(0, 0, 0, 0.40);
}

.ingamepolicy-button>svg {
  filter: drop-shadow(rgba(0, 0, 0, 0.40) 0px 4px 4px)
}

.outgameheader-container {
  width: 822px;
  height: 54px;
  position: absolute;
  top: 0px;
  left: 0px;
  background: linear-gradient(90deg, #000 77.92%, rgba(0, 0, 0, 0.00) 101.7%);

  display: flex;
  flex-direction: row;
  gap: 40px;
  align-items: center;
  justify-content: flex-start;

  z-index: 18;
}

.outgameheader-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

img.outgameheader-icon {
  width: 50px;
  height: 50px;
}

.outgameheader-value {
  font-family: The Jamsil OTF;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.outgameheader-value.cash {
  color: #E29D00;
  width: 80px;
  text-align: right;
}

.outgameheader-value.reputation {
  color: #30FFF6;
}

.outgameheader-value.agent {
  color: #FFF8F3;
}

.outgameheader-value.training {
  color: #35D901;
}

.outgameheader-value.injured {
  color: #FF484A;
}

.outgameheader-value>span.limit {
  color: #757575;
}

.outgamealarm-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.outgamealarm-item {
  width: 130px;
  height: 80px;
}

.outgamealarm-item>img.panel {
  width: 130px;
  height: 78px;
  margin: 1px 0px;
  position: absolute;
}

.outgamealarm-item>img.icon {
  width: 80px;
  height: 80px;
  margin: 0px 25px;
  position: absolute;
}
