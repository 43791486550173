.figmaingame-testroot {
  width: 1920px;
  height: 1080px;

  background: #241b1b;

  position: relative;
}

.figmaingame-root {
  width: 1920px;
  height: 1080px;

  position: relative;

  font-family: "Noto Sans", "Noto Sans CJK";
}

.figmaingame-testroot > .figmaingame-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.figmaingame-agentlist {
  display: flex;
  flex-direction: column;

  position: fixed;
  left: 10px;
  bottom: 10px;
  gap: 20px;
  /* pointer-events: none; */
}

.figmaingame-agentcard {
  display: flex;
  padding: 4px 8px;
  align-items: flex-start;

  background: rgba(0, 0, 0, 0.3);
  color: #d3c6b2;

  border-bottom: 1px solid black;
  font-family: "Noto Sans", "Noto Sans CJK";
}

.figmaingame-agentcard.dead {
  background: rgba(0, 0, 0, 0.5);
  filter: grayscale(0);
}

.figmaingame-agentcard-detail {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
  flex: 1 0 0;
}

.figmaingame-agentcard-detail-card {
  width: 220px;
  overflow: hidden;
}

.figmaingame-root .flex-agents-detail-portrait {
  display: flex;
  width: 73px;
  height: 88px;
  padding: 3px;
  flex-basis: 0 0 73px;

  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.figmaingame-root .flex-agents-detail-portrait img {
  width: 67px;
  height: 82px;
  flex-shrink: 0;
  object-fit: cover;
}

.figmaingame-agentcard-namerow {
  display: flex;
  align-items: center;
  gap: 5px;
  align-self: stretch;
}

.figmaingame-agentcard-callsign {
  display: flex;
  padding: 0px 5px;
  align-items: center;
  border: 1px solid #d3c6b2;

  color: #d3c6b2;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaingame-agentcard.dead .figmaingame-agentcard-callsign,
.figmaingame-agentcard.dead .figmaingame-agentcard-name {
  color: #8d8d8d;
}

.figmaingame-agentcard.dead .figmaingame-agentcard-gaugerow,
.figmaingame-agentcard.dead .figmaingame-agentcard-itemrow {
}

.figmaingame-agentcard-name {
  color: #d3c6b2;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.figmaingame-agentcard-gaugerow {
  display: flex;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
}

.figmaingame-agentcard-itemrow {
  display: flex;
  align-items: flex-start;
  gap: 7px;
  align-self: stretch;
}

.figmaingame-agentcard-item {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  gap: 10px;
  background: #160c0c;
}

.figmaingame-agentcard-item-label {
  display: flex;
  padding: 0px 4px;
  align-items: flex-start;
  align-self: stretch;

  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaingame-label-K {
  background: #c63d3d;
}

.figmaingame-label-D {
  background: #db8c16;
}

.figmaingame-agentcard-item-value {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaingame-value-K {
  color: #c63d3d;
}

.figmaingame-value-D {
  color: #db8c16;
}

.figmaingame-agent-buff {
  display: flex;
}

.figmaingame-agent-buff-icon {
  width: 28px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid #ce9f75;
  background: #a97f58;
}

.figmaingame-agentcard-gauges {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  align-self: stretch;
  width: 120px;
}

.figmaingame-agentcard-gauge {
  display: flex;
  padding-right: 5px;
  align-items: center;
  flex: 1 0 0;
  background: rgba(0, 0, 0, 0.4);
  gap: 2px;

  width: 30%;
  height: 18px;
}

.figmaingame-agentcard-gauge-empty {
  flex: 1 1 0;
}

.figmaingame-agentcard-gauge-icon {
  flex: 0 0 20px;
}

.figmaingame-agentcard-gauge-label {
  margin: 0px 3px;
  width: 40px;
}

.figmaingame-agentcard-gauge-icon > svg {
  width: 20px;
  height: 20px;
}

.figmaingame-agentcard-gauge-value {
  flex: 1;

  display: flex;
  align-items: center;

  overflow-x: hidden;
}

.figmaingame-agentcard-iconrow {
  display: flex;
  align-items: center;
  justify-content: start;
  overflow: hidden;
}

.figmaingame-agentcard-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
}

.figmaingame-hotkey-group {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.figmaingame-hotkey-button {
  position: relative;
  width: 74px;
  height: 62px;
}

.figmaingame-hotkey-button .union-svg {
  position: absolute;
}

.figmaingame-hotkey-button .intersect-svg {
  top: 3px;
  left: 3px;
  position: absolute;
}

.figmaingame-hotkey-button .vector-svg {
  top: 10px;
  left: 11px;
  position: absolute;
}

.figmaingame-hotkey-button .initial {
  position: absolute;
  left: 60px;
  top: 21px;

  color: #DCE9E9;
  text-align: center;
  font-family: "Noto Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaingame-hotkey-button:not(.disabled):hover .union-path {
  fill: #D2E0E0;
  opacity: 1;
}

.figmaingame-hotkey-button:not(.disabled):hover .intersect-path {
  stroke: #F0F0F0;
}

.figmaingame-hotkey-button:not(.disabled):active .union-path {
  fill: #D2E0E0;
  opacity: 0.7;
}

.figmaingame-hotkey-button:not(.disabled):hover .initial {
  color: #3C3C3C;
}

.figmaingame-hotkey-button:not(.disabled):active .intersect-path {
  stroke: #F0F0F0;
  stroke-width: 1px;
}

.figmaingame-hotkey-button:not(.disabled):active .initial {
  color: #3C3C3C;
}

.disabled .union-path {
  fill: #000;
  opacity: 0.7;
}

.disabled .intersect-path {
  fill: #2A2A2A;
  stroke: #595959;
}

.disabled .vector-path {
  fill: #616161;
}

.disabled .initial {
  color: #8D8D8D;
}

.disabled .block-vector-svg {
  position: absolute;
  top: 6px;
  left: 6px;
}

.figmaingame-throwable-controller {
  border-radius: 15px;
  background: #dadada;
  padding: 20px;
  width: 64px;
  height: 64px;
}

.figmaingame-heal-controller {
  border-radius: 15px;
  background: #adff74;
  padding: 20px;
  width: 64px;
  height: 64px;
}

.figmaingame-order-controller {
  border-radius: 15px;
  background: #00aeff;
  padding: 20px;
  width: 64px;
  height: 64px;
}

.figmaingame-progressbar-container {
  display: flex;
  height: 9px;
  width: 100%;

  position: relative;
  overflow: hidden;
}

.figmaingame-progressbar-container.stock {
  height: 12px;
}

.figmaingame-progressbar-container-label {
  display: flex;
  position: absolute;
  width: 100%;
  font-weight: 900;
  justify-content: center;
  line-height: 12px;
}

.figmaingame-progressbar-fg,
.figmaingame-progressbar-bg {
  display: flex;
  height: 100%;
}

.figmaingame-progressbar-fg.damage {
  background: red;
}
.figmaingame-progressbar-bg.damage {
  background: #072816;
}

.figmaingame-progressbar-fg.life {
  background: #03bd59;
}
.figmaingame-progressbar-bg.life {
  background: #072816;
}

.figmaingame-progressbar-limit.life {
  background: red;
}

.figmaingame-progressbar-fg.gear {
  background: #a0e9f7;
}
.figmaingame-progressbar-bg.gear {
  background: #21282c;
}

.figmaingame-progressbar-fg.kill {
  background: purple;
}
.figmaingame-progressbar-bg.kill {
  background: #21282c;
}

.figmaingame-progressbar-fg.enemy {
  background: #e94559;
}

.figmaingame-progressbar-fg.enemy.gear {
  background: #a0e9f7;
}

.figmaingame-progressbar-bg.enemy {
  background: #0e0300;
}

.figmaingame-progressbar-fg.enemy.idle {
  background: #878787;
}

.figmaingame-progressbar-fg.enemy.idle.gear {
  background: #63989f;
}

.figmaingame-progressbar-container.discrete {
  max-width: 100%;
  width: 50%;
  flex: 1 0 0;
  overflow-x: hidden;
}

.figmaingame-agentcard-gauge-value-desc {
  display: flex;
}

.figmaingame-progressbar-fg.discrete,
.figmaingame-progressbar-bg.discrete {
  width: 9px;
  margin-right: 4px;
  flex: 1 0 0;
}

.figmaingame-progressbar-fg.discrete.ammo,
.figmaingame-progressbar-bg.discrete.ammo {
  width: unset;
  flex: 1 1 9px;
  margin-right: 1px;
}

.figmaingame-policycard {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;

  position: absolute;
  left: 8px;
  bottom: 7px;
}

.figmaingame-policycard-item {
  display: flex;
  align-items: center;
  align-self: stretch;

  border: 1px solid #1d2a34;
  background: rgba(0, 0, 0, 0.5);
}

.figmaingame-policycard-label {
  display: flex;
  width: 150px;
  padding: 3px 20px;
  justify-content: center;
  align-items: center;

  white-space: nowrap;
  background: #1d2a34;
  color: #869bab;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaingame-policycard-value {
  display: flex;
  width: 100px;
  justify-content: center;
  align-items: center;

  color: #bababa;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaingame-progressboard {
  width: 177px;
  height: 298px;
  flex-shrink: 0;

  display: inline-flex;
  padding: 10px 10px 10px 34px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  position: absolute;
  left: 0px;
  top: 61px;

  pointer-events: none;
}

.figmaingame-progressboard-segment {
  display: flex;
  height: 26px;
  align-items: center;

  position: relative;
  z-index: 0;
}

.figmaingame-progressboard-segment.current::before {
  content: "";
  width: 211px;
  height: 37px;
  flex-shrink: 0;

  position: absolute;
  left: -34px;
  z-index: -1;
  background: linear-gradient(
    90deg,
    #3a6679 70.06%,
    rgba(54, 123, 137, 0) 101.13%
  );
}

.figmaingame-progressboard-segment.current::after {
  content: "▶";
  position: absolute;
  left: -12px;
  top: -1px;

  color: #b6d7df;
  font-size: 16px;
}

.figmaingame-progressboard-segment-label {
  display: flex;
  margin-right: 10px;
  padding: 0px 7px 2px 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: #b6d7df;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.figmaingame-progressboard-segment-tallymarks {
  display: flex;
}

.figmaingame-progressboard-segment-tallymarks > div {
  margin-left: 4px;
}

.figmaingame-characterhud-line {
  position: absolute;
  height: 2px;
  transform-origin: top left;
  /*
  backgroundColor: color,
  transform: `rotate(${angle * 180 / Math.PI}deg)`,
  */
}

.figmaingame-characterhud-line.ally {
  background-color: #b6f657;
}
.figmaingame-characterhud-line.enemy {
  background-color: #f14d61;
}
.figmaingame-characterhud-line.enemy.idle {
  background-color: #878787;
}

.figmaingame-characterhud {
  display: flex;
  flex-direction: row;
  width: 140px;

  position: relative;
  overflow: hidden;
}

.figmaingame-characterhud-outer {
  padding: 5px;

  background: rgba(0, 0, 0, 0.5);

  position: absolute;
  transform: translate(-50%, -50%);
}

.figmaingame-characterhud-outer.compact {
  padding: 1px;
  border-radius: 5px;

  transform: translate(-50%, 50%);
}

.figmaingame-characterhud-outer.covered {
  border: 2px solid #07c3ff;
}

.figmaingame-characterhud-outer.ally.obstructed {
  border: 2px solid #c6c6c6;
}

.figmaingame-characterhud-outer.stunned {
  border: 2px solid #ff6666;
}

.figmaingame-characterhud-states {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin-right: 5px;
}

.figmaingame-characterhud-states > div {
  padding-bottom: 2px;
  border-bottom: 1px solid #d3c6b2;
}

.figmaingame-characterhud-states > div:last-child {
  padding-bottom: unset;
  border-bottom: unset;
}

.figmaingame-characterhud-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 2px;
  flex: 1 0 0;
}

.figmaingame-characterhud-name {
  padding: 0px 5px;
  align-items: center;

  color: #d3c6b2;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  white-space: nowrap;
  overflow: hidden;
  font-family: "Noto Sans", "Noto Sans CJK";
}

.figmaingame-characterhud-gaugerow {
  width: 101px;

  display: flex;
  align-items: flex-start;
}

.figmaingame-characterhud-gauge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  padding: 2px;
  flex: 1 0 0;

  width: 50%;
}

.figmaingame-characterhud-gauge.unavailable {
  visibility: hidden;
}

.figmaingame-characterhud-gauge.unequipped span {
  text-align: center;
  flex: 1 0 0;
  color: #626262;
}

.figmaingame-characterhud-gauge-value {
  width: 100%;
}

.figmaingame-characterhud-gauge-value.narrow .figmaingame-progressbar-fg,
.figmaingame-characterhud-gauge-value.narrow .figmaingame-progressbar-bg {
  height: 4px;
}

.figmaingame-characterhud-gauge-desc {
  display: flex;
  align-items: center;
  color: #9be1d0;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}

.figmaingame-characterhud.enemy {
  display: flex;
  width: 101px;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 0;

  color: #d9495a;
}

.figmaingame-characterhud.enemy .figmaingame-characterhud-callsign {
  color: #d9495a;
  padding: unset;
  border: unset;
}

.figmaingame-characterhud.enemy.idle,
.figmaingame-characterhud.enemy.idle .figmaingame-characterhud-callsign {
  color: #878787;
}

.figmaingame-characterhud-passive-state {
  display: flex;
  padding: 2px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;

  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  font-family: "Noto Sans", "Noto Sans CJK";
}

.figmaingame-characterhud-passive-state.covered {
  background: #19435b;
  color: #07c3ff;
}

.figmaingame-characterhud-passive-state.obstructed {
  background: #434343;
  color: #c6c6c6;
}

.figmaingame-characterhud-passive-state.stunned {
  background: #ff6666;
  color: #ffff66;
}

.figmaingame-damage-indication {
  display: flex;
  flex-direction: row;

  align-items: center;

  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  position: absolute;

  font-family: "Noto Sans", "Noto Sans CJK";
}

.figmaingame-damage-indication .figmaingame-damage-indication-damage {
  position: relative;
  z-index: 1;

  transform: translate(-50%, -50%);

  color: #ff1000ff;
  text-shadow: -3px -3px 0 black, 3px -3px 0 black, -3px 3px 0 black,
    3px 3px 0 black;
}

.figmaingame-spot-marker {
  display: flex;
  flex-direction: row;

  align-items: center;

  position: absolute;
}

.figmaingame-spot-marker .figmaingame-spot-marker-image {
  width: 80px;
  height: 80px;
  transform-origin: center;
}

.figmaingame-spot-marker .figmaingame-spot-marker-text {
  position: relative;
  z-index: 1;

  transform: translate(-50%, -50%);

  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.figmaingame-alert-marker {
  display: flex;
  flex-direction: row;

  align-items: center;

  position: absolute;
}

.figmaingame-alert-marker .figmaingame-alert-marker-image {
  width: 48px;
  height: 48px;
  transform-origin: center;
}

.figmaingame-direction-marker {
  display: flex;
  flex-direction: row;

  align-items: center;

  position: absolute;
}

.figmaingame-direction-marker .figmaingame-direction-marker-image {
  width: 64px;
  height: 64px;
  transform-origin: center;
}

.figmaingame-death-marker {
  display: flex;
  flex-direction: row;

  align-items: center;

  position: absolute;
}

.figmaingame-death-marker .figmaingame-death-marker-image {
  width: 64px;
  height: 64px;
  transform-origin: center;
}

.figmaingame-headshot-marker {
  display: flex;
  flex-direction: row;

  align-items: center;

  position: absolute;
  width: 100px;
  height: 100px;
  overflow: hidden;
}

.figmaingame-headshot-marker .figmaingame-headshot-marker-image {
  position: absolute;
}

.figmaingame-bubble {
  display: flex;
  flex-direction: row;

  padding: 0px 10px;
  align-items: center;

  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  position: absolute;
  transform: translate(0%, -50%);

  font-family: "Noto Sans", "Noto Sans CJK";
}

.figmaingame-bubble.ally {
  color: #cdff80;
}

.figmaingame-bubble.enemy {
  color: #fd6577;
}

.figmaingame-bubble.enemy.idle {
  color: #878787;
}

.figmaingame-bubble .figmaingame-bubble-bg {
  filter: blur(5px);
  width: 100%;
  height: 100%;

  position: absolute;
  z-index: 0;

  left: 0px;
}

.figmaingame-bubble.ally .figmaingame-bubble-bg {
  background: #104819;
}

.figmaingame-bubble.enemy .figmaingame-bubble-bg {
  background: #481010;
}

.figmaingame-bubble.enemy.idle .figmaingame-bubble-bg {
  background: rgba(0, 0, 0, 0.8);
}

.figmaingame-bubble .figmaingame-bubble-icon {
  width: 10px;
  height: 14px;
  margin-right: 6px;
}

.figmaingame-bubble .figmaingame-bubble-message,
.figmaingame-bubble .figmaingame-bubble-icon {
  position: relative;
  z-index: 1;
}

.figmaingame-dialog {
  position: absolute;
  width: 251px;
  height: 254px;
  left: 31px;
  top: 636px;
}

.figmaingame-dialog-bg {
  position: absolute;
  width: 648px;
  height: 163px;
  left: -31px;
  top: 91px;

  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.7) 61.11%,
    rgba(0, 0, 0, 0) 97.45%
  );
}

.figmaingame-dialog-name {
  position: absolute;
  left: 201px;
  top: 113px;
  width: 300px;

  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */
  display: flex;
  align-items: center;

  color: #98ab9a;
}

.figmaingame-dialog-text {
  position: absolute;
  width: 200px;
  left: 248px;
  top: 156px;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  /* or 144% */

  color: #dbdbdb;
}

.figmaingame-dialog > .portrait-frame-small01 {
  position: absolute;
}

.figmaingame-progress {
  position: absolute;
  transform: translate(-50%, -50%);

  background: rgba(0, 0, 0, 0.8);
  padding: 5px;
  border-radius: 10px;
  border: 2px solid white;
}

.flex-agents-portrait.dead {
  position: relative;
}

.flex-agents-portrait.dead > img,
.flex-agents-portrait.dead > .portrait-frame {
  filter: grayscale(1);
}

.flex-agents-portrait-dead {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
  color: #ff2525;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.connecting-line {
  /* 너비 */
  height: 4px;
  /* 패턴 숫자 (25% -> 4개) */
  background-size: 30% 100%;
  position: absolute;
  /* 배경 색 */
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 50, 50, 0.5),
    transparent
  );
  background-repeat: repeat-x;
  /* 2초에 한 번 깜빡이고, 4초에 한 칸씩 흐릅니다 */
  animation: fadeInOut 0s linear infinite, flowAnimation 2s linear infinite;
}

/* Fade in and out animation */
@keyframes fadeInOut {
  /* 페이드 인, 아웃 애니메이션 키프레임. 0% 진행일 때 투명, 50% 일 때 불투명 */
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

/* Flow animation (left to right) */
@keyframes flowAnimation {
  /* flow animation 키프레임 */
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
}
